import React from 'react';
import { Grid } from '@mui/material';
import { useAppSelector, useAppDispatch } from "app/hooks";
import { selectAuthUser } from "features/auth";
import { fetchHomeMarkdown } from "./homeSlice";
import { AuthUser } from "features/auth/authAPI";
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from "rehype-sanitize";

// const useStyles = makeStyles((theme) => ({
//     root: {
//         '& .MuiTextField-root': {
//             marginTop: theme.spacing(1),
//             marginBottom: theme.spacing(1)
//         },
//         '& li': {
//             marginBottom: theme.spacing(1)
//         }
//     }
// }));
export function getInterpolatedMarkdown(md: string, authUser: AuthUser) {
    const regex = /({\S*})/g;
    const matches = md.match(regex);
    let imd = md;
    matches?.forEach(match => {
        const fieldName = match.substring(1, match.length - 1); // remove braces
        if (authUser.profile && authUser.profile.value && fieldName in authUser.profile.value) {
            imd = imd.replaceAll(match, String(authUser.profile.value[fieldName]));
        }
    });
    return imd;
}

export default function HomePage() {    
    
    const authUser = useAppSelector(selectAuthUser).user;
    if (!authUser) throw Error("No user logged in");

    const dispatch = useAppDispatch(); 
    const homeState = useAppSelector(state => state.home);    
    const imd = getInterpolatedMarkdown(homeState.markdown, authUser);

    // const classes = useStyles();

    React.useEffect(() => {
        if(homeState.status === "idle") {
            dispatch(fetchHomeMarkdown());
        }
    });

    return (
        <Grid>
            <MDEditor.Markdown 
                source={imd} 
                rehypePlugins={[[rehypeSanitize]]}
                />
        </Grid>
        // <Grid container spacing={5} className={classes.root}>
        //     <Grid item xs={12} sm={6} md={8}>
        //         <MDEditor.Markdown source={imd} />
        //     </Grid>
        //     <Grid item xs={12} sm={6} md={4} >
        //         <h4>Downloads</h4>                
        //         <p>No downloads currently available</p>
        //     </Grid>
        // </Grid>
    );
}
