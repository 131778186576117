// currently used for determining the visibility of a field or when to overwrite its value
// consider expanding to warning conditions etc.
export interface FieldCondition {
    dataElementUid: string;
    operator: ComparisonOperators;
    value: any;
}

export interface FieldConditions {
    logic: "and" | "or";
    conditions: FieldCondition[];
}

export enum ComparisonOperators {
    eq = "eq",
    neq = "neq",
    isnull = "isnull",
    isnotnull = "isnotnull",
    isempty = "isempty",
    isnotempty = "isnotempty",
    startswith = "startswith",
    doesnotstartwith = "doesnotstartwith",
    contains = "contains",
    doesnotcontain = "doesnotcontain",
    endswith = "endswith",
    doesnotendwith = "doesnotendwith"
}