import { API_URL } from "../../app/runtimeConstants";
import { CourseRequirementGroup } from "../courses/courseRequirementGroups";
import { Course } from "../courses/data/course";

/** nullable values are those that aren't necessary to post */
export interface ApiJsonDatum {
    uid: string | null; // uuid, lowercase
    schemaName: string; // enum/map? course | userProfile  - maps to type of data
    userId: string | null; // uuid, lowercase
    created: string | null; // date YYYY-MM-DDTHH:MM:SS.mmmZ
    active: boolean | null;
    data: string; // this is a json string that once parsed has type of:  Omit<T, "uid">; 
}
export interface BinaryDatum {
    uid: string;
    mimeType: string;
    // filename: string;
    // uploadedByUserId: string;
    name: string;
    description: string;
    // created: string; // date YYYY-MM-DDTHH:MM:SS.mmmZ

    //TODO: consider using blob links or whatever and returning the binary data with the metadata
    url: string;
}
export interface CourseUploadResult {
    uid: string;
    title: string;
    href: string;
    ver: string;
}
export interface ApiEndpoint<O extends FormData | {} | undefined, I> {
    url: string;
    authorizationRequired: boolean;
    method: "GET" | "POST";
    requestBody?: O;
    //responseMapper?: (r: ApiJsonDatum<I>[]) => I;
    okResponseMapper: (r: Response) => Promise<I>;
}
export async function ApiFetchOLD<O, I>(config: ApiEndpoint<O, I>, payload: O, token?: string): Promise<I> {

    if (config.authorizationRequired && !token) {
        // potential error
    }

    const requestInit: RequestInit = {
        method: config.method,
        body: (config.requestBody instanceof FormData) ? config.requestBody : JSON.stringify(config.requestBody),
        headers: config.authorizationRequired ? { 'Authorization': `Bearer ${token}` } : undefined,
        credentials: config.authorizationRequired ? "include" : undefined
    };

    const response = await fetch(config.url, requestInit);

    if (response.ok) {
        const mapped = config.okResponseMapper(response);
        return mapped;
    } else {
        const responseText = await response.text();
        return Promise.reject(new Error(responseText));
    }
}

