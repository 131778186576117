import React from "react";
import { Divider, Grid, Button } from "@mui/material";
import { DomainSchema } from "features/domainModel";
import { Form, Formik } from "formik";
import { DMFormProps, DMFormAction } from ".";
import { FMuiStepper } from "./FMuiStepper";


export function StepForm<T extends DomainSchema, A extends DMFormAction>(props: DMFormProps<T, A>) {
    if (props.domainSchema.schema.kind !== "stepForm") throw new Error(`A non-StepForm schema ('${props.domainSchema.name}') was passed to StepForm`);
    type RecordValues = typeof initialState.forFormik.initialValues;
    const [initialState] = React.useState(() => {
        return {
            ds: props.domainSchema,
            action: props.action,
            areInitialValuesValid: props.areInitialValuesValid,
            //validator: props.validator,
            forFormik: props.forFormik
        };
    });
    const { ds, action, areInitialValuesValid: initialValid } = initialState;
    const { initialValues, validationSchema, onSubmit: handleSubmit } = initialState.forFormik;
    const stepperRef = React.useRef<{reset: () => void}>();
    // state that may change over time
    const [stepperIsValid, setStepperIsValid] = React.useState(initialState.areInitialValuesValid);

    // state that remains static after initial computation. Stored in state for memoization and/or to avoid recomputation


    // end state
    
    const handleStepperValidityStateChange = (isValid: boolean) => {
        if (stepperIsValid !== isValid)
            setStepperIsValid(isValid);
    };

    const handleCancel = () => {
        handleReset();
        props.onUserCancel();

    };
    const handleReset = () => {
        setStepperIsValid(initialState.areInitialValuesValid);
        stepperRef.current?.reset();
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
        >
            {(formikProps) => {
                return (
                    <Form>
                        <FMuiStepper resetStateRef={stepperRef} formik={formikProps} forAction={action} domainSchema={ds} isInitialValid={initialValid} onValidityChange={handleStepperValidityStateChange} />

                        <Divider style={{ marginBottom: "0.5rem", marginTop: "1rem" }} />
                        <Grid container>
                            <Grid item xs={9}>
                                <Button onClick={() => { formikProps.resetForm(); handleCancel(); }} color="primary">Cancel</Button>
                                <Button onClick={() => { formikProps.resetForm(); handleReset(); }} color="primary">Reset</Button>
                            </Grid>
                            <Grid item xs={3} style={{ textAlign: "right" }}>
                                <Button type="submit" color="primary" variant={stepperIsValid && formikProps.isValid ? "contained" : "text"} disabled={formikProps.isSubmitting || !stepperIsValid || !formikProps.isValid}>Save</Button>
                            </Grid>
                        </Grid>
                    </Form>);
            }}
        </Formik>);
}