import React from "react";
import type { Column } from "react-data-grid";
import { SelectColumn, TextEditor, SelectCellFormatter } from "react-data-grid";
import { Convert } from '../../../data/old/UserProfileDataTypes';
import type { FieldType, Option, UserProfileField } from '../../../data/old/UserProfileDataTypes';
import fieldsData from "../../../data/old/userProfileFields.json";
import testUserData from "./testUserData.json";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { IconButton, Tooltip } from "@mui/material";
import EmailIcon from '@mui/icons-material/Email';
import LockOpenIcon from '@mui/icons-material/LockOpen';
import PopupUserEditor from "./PopupUserEditor";
const fieldConfig = Convert.toUserProfileField(JSON.stringify(fieldsData));
const users: UserRow[] = testUserData as UserRow[];
export interface UserRow {
    uuid: string;
    email: string;
    givenName: string;
    surname: string;
    // testUser: boolean;
    // Military Duties
    "2FCF6D87-FD10-4CB1-94D4-60E80C590EA6": string;
    //full-time guard employee
    "90727415-F957-4102-977D-544E36DD54EF": string;
    //state employee
    "0750CE68-6AAD-46C3-B954-25ACAA71539A": string;
    //full-time federal employee
    "631AB676-6BE3-4128-B777-4AE6413D2147": string;
    // OMD (State)
    "915B0207-16C0-4C8B-A41C-D96F1005EFDE": string;
    // full-time status
    "D6D70570-DD28-48FB-A68E-6C92D2E99B32": string;
    // full-time status (second one)
    "9441F0AF-AEE6-4E17-823A-2EAF63F10DC7": string;
    // ADOS Position
    "AE98001E-42B1-4530-B639-C8D1FB96840A": string;
    // Wage Grade Position
    "772F3A83-64A8-40B9-A3CF-5F62710EB4FA": string;
    // General Schedule Position
    "69A1A490-FC90-42E5-B5D8-19D0627DD5E7": string;
    // AGR Position
    "794C726B-94E1-4AE5-9969-1F919D89386A": string;
    // MOS Type
    "5EB73E3A-DDD3-40B1-966F-040255A7773E": string;
    // Primary MOS Enlisted
    "1C46A95C-1463-49C0-BB05-C885C3CC3FB5": string;
    // Primary MOS Warrant
    "841F1263-13EE-44C4-A9C1-1362970B4BB5": string;
    // Primary MOS Officer
    "5A1C9CD0-9C5E-40BA-9909-F99412B72D8B": string;
    // Facility
    "FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E": any;
    // EPOC
    "dc024ff8-7046-422f-8628-fcd7b247a72e": string;
    // Work at shop
    "f2cc7462-c956-41ad-bab6-1e3bb6c84f43": string;
    // Handles HW
    "482425c2-1394-4788-ba9a-ef341c8886f3": string;
    // CSMS EPOC
    "29e85428-6d03-4d9f-b07e-8255fc462232": string;
    // EPOC supervisor
    "5744bda3-9db6-4dbd-b2dc-a876b6c46dd4": string;
}

export function getColumns(): readonly Column<UserRow>[] {

    return [
        SelectColumn,
        {
            key: "edit",
            name: "Action",
            frozen: true,
            width: 120,
            formatter(props) {                
                return (
                    <div>
                        <Tooltip title="Change User's Email">
                            <IconButton size="small">
                                <EmailIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                        <Tooltip title="Reset User's Password">
                            <IconButton size="small">
                                <LockOpenIcon color="primary" />
                            </IconButton>
                        </Tooltip>
                        <PopupUserEditor/>
                    </div>
                );
            }
        } as Column<UserRow>,
        {
            key: "email",
            name: "Email",
            width: 200,
            editor: TextEditor,
            frozen: true
        },
        {
            key: "surname",
            name: "Surname",
            width: 120,
            editor: TextEditor
        },
        {
            key: "givenName",
            name: "Given Name",
            width: 120,
            editor: TextEditor
        }
    ].concat(fieldConfig.sort((a, b) => a.displayOrder - b.displayOrder).map(field => {
        return {
            key: field.fieldId,
            name: field.adminLabel,
            width: 200,
            formatter(props) {
                //@ts-ignore
                const value = Number(props.row[field.fieldId]);

                const text = field.options.find(opt => opt.key === value)?.text || "";
                return <>{text}</>;
            },
            editorOptions: {
                editOnClick: true
            },            
            editor: (p) => (
                <select
                    autoFocus
                    style={{height: "100%", width: "100%", padding: "0 6px", verticalAlign: "top"}}
                    //@ts-ignore
                    value={p.row[field.fieldId] ? Number(p.row[field.fieldId]): ""}
                    onChange={(e) => p.onRowChange({...p.row, [field.fieldId] : e.target.value}, true)}
                >
                    <option key="" value=""></option>
                    {
                        field.options.map(opt => (
                            <option key={opt.key} value={opt.key}>{opt.text}</option>
                        ))
                    }
                    
                </select>
            )

        } as Column<UserRow>;
    }));
}

export function getRows(): readonly UserRow[] {
    return users;
}

export function rowKeyGetter(row: UserRow) {
    return row.uuid;
}