import { DomainConfig } from "../types/domainConfig";
import { DomainSchema } from "../types/domainSchema";
import { userProfileDomainSchema } from "./domainSchema";
import { API_URL } from "app/runtimeConstants";
import { ApiFetch, Ok } from "../asyncApi";
import { DomainRecordValidator } from "../util";
import { DomainRecordAction, DomainRecordError } from "../types";
import { quickUserDomainSchema } from "./domainSchemaQuickUser";
export * from "./domainConfigUserProfileAdmin";
export interface UserProfileDomainSchema extends DomainSchema { }

export const UserProfileDomainConfig: DomainConfig<UserProfileDomainSchema> = {
    id: [userProfileDomainSchema.name, userProfileDomainSchema.uid, userProfileDomainSchema.version],
    schema: userProfileDomainSchema as UserProfileDomainSchema
};

export interface QuickUserDomainSchema extends DomainSchema {} 
export const QuickUserDomainConfig: DomainConfig<QuickUserDomainSchema> = {
    id: [quickUserDomainSchema.name, quickUserDomainSchema.uid, quickUserDomainSchema.version],
    schema: quickUserDomainSchema as QuickUserDomainSchema
}

const drv = new DomainRecordValidator(UserProfileDomainConfig.schema);

function readMyUserProfile(token: string) {
    const action = "read";
    const url = `${API_URL}/mydata/user`;
    const aRes = ApiFetch(url, token);
    return aRes
            .map((values) => mapUserProfile(values, action))
            .mapErr((err) => mapUserProfileError(err, action));
}
export function mapUserProfile<T extends DomainRecordAction>(datum: any, action: T) {
    return drv.validate(datum, action)                
                .map(v => v)
                .mapErr(v => v);
}

function mapUserProfileError(error: string, action: DomainRecordAction) {
    return {
        kind: "error",
        schema: UserProfileDomainConfig.id,
        action: action,
        value: undefined,
        isValid: false,
        wasCast: false,
        errors: error,
        stack: new Error().stack
    } as DomainRecordError<UserProfileDomainSchema, typeof action>;
}