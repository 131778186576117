import React from "react";
import { Link } from 'react-router-dom';
import { useAppSelector } from "../../app/hooks";
export default function AdminPage() {
    const authUser = useAppSelector(state => state.auth.user);
    const content = authUser?.areas?.find(a => a.name === "Admin")?.modules.map(m => {
        return (
            <div key={m.name}>
                <Link to={`/admin/${m.name.toLowerCase()}`}>{m.name}</Link> - from here, you can perform the following actions:
                <ul>
                    {m.actions.map((a,i) => <li key={i}>{a.name}</li>)}
                </ul>
            </div>
        );
    });
    return (
        <div>
            <h4>Welcome to the Administration Section</h4>
            <p>Below is a summary of the administration pages you have access to, along with the actions you can perform.</p>
            {content}
            {/* <p>
                <Link to="/admin/users">Users</Link> - from here, you can perform the following actions:
                <ul>
                    <li>Create new user accounts</li>
                    <li>Merge duplicate user accounts</li>
                    <li>Deactivate user accounts</li>
                    <li>Change user email addresses</li>
                    <li>Reset user passwords</li>
                    <li>Manage user groups</li>
                    <li>Manage user permissions</li>
                </ul>
            </p>
            <p>
                <Link to="/admin/courses">Courses</Link> - from here, you can perform the following actions:
                <ul>
                    <li>Create, edit, and deactivate Course Requirements</li>
                    <li>Create, edit, and deactivate Online - Self-Paced Modules</li>
                    <li>Create, edit, and deactivate Online - Document Review Modules</li>
                    <li>Create, edit, and deactivate In-Person - AdHoc Training Events</li>
                    <li>Create, edit, deactivate, and schedule In-Person - Training Events</li>
                </ul>
            </p>
            <p>
                <Link to="/admin/profile">User Profile</Link> - from here, you can manage the fields that are part of the User Profile.
            </p>
            <p>
                <Link to="admin/home">Home Page &amp; Downloads</Link> - from here, you can manage the content of the Home page.
            </p> */}
        </div>
    );
}