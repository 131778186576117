import { DomainRecordValidator, UserProfileDomainConfig } from "features/domainModel";
import { InPersonAdHocDomainConfig, onlineDocumentReviewConfig } from "features/domainModel/Courses";
import { useHistory } from "react-router-dom";
import { DMForm, DMFormProps, DMFormValue } from ".";



export default function DmeTestPage() {
    const history = useHistory();
    const schema = onlineDocumentReviewConfig.schema; //InPersonAdHocDomainConfig.schema; // UserProfileDomainConfig.schema;
    const drv = new DomainRecordValidator(schema);
    const { initialValues, validationSchema } = drv.getPropsForFormik("create");


    const upCreateTestProps: DMFormProps<typeof schema, "create"> = {
        domainSchema: schema,
        action: "create",
        areInitialValuesValid: false,
        onUserCancel: () => { history.goBack()},
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: (values) => {
                window.alert("save clicked");
            }
        }
    };

    return (
        <DMForm {...upCreateTestProps} />
    );
}