import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';
import { Redirect, useHistory } from "react-router-dom";
import { doRegisterAsync } from "../auth";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateUserProfileAsync } from "../auth/authSlice";
import { DMForm, DMFormProps } from "features/dmForms";
import { DomainRecordValidator, UserProfileDomainConfig, UserProfileDomainSchema } from "features/domainModel";

export interface UserProfilePageProps {
    action: "register" | "userProfile" | "admin";
}

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


export default function UserProfilePage(props: UserProfilePageProps) {    
    const [submissionError, setSubmissionError] = React.useState(false);
    const [submissionSuccess, setSubmissionSuccess] = React.useState(false);    
    const [successSnackbarUserClosed, setSuccessSnackbarUserClosed] = React.useState(false);
    const [authErrorSnackbarUserClosed, setAuthErrorSnackbarUserClosed] = React.useState(false);
    const [submissionErrorSnackbarUserClosed, setSubmissionErrorSnackbarUserClosed] = React.useState(false);

    const dispatch = useAppDispatch();
    const authUser = useAppSelector(state => state.auth);
    const authError = authUser.error;

    const history = useHistory();
    const dmAction = props.action === "register" ? "create" : "update";
    const schema = UserProfileDomainConfig.schema;
    const drv = new DomainRecordValidator(schema);
    const { initialValues, validationSchema } = dmAction === "create" ? drv.getPropsForFormik("create") : drv.getPropsForFormik("update", authUser.user?.profile?.value);

    const dmFormProps: DMFormProps<UserProfileDomainSchema, typeof dmAction> = {
        domainSchema: UserProfileDomainConfig.schema,
        action: dmAction,
        areInitialValuesValid: authUser.user?.profile?.isValid || false,  //TODO: this is checking against READ not UPDATE, fix.
        onUserCancel: function (): void {
            history.push("/");
        },
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: (values, formikHelpers) => {
                const { setSubmitting } = formikHelpers;

                // TODO implement notistack here
                setSuccessSnackbarUserClosed(false);
                setSubmissionErrorSnackbarUserClosed(false);
                setAuthErrorSnackbarUserClosed(false);

                //TODO make this DRY
                setSubmitting(true);
                if (dmAction === "create") {
                    drv.validate(values, "create")
                        .map(valid => {
                            return dispatch(doRegisterAsync({ userProfile: valid }))
                                .then(value => {
                                    setSubmitting(false);
                                    // setSubmissionSuccess(true) // update only
                                }).catch(err => {
                                    setSubmitting(false);
                                    setSubmissionError(true);
                                });
                        })
                        .mapErr(notValid => {
                            //shouldnt happen since formik already validated
                            setSubmitting(false);
                            setSubmissionError(true);
                        })
                        .resolve();
                }
                if (dmAction === "update") {
                    drv.validate(values, "update")
                        .map(valid => {
                            return dispatch(updateUserProfileAsync({ userProfile: valid }))
                                .then(value => {
                                    setSubmitting(false);
                                    setSubmissionSuccess(true)
                                }).catch(err => {
                                    setSubmitting(false);
                                    setSubmissionError(true);
                                });
                        })
                        .mapErr(notValid => {
                            //shouldnt happen since formik already validated
                            setSubmitting(false);
                            setSubmissionError(true);
                        })
                        .resolve();
                }
            }
        }
    };



    

    return (
        <div>
            {/* {submissionError && <Typography color="error">An unknown error was encountered. Please try again later.</Typography>}
            {authError && <Typography color="error">{<>{authError.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</>}</Typography>} */}
            <Snackbar
                open={submissionSuccess && !authError && !successSnackbarUserClosed}
                onClose={() => setSuccessSnackbarUserClosed(true)}
            >
                <>
                    <Alert severity="success" onClose={() => setSuccessSnackbarUserClosed(true)}>User profile updated successfully!</Alert>

                </>
            </Snackbar>
            <Snackbar
                open={submissionError && !submissionErrorSnackbarUserClosed}
                onClose={() => setSubmissionErrorSnackbarUserClosed(true)}
            >
                <>
                    <Alert severity="error" onClose={() => setSubmissionErrorSnackbarUserClosed(true)}>An unknown error was encountered. Please try again later.</Alert>

                </>
            </Snackbar>
            <Snackbar
                open={(authError !== undefined && authError !== "") && !authErrorSnackbarUserClosed}
                onClose={() => setAuthErrorSnackbarUserClosed(true)}
            >
                <>
                    <Alert severity="error" onClose={() => setAuthErrorSnackbarUserClosed(true)}><>{authError?.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</></Alert>

                </>
            </Snackbar>

            <DMForm {...dmFormProps} />

            {!authError && props.action === "register" && authUser.isAuthenticated && <Redirect to="/" />}
        </div>
    );
}
