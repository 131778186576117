import React from "react";
import DataGrid, { Column } from "react-data-grid";
import type { SortColumn } from "react-data-grid";
import GridBottomBar from "./gridEditor/GridBottomBar";
import GridTopBar from "./gridEditor/GridTopBar";
import { getRows as getUserRows, getColumns as getUserColumns, rowKeyGetter as userRowKeyGetter, UserRow } from "./gridEditor/users";
import { getRows as getCourseRows, getColumns as getCourseColumns, rowKeyGetter as courseRowKeyGetter, CourseRow } from "./gridEditor/courses";
import { Schemas } from "./gridEditor/SchemaChooser";
import ProfileFieldGrid from "./gridEditor/profileFields";
import { ApiFetchOLD } from "../common/api";
import { CoursesApiEndpoints } from "../common/userApiEndpoints";
import { userManager } from "../auth";
import { Paper } from "@mui/material";
import { AdminCoursesApiEndpoints } from "features/common/elevatedApiEndpoints";
export interface AdminGridProps {
    schema: Schemas;
}
export default function AdminGrid(props: AdminGridProps) {
    // const [schema, setSchema] = React.useState<Schemas>("users");    
    const [loading, setLoading] = React.useState(true);

    const [userRows, setUserRows] = React.useState(getUserRows);
    const [userCols, setUserCols] = React.useState(getUserColumns);
    const [selectedUserRows, setSelectedUserRows] = React.useState<ReadonlySet<string>>(() => new Set());

    const [courseRows, setCourseRows] = React.useState(getCourseRows([]));
    const [courseCols, setCourseCols] = React.useState(getCourseColumns(() => setLoading(true)));
    const [selectedCourseRows, setSelectedCourseRows] = React.useState<ReadonlySet<string>>(() => new Set());

    React.useEffect(() => {
        userManager.getUser().then(user => {
            if (user && loading) {
                ApiFetchOLD(AdminCoursesApiEndpoints.GetAllCourses, undefined, user.access_token)
                    .then(courses => {
                        const filteredCourses = getCourseRows(courses.filter(course => course.details.kind === "InPersonAdHoc" || course.details.kind === "OnlineDocReview" || course.details.kind == "OnlineSelfPacedModule"));
                        setCourseRows(filteredCourses);
                        setCourseCols(getCourseColumns(() => setLoading(true))); // necessary for the modal editor to show changes
                        setLoading(false);
                    });
            }
        });
    });

    // const handleSchemaChange = (newSchema: Schemas) => {
    //     if (newSchema !== schema) {
    //         setSchema(newSchema);

    //         switch (newSchema) {
    //             case "courses":
    //                 setRows(getCourseRows);
    //                 setCols(getCourseColumns);
    //                 break;
    //             case "users":
    //                 setRows(getUserRows);
    //                 setCols(getUserColumns);
    //                 break;
    //         }
    //     }
    // };

    const SchemaGrid =
        props.schema === "users"
            ? <DataGrid
                columns={userCols}
                rows={userRows}
                rowKeyGetter={userRowKeyGetter}
                selectedRows={selectedUserRows}
                onSelectedRowsChange={setSelectedUserRows}
                onRowsChange={setUserRows}
                defaultColumnOptions={{
                    resizable: true,
                }}
                style={{ height: "400px" }}
            />
            : props.schema === "courses"
                ? <DataGrid
                    columns={courseCols}
                    rows={courseRows}
                    rowKeyGetter={courseRowKeyGetter}
                    selectedRows={selectedCourseRows}
                    onSelectedRowsChange={setSelectedCourseRows}
                    onRowsChange={setCourseRows}
                    defaultColumnOptions={{
                        resizable: true,
                    }}
                    style={{ height: "400px" }}
                />
                : <ProfileFieldGrid />;
    const TopBar =
        props.schema === "users" || props.schema === "profile"
            ? <GridTopBar cols={userCols.slice(2)} />
            : <GridTopBar cols={courseCols.slice(2)} />;
    return (
        <div>
            <h4>Courses Administration</h4>
            <p>You are able to create, edit, and deactivate Online - Document Review Modules, and Training Events.</p>
            {/* <p>These courses will only be available to students that selected "ORARNG &gt; Joint Force Headquarters &gt; Surface Maintenance Shops &gt; CSMS" for their organization and "Camp Withycombe" for their facility.</p> */}
            <Paper>
                {/* {TopBar} */}
                {SchemaGrid}
            </Paper>
            <GridBottomBar schema={props.schema} onNewRecordCreated={() => setLoading(true)} />
        </div>
    );
}