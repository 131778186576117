import { API_URL } from "app/runtimeConstants";
import { ApiJsonDatum } from "features/common/api";
import { CourseRequirementGroupEndPoints, JsonDatumEndpoints } from "features/common/elevatedApiEndpoints";
import { CoursesApiEndpoints } from "features/common/userApiEndpoints";
import { CourseRequirementGroup } from "features/courses/courseRequirementGroups";
import { Course } from "features/courses/data/course";
import { ApiFetch } from "../asyncApi";
import { DomainRecordAction, DomainSchema, DomainRecordValues, DomainRecordError, DomainRecordValid } from "../types";
import { DomainConfig } from "../types/domainConfig";
import { DomainRecordValidator } from "../util";
import { onlineDocumentReviewSchema } from "./domainSchemaOnlineDocumentReview";


export interface OnlineDocumentReviewDomainSchema extends DomainSchema { };

export const OnlineDocumentReviewConfig: DomainConfig<OnlineDocumentReviewDomainSchema> = {
    id: [onlineDocumentReviewSchema.name, onlineDocumentReviewSchema.uid, onlineDocumentReviewSchema.version],
    schema: onlineDocumentReviewSchema as OnlineDocumentReviewDomainSchema
};

async function mapOnlineDocumentReview<T extends DomainRecordAction>(values: DomainRecordValues<OnlineDocumentReviewDomainSchema>, action: T): Promise<{ course: Omit<Course, "prereqsSatisfied">, crg: string, targetGroupUids: string[]; }> {
    const drv = new DomainRecordValidator(OnlineDocumentReviewConfig.schema);
    const result = await drv.validate(values, action)
        .map(v => v)
        .mapErr(v => v)
        .resolve();

    if (result.err) {
        throw new Error(`Recieved invalid values for mapping: ${result.val.errors}`);
    }
    const v = result.val.value;

    // CRG/UID/ID workaround (because of fmuiselectfield)
    let crg = String(v["crg"]).includes("-") ? v["crg"] : crgIdToUid(Number(v["crg"]));

    return {
        course: {
            uid: v["uid"],
            name: v["courseName"],
            description: v["description"],
            active: v["active"] === 1 ? true : false,
            certificate: v["certificateYN"] === 1 ? { name: v["certificateCourseName"] } : false,
            header: {
                backgroundColor: v["color"]
            },
            details: {
                kind: "OnlineDocReview",
                trackingMethod: v["trackingMethod"] === 1 ? "Attestation" : "NotTracked",
                urls: v["docs"],
                allowReview: v["trackingMethod"] === 1
            },
            timeToExpiry: v["expiryMo"] && Number(v["expiryMo"]) > 0 ? { unit: "months", value: Number(v["expiryMo"]) } : undefined
        },
        crg: crg,
        targetGroupUids: v["userGroups"]
    };
}

export function unmapOnlineDocumentReview(c: Omit<Course, "prereqsSatisfied">): Omit<DomainRecordValues<OnlineDocumentReviewDomainSchema>, "crg"> {
    if (c.details.kind !== "OnlineDocReview") {
        throw Error(`Expected OnlineDocReview, got ${c.details.kind}`);
    }

    return {
        uid: c.uid,
        courseName: c.name,
        description: c.description,
        active: c.active ? 1 : 2,
        trackingMethod: c.details.trackingMethod === "Attestation" ? 1 : 2,
        certificateYN: typeof c.certificate === "object" ? 1 : 2,
        certificateCourseName: typeof c.certificate === "object" ? c.certificate.name : "",
        docs: c.details.urls,
        expires: typeof c.timeToExpiry === "object" && c.timeToExpiry.value > 0 ? 1 : 2,
        expiryMo: typeof c.timeToExpiry === "object" && c.timeToExpiry.value > 0 ? c.timeToExpiry.value : undefined, // assumes unit is months
        color: c.header.backgroundColor
    };
}

export async function saveOnlineDocumentReview<T extends Exclude<DomainRecordAction, "read">>(values: DomainRecordValues<OnlineDocumentReviewDomainSchema>, action: T, userUid: string, token: string): Promise<DomainRecordValid<OnlineDocumentReviewDomainSchema, "read"> | DomainRecordError<OnlineDocumentReviewDomainSchema, T | "read">> {
    const mapped = await mapOnlineDocumentReview(values, action);
    if (mapped.course.details.kind !== "OnlineDocReview") {
        throw Error(`Expected OnlineDocReview, got ${mapped.course.details.kind}`);
    }

    const uid = action === "create" ? null : mapped.course.uid;
    const payload: Partial<Course> = { ...mapped.course };
    delete payload.uid;

    const datum: ApiJsonDatum = {
        uid: uid,
        schemaName: "course",
        userId: userUid,
        created: null,
        active: true,
        data: JSON.stringify(payload)
    };

    // Save JsonDatum for Course
    const config = JsonDatumEndpoints.post(datum, "course");
    const fetchResult = (await ApiFetch<ApiJsonDatum>(config.url, token, config.requestBody).resolve())
        .map(v => v)
        .mapErr((err) => {
            return {
                kind: "error",
                schema: OnlineDocumentReviewConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<OnlineDocumentReviewDomainSchema, typeof action>;
        });

    if (fetchResult.err) return fetchResult.val;

    const fCourseUid = String(fetchResult.val.uid);

    // Add JsonDatum to ActionObjects
    // TODO add a config endpoint for this method and clean up the entire fetch API thingies
    const targetGroupUids = mapped.targetGroupUids;
    targetGroupUids.forEach(async group => {
        const aoResult = (await ApiFetch<undefined>(`${API_URL}/data/admin/actionobject/${group}/${fCourseUid}/ViewCourse`, token).resolve())
            .map(v => v)
            .mapErr((err) => {
                return {
                    kind: "error",
                    schema: OnlineDocumentReviewConfig.id,
                    action: action,
                    value: undefined,
                    isValid: false,
                    wasCast: false,
                    errors: err,
                    stack: new Error().stack
                } as DomainRecordError<OnlineDocumentReviewDomainSchema, typeof action>;
            });
        if (aoResult.err) return aoResult.val;
    });


    // Save to CRG
    const crgUid = mapped.crg;
    const crgConfig = CourseRequirementGroupEndPoints.addCourseToCrg(crgUid, fCourseUid);
    const crgResult = (await ApiFetch<string>(crgConfig.url, token).resolve())
        .map(v => v)
        .mapErr((err) => {
            return {
                kind: "error",
                schema: OnlineDocumentReviewConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<OnlineDocumentReviewDomainSchema, typeof action>;
        });

    if (crgResult.err) return crgResult.val;

    // TODO Rework add/remove from CRG's as this will only work when Shop Training Materials and Annual Environmental Awareness Training are the only admin selectable groups
    const removeCrgUid = crgUid.toUpperCase() === "C9613306-7794-47B5-B6F9-55C0C4E774E2" ? "F294CA68-5B9D-41A0-A499-9A10053F4A95" : "C9613306-7794-47B5-B6F9-55C0C4E774E2";
    const removeCrgConfig = CourseRequirementGroupEndPoints.removeCourseFromCrg(removeCrgUid, fCourseUid);
    const removeCrgResult = (await ApiFetch<string>(removeCrgConfig.url, token).resolve())
        .map(v => v)
        .mapErr((err) => {
            return {
                kind: "error",
                schema: OnlineDocumentReviewConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<OnlineDocumentReviewDomainSchema, typeof action>;
        });

    if (removeCrgResult.err) return removeCrgResult.val;
    // TODO get crgResult.val for returned CRG even though it should match crgUid already

    const fetchedCourse = { ...unmapOnlineDocumentReview(JSON.parse(fetchResult.val.data)), uid: fetchResult.val.uid, crg: crgUidToId(crgUid), userGroups: targetGroupUids };

    const drvResult = await new DomainRecordValidator(OnlineDocumentReviewConfig.schema)
        .validate(fetchedCourse, "read").resolve();

    if (drvResult.err) return drvResult.val;

    const valid = drvResult.val; // does not contain crg yet

    return valid;
}


export function crgIdToUid(id: number): string {
    // TODO: REPLACE THIS CRG LOOKUP
    if (id === 1) return "B21CDB44-3789-47A0-A048-D94184A0BDE4";

    if (id === 2) return "7BF2A7CA-2566-4F42-84A9-AD913871EE3D";
    if (id === 3) return "5744BDA3-9DB6-4DBD-B2DC-A876B6C46DD4";

    if (id === 4) return "C9613306-7794-47B5-B6F9-55C0C4E774E2";
    if (id === 5) return "F294CA68-5B9D-41A0-A499-9A10053F4A95";

    throw Error("Unexpected crg ID received");
}

export function crgUidToId(uid: string): number {
    if(uid.toUpperCase() === "B21CDB44-3789-47A0-A048-D94184A0BDE4") return 1;
    if(uid.toUpperCase() === "7BF2A7CA-2566-4F42-84A9-AD913871EE3D") return 2;
    if(uid.toUpperCase() === "5744BDA3-9DB6-4DBD-B2DC-A876B6C46DD4") return 3;
    if(uid.toUpperCase() === "C9613306-7794-47B5-B6F9-55C0C4E774E2") return 4;
    if(uid.toUpperCase() === "F294CA68-5B9D-41A0-A499-9A10053F4A95") return 5;

    throw Error("Unexpected crg UID received");
}