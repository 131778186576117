import { UserProfileAdminDomainConfig } from "features/domainModel/UserProfile";
import React from "react";
import DMAdminGrid from "./DMAdminGrid";

export default function DMAdminGridTestPage() {
    const dca = UserProfileAdminDomainConfig;

    return (
        <DMAdminGrid 
            pageTitle="User Administration"
            pageDescription="Description"
            dca={dca}
        />
    );
}