// import { IconButton, Tooltip } from "@mui/material";
import React from "react";
import type { Column } from "react-data-grid";
import { SelectColumn, TextEditor, SelectCellFormatter } from "react-data-grid";
import type { Course } from "../../courses/data/course";
// import EmailIcon from '@mui/icons-material/Email';
// import LockOpenIcon from '@mui/icons-material/LockOpen';
// import { IconOpenPopupCoursesEditor } from "./PopupCoursesEditor";
// import { IconOpenODREditor } from "./ODR/ODREditor";
import { IconOpenModalDMEditor } from "./ModalDMEditor";

//import jsonCourseData from "../../courses/data/data_courses.json";

// const courseData: Course[] = jsonCourseData.courses as Course[];

export interface CourseRow {
    courseData: Course;
    uuid: string;
    name: string;
    description: string;
    type: string; // "Online - Self-Paced Module" | "In-Person - Event" | "In-Person - AdHoc"
    certificateEligible: boolean;
    certificateName: string;
    // certificateAdditional: string;
    active: boolean;
}
function stopPropagation(event: React.SyntheticEvent) {
    event.stopPropagation();
}

export function getColumns(onEditSuccess: () => void): readonly Column<CourseRow>[] {
    const cols: Column<CourseRow>[] = [
        // SelectColumn,
        {
            key: 'name',
            name: 'Course Name',
            width: 250,
            // editor: TextEditor
        },
        {
            key: "edit",
            name: "Action",
            frozen: true,
            width: 90,
            formatter(props) {                
                return (
                    <IconOpenModalDMEditor
                        kind={props.row.courseData.details.kind}
                        initialValues={props.row.courseData}
                        onSuccess={onEditSuccess}
                    />
                );
            }
        } as Column<CourseRow>,
        {
            key: 'type',
            name: 'Type',
            width: 250,
            // editor: (p) => {
            //     return (
            //         <select
            //             autoFocus
            //             style={{ height: "100%", width: "100%", padding: "0 6px", verticalAlign: "top" }}
            //             value={p.row.type}
            //             onChange={(e) => p.onRowChange({ ...p.row, type: e.target.value }, true)}
            //         >                        
            //             <option>Online - Self-Paced Module</option>
            //             <option>In-Person - Event</option>
            //             <option>In-Person - AdHoc</option>
            //         </select>
            //     );
            // }
        },
        {
            key: 'description',
            name: "Description",
            width: 200,
            // editor: TextEditor
        },
        {
            key: 'certificateEligible',
            name: 'Certificate?',
            width: 100,
            formatter({ row, onRowChange, isCellSelected }) {
                return (
                    <SelectCellFormatter
                        value={row.certificateEligible}
                        onChange={() => {
                            // onRowChange({ ...row, certificateEligible: !row.certificateEligible });
                        }}
                        // onClick={stopPropagation}
                        // isCellSelected={isCellSelected}
                        isCellSelected={row.certificateEligible}
                    />
                );
            }
        },
        {
            key: "certificateName",
            name: "Certificate Course Name",
            width: 200,
            // editor: TextEditor
        },
        // {
        //     key: "certificateAdditional",
        //     name: "Certificate - Additional Info",
        //     width: 200,
        //     // editor: TextEditor
        // },
        {
            key: "active",
            name: "Active",
            width: 80,
            formatter({ row, onRowChange, isCellSelected }) {
                return (
                    <SelectCellFormatter
                        value={row.active}
                        onChange={() => {
                            //onRowChange({ ...row, active: !row.active });
                        }}
                        //onClick={stopPropagation}
                        //isCellSelected={isCellSelected}
                        isCellSelected={row.active}
                    />
                );
            }
        }
    ];

    return cols;
}

export function getRows(courseData: Course[]): readonly CourseRow[] {
    // TODO: use redux for courseData

    const rows: CourseRow[] = [];
    courseData.forEach(course => {
        let type = "";
        switch (course.details.kind) {
            case "InPersonAdHoc":
                type = "Training Event";
                break;
            case "OnlineSelfPacedModule":
                type = "Online - Self-Paced Module";
                break;
            case "InPersonEvent":
                type = "In-Person - Event";
                break;
            case "OnlineDocReview":
                type = "Online - Document Review";
                break;
        }
        rows.push({
            courseData: course,
            uuid: course.uid,
            name: course.name,
            description: course.description,
            type: type,
            certificateEligible: Boolean(course.certificate),
            certificateName: typeof course.certificate === "object" ? course.certificate.name : "",
            // certificateAdditional: typeof course.certificate === "object" && course.certificate.additionalInfo ? course.certificate.additionalInfo : "",
            active: course.active
        } as CourseRow);
    });
    return rows;
}

export function rowKeyGetter(row: CourseRow) {
    return row.uuid;
}