import { ApiJsonDatum } from "features/common/api";
import { JsonDatumEndpoints } from "features/common/elevatedApiEndpoints";
import { ApiFetch } from "../asyncApi";
import { DataElementType, DomainRecordAction, DomainRecordError, DomainRecordValid, DomainRecordValues, DomainSchema } from "../types";
import { DomainConfig } from "../types/domainConfig";
import { DomainRecordValidator } from "../util";

const HomeDataElements: DataElementType[] = [
    {
        uid: "e08b1232-6f9e-4df2-9932-78f5bd2603b7",
        formLabel: "Home Page Markdown ID",
        reportLabel: "HomePageMD ID",
        formElement: "hidden"
    },
    {
        uid: "5fe43b06-37f8-49a3-b860-b9aa5913e864",
        formLabel: "Home Page Content",
        reportLabel: "Home Page Content",
        formElement: "markdown",
        helpText: [
            "You may use any of the following variables. The results are shown in the Preview section.",
            "{givenName} - the signed in user's given name",
            "{surname} - the signed in user's surname",
            "{email} - the signed in user's email address"
        ]
    }
];
const HomeSchema: DomainSchema = {
    uid: "f98dd62c-cb4f-4ccc-9f3a-a2cac97f0ac0",
    name: "homemd",
    version: 0,
    dataElements: HomeDataElements,
    fields: {
        uid: {
            sortOrder: 0,
            dataElementUid: "e08b1232-6f9e-4df2-9932-78f5bd2603b7",
            onAction: {
                base: { required: ["Required"] },
                create: { required: false, omit: true }
            }
        },
        md: {
            sortOrder: 1,
            dataElementUid: "5fe43b06-37f8-49a3-b860-b9aa5913e864",
            onAction: {
                base: { required: ["Required"] }
            }
        }
    },
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "Home Page Content",
                description: "Edit the Home Page content below using Markdown syntax.",
                fields: ["uid", "md"]
            }
        ]
    }
};

export interface HomeDomainSchema extends DomainSchema { }
export const HomeDomainConfig: DomainConfig<HomeDomainSchema> = {
    id: [HomeSchema.name, HomeSchema.uid, HomeSchema.version],
    schema: HomeSchema as HomeDomainSchema
};
const drv = new DomainRecordValidator(HomeDomainConfig.schema);
export function mapHome<T extends DomainRecordAction>(datum: any, action: T) {
    return drv.validate(datum, action)
        .map(v => v)
        .mapErr(v => v);
}
export function mapHomeError(error: string, action: DomainRecordAction) {
    return {
        kind: "error",
        schema: HomeDomainConfig.id,
        action: action,
        value: undefined,
        isValid: false,
        wasCast: false,
        errors: error,
        stack: new Error().stack
    } as DomainRecordError<HomeDomainSchema, typeof action>;
}

export async function saveHome<T extends Exclude<DomainRecordAction, "read">>(values: DomainRecordValues<HomeDomainSchema>, action: T, token: string): Promise<DomainRecordValid<HomeDomainSchema, "read"> | DomainRecordError<HomeDomainSchema, T | "read">> {
    const mappedAsync = await mapHome(values, action).resolve();
    if (mappedAsync.err) {
        throw Error(`Recieved invalid values for mapping: ${mappedAsync.val.errors}`);
    }
    const validValues = mappedAsync.val.value;
    const uid = validValues.uid;

    const datum: ApiJsonDatum = {
        uid: uid,
        schemaName: "homedm",
        userId: null,
        created: null,
        active: true,
        data: JSON.stringify(validValues)
    };

    const config = JsonDatumEndpoints.post(datum, "homemd");
    const fetchResult = (await ApiFetch<ApiJsonDatum>(config.url, token, config.requestBody).resolve())
        .map(v => v)
        .mapErr((err) => {
            return {
                kind: "error",
                schema: HomeDomainConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<HomeDomainSchema, typeof action>;
        });

    if (fetchResult.err) return fetchResult.val;

    const fetchedRecord = JSON.parse(fetchResult.val.data);
    const drvResult = await new DomainRecordValidator(HomeDomainConfig.schema).validate(fetchedRecord, "read").resolve();
    if (drvResult.err) return drvResult.val;

    const valid = drvResult.val; 

    return valid;
    
}