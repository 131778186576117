import { Course } from "features/courses/data/course";
import { API_URL } from "../../app/runtimeConstants";
import { ApiEndpoint, ApiJsonDatum, BinaryDatum, CourseUploadResult } from "./api";

export const BinaryDatumEndpoints = {
    // download endpoint is meant to be accessed by an anchor link with download attribute
    upload: (body: FormData) => ({
        url: `${API_URL}/bin`,
        authorizationRequired: true,
        method: "POST",
        requestBody: body,
        okResponseMapper: async (response) => {
            const data: Omit<BinaryDatum, "url"> = await response.json();
            return { ...data, url: `${API_URL}/bin/uid/${data.uid}`  }; 
        }
    } as ApiEndpoint<FormData, BinaryDatum>),
    downloadUrl: (uid: string) => `${API_URL}/bin/uid/${uid}`,
    metadata: (uid: string) => ({
        url: `${API_URL}/bin/meta/uid/${uid}`,
        authorizationRequired: true,
        method: "GET",
        okResponseMapper: async (response) => {
            let data = await response.json();
            return { ...data, url: `${API_URL}/bin/uid/${data.uid}` };
        }
    } as ApiEndpoint<undefined, BinaryDatum>),
    course: (body: FormData, courseUid?: string) => ({
        url: `${API_URL}/bin/course${courseUid ? `?courseUid=${courseUid}` : ""}`,
        authorizationRequired: true,
        method: "POST",
        requestBody: body,
        okResponseMapper: async (response) => {
            const data: CourseUploadResult = await response.json();
            return data;
        }
    } as ApiEndpoint<FormData, CourseUploadResult>)
};
export const AdminCoursesApiEndpoints = {
    GetAllCourses: {
        url: `${API_URL}/data/admin/schemaname/course/current?a=ManageOSPM&a=ManageODR&a=ManageOE&a=ManageIPE&a=ManageIPAH`,
        authorizationRequired: true,
        method: "GET",
        requestBody: undefined,
        okResponseMapper: async (response) => {
            return (await response.json() as ApiJsonDatum[]).map(datum => {
                const data = JSON.parse(datum.data) as Omit<Course, "uid">;

                //TODO: standardize on upper or lower cased uids
                const mapped = Object.assign({ uid: datum.uid }, data);
                return mapped;
            });
        }
    } as ApiEndpoint<undefined, Course[]>,
};

export const JsonDatumEndpoints = {
    post: (data: ApiJsonDatum, schemaName: string) => ({
        url: `${API_URL}/data/schemaName/${schemaName}`,
        authorizationRequired: true,
        method: "POST",
        requestBody: data,
        okResponseMapper: async (response) => await response.json()
    } as ApiEndpoint<ApiJsonDatum, ApiJsonDatum>)    
};
export const MiscEndpoints = {
    setCourseAttendees: (courseUid: string, start: string, end: string, userUids: string[]) => ({
        url: `${API_URL}/misc/attendees/${courseUid}?start=${encodeURIComponent(start)}&end=${encodeURIComponent(end)}`,
        authorizationRequired: true,
        method: "POST",
        requestBody: userUids,
        okResponseMapper: async (response) => await response.json()
    } as ApiEndpoint<string[], BasicUserInfo[]>),
    getCourseAttendees: (courseUid: string) => ({
        url: `${API_URL}/misc/attendees/${courseUid}`,
        authorizationRequired: true,
        method: "GET",
        okResponseMapper: async (response) => await response.json()
    } as ApiEndpoint<undefined, BasicUserInfo[]>)
};

export const CourseRequirementGroupEndPoints = {
    addCourseToCrg: (crgUid: string, courseUid: string) => ({
        url: `${API_URL}/data/admin/schemaName/courseRequirementGroup/${crgUid}/add/course/${courseUid}`,
        authorizationRequired: true,
        method: "GET",
        requestBody: undefined,
        okResponseMapper: async (response) => await response.text()
    } as ApiEndpoint<any, string>),
    removeCourseFromCrg: (crgUid: string, courseUid: string) => ({
        url: `${API_URL}/data/admin/schemaName/courseRequirementGroup/${crgUid}/remove/course/${courseUid}`,
        authorizationRequired: true,
        method: "GET",
        requestBody: undefined,
        okResponseMapper: async (response) => await response.text()
    } as ApiEndpoint<any, string>)
};

export interface BasicUserInfo {
    //TODO move this interface somewhere more logical
    uid: string;
    email: string;
    givenName: string;
    surname: string;
}

export const UserEndpoints = {
    userSearch: (searchTerm: string): ApiEndpoint<undefined, BasicUserInfo> => ({
        // TODO and searchTerm length check?
        url: `${API_URL}/data/admin/user/search/${encodeURIComponent(searchTerm)}`,
        authorizationRequired: true,
        method: "GET",
        requestBody: undefined,
        okResponseMapper: async (response) => await response.json()
    })
};