import {
    FormControl,
    IconButton,
    InputAdornment,
    Popover,
    TextField,
    Theme,
    useTheme,
} from "@mui/material";
import { useField } from "formik";
import { FMuiColorProps, FMuiProps, FMuiTextFieldProps, getFilteredPropsForInputSpread, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";
import { GithubPicker as ColorPicker } from "react-color";
import PaletteIcon from '@mui/icons-material/Palette';
import React from "react";

// // TODO: DRY - this is duplicated from FMuiTextField
// const useStyles = makeStyles((theme: Theme) =>
//     createStyles({
//         extraHelperTextWrapper: {
//             marginTop: -theme.spacing(2)
//         }
//     })
// );

//TODO these colors should be coming from theme
const colors = [
    // default colors from picker
    '#B80000', '#DB3E00', '#FCCB00', '#008B02', '#006B76', '#1273DE', '#004DCF', '#5300EB', '#EB9694', '#FAD0C3', '#FEF3BD', '#C1E1C5', '#BEDADC', '#C4DEF6', '#BED3F3', '#D4C4FB',
    // colors from omd online courses
    '#1A4B32', '#1D5237', '#20593C', '#236041', '#261A4B', '#281C4E', '#2A1E51', '#2C2054', '#2E2257', '#30245A', '#32265D', '#342860', '#362A63', '#382C66', '#3A2E69', '#3C306C', '#3E326F', '#403472', '#423675', '#443878', '#463A7B', '#483C7E', '#4A3E81', '#4C4084', '#4E4287', '#50448A', '#52468D', '#544890', '#564A93', '#584C96', '#5A4E99', '#5C509C', '#5E529F', '#6054A2', '#6256A5', '#6458A8', '#665AAB', '#685CAE', '#6A5EB1', '#6C60B4', '#2F2358', '#2F2357',
];

export default function FMuiColorField(props: FMuiProps<FMuiColorProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const textFieldProps = getFilteredPropsForInputSpread<FMuiProps<FMuiTextFieldProps>>(props);    
    const [cpEl, setCpEl] = React.useState<Element | null>(null);
    //const classes = useStyles();
    const theme = useTheme();    
    const colorValue = field.value; // || theme.palette.primary.main

    React.useEffect(() => {
        if (!field.value) {
            // set to a random color instead of using the theme.palette.primary.main all the time;
           helpers.setValue(colors[Math.floor(Math.random() * colors.length)] || theme.palette.primary.main);  //  || incase math is off
        }
    });    

    if (colorValue && !colors.includes(colorValue)) colors.push(colorValue);

    const handleClick = (e: React.MouseEvent) => {
        setCpEl(e.currentTarget);
    };
    const handleClose = () => setCpEl(null);

    const helperTexts = getFMuiFieldHelperText(props.formHelperText);

    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <TextField
                fullWidth
                label={props.label}
                name={field.name}
                onBlur={field.onBlur}
                error={inError}
                value={colorValue}
                helperText={errorMsg}
                {...textFieldProps}
                onClick={handleClick}
                inputProps={{ style: { color: colorValue } }}
                InputProps={{
                    endAdornment:
                        <InputAdornment position="end">
                            <IconButton onClick={handleClick} size="large">
                                <PaletteIcon htmlColor={colorValue} />
                            </IconButton>
                        </InputAdornment>
                }}
            />
            <Popover
                open={Boolean(cpEl)}
                anchorEl={cpEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                  }}
            >
                <ColorPicker 
                    color={colorValue}
                    colors={colors}
                    width={cpEl?.clientWidth ? `${cpEl.clientWidth}px` : "250px"}
                    onChangeComplete={(color: any, event: any) => { handleClose(); helpers.setValue(color.hex); }} />
            </Popover>

            {helperTexts && <div style={{marginTop: -theme.spacing(2)}} >{helperTexts}</div>}
        </FormControl>
    );
}