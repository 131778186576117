import { DomainSchema } from "../types";
import { CoursesDataElements } from "./dataElements";
import { OnlineDocumentReviewFields } from "./domainFieldsOnlineDocumentReview";

export const onlineDocumentReviewSchema: DomainSchema = {
    uid: "597ee65e-81ae-4067-a851-32f3c4c80f04",
    name: "onlineDocumentReview",
    version: 0,
    fields: OnlineDocumentReviewFields,
    dataElements: CoursesDataElements,
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "Basic Info",
                description: "Please complete the following information.",
                fields: ["uid", "courseName", "description", "color", "userGroups", "crg", "active"]
            },
            {
                title: "Completion Tracking",
                description: "Please complete the following information.",
                fields: ["trackingMethod", "certificateYN", "certificateCourseName", "expires", "expiryMo"]
            },
            {
                title: "Documents",
                description: "",
                fields: ["docs"]
            }
        ]
    }
}