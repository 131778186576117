import { DomainSchema } from "../types";
import { CoursesDataElements } from "./dataElements";
import { OnlineSelfPacedModuleFields } from "./domainFieldsOnlineSelfPacedModule";

export const onlineSelfPacedModuleDomainSchema: DomainSchema = {
    uid: "2c9f13b8-90c2-47ba-b5fd-a1c1bbf3ce44",
    name: "onlineSelfPacedModule",
    version: 0,
    fields: OnlineSelfPacedModuleFields,
    dataElements: CoursesDataElements,
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "Basic Info",
                description: "Please complete the following information.",
                fields: ["uid", "url", "courseName", "description", "color", "userGroups", "crg", "active"]
            },
            {
                title: "Completion Tracking",
                description: "Please complete the following information.",
                fields: ["certificateYN", "certificateCourseName", "expires", "expiryMo"]
            }
        ]
    }
};