import { Dialog, DialogContent, DialogTitle, IconButton, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { BasicUserInfo, MiscEndpoints } from "features/common/elevatedApiEndpoints";
import { Course } from "features/courses/data/course";
import { DMForm, DMFormProps } from "features/dmForms";
import { DomainRecordAction, DomainRecordError, DomainRecordValidator, DomainRecordValues, DomainSchema } from "features/domainModel";
import { ApiFetch } from "features/domainModel/asyncApi";
import { InPersonAdHocDomainConfig, onlineDocumentReviewConfig, saveInPersonAdHoc, unmapInPersonAdHoc } from "features/domainModel/Courses";
import { useSnackbar } from "notistack";
import React from "react";
// import { AppTheme } from "../../../components/Theme";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Details } from "@mui/icons-material";
import { crgUidToId, saveOnlineDocumentReview, unmapOnlineDocumentReview } from "features/domainModel/Courses/domainConfigOnlineDocumentReview";
import { CoursesApiEndpoints } from "features/common/userApiEndpoints";
import { CourseRequirementGroup } from "features/courses/courseRequirementGroups";
import { ApiJsonDatum } from "features/common/api";
import { UserGroup } from "features/auth/authAPI";
import { API_URL } from "app/runtimeConstants";
import { HomeDomainConfig, saveHome } from "features/domainModel/Home";
import { DownloadsDomainConfig } from "features/domainModel/Downloads";
import { OnlineSelfPacedModuleDomainConfig, saveOnlineSelfPacedModule, unmapOnlineSelfPacedModule } from "features/domainModel/Courses/domainConfigOnlineSelfPacedModule";
type DMKind = Course["details"]["kind"] | "homemd" | "download";

interface ModalDMEditorProps {
    kind: DMKind;
    initialValues?: any;
    open: boolean;
    onCancel: () => void;
    onSuccess: () => void;
}

export function IconOpenModalDMEditor(props: Pick<ModalDMEditorProps, "kind" | "initialValues" | "onSuccess">) {
    const [open, setOpen] = React.useState(false);

    return (
        <div>
            <Tooltip title="Edit">
                <IconButton size="small" onClick={() => setOpen(true)}>
                    <OpenInNewIcon />
                </IconButton>
            </Tooltip>
            <ModalDMEditor
                kind={props.kind}
                initialValues={props.initialValues}
                open={open}
                onCancel={() => setOpen(false)}
                onSuccess={() => { setOpen(false); props.onSuccess(); }}
            />
        </div>
    );
}

function convertInitialValues(val: any, kind: DMKind): DomainRecordValues<DomainSchema> {

    switch (kind) {
        case "InPersonAdHoc":
            return unmapInPersonAdHoc(val);
        case "OnlineDocReview":
            return unmapOnlineDocumentReview(val);
        case "OnlineSelfPacedModule":
            return unmapOnlineSelfPacedModule(val);
        case "homemd":
            return val;
        case "download":
            return val;
        default:
            throw Error(`Kind '${kind}' not supported`);
    }
}

export default function ModalDMEditor(props: ModalDMEditorProps) {
    const [pInitialValues, setPInitialValues] = React.useState(props.initialValues && convertInitialValues(props.initialValues, props.kind));

    const theme = useTheme();
    const fullscreenDialogs = useMediaQuery(theme.breakpoints.down('md'));
    // const muiClasses = AppTheme.useMuiStyles();
    const user = useAppSelector(state => state.oidc.user);
    const authUserUid = useAppSelector(state => state.auth.user?.uid);
    const { enqueueSnackbar } = useSnackbar();

    if (!user || !authUserUid) {
        throw Error("No user logged in");
    }

    const action: DomainRecordAction = pInitialValues ? "update" : "create";
    const domainConfig = props.kind === "InPersonAdHoc" ? InPersonAdHocDomainConfig
        : props.kind === "OnlineDocReview" ? onlineDocumentReviewConfig
            : props.kind === "OnlineSelfPacedModule" ? OnlineSelfPacedModuleDomainConfig
                : props.kind === "homemd" ? HomeDomainConfig
                    : props.kind === "download" ? DownloadsDomainConfig
                        : undefined;

    if (!domainConfig) {
        throw Error(`No editor available for ${props.kind}`);
    }
    const schema = domainConfig.schema;

    const [loading, setLoading] = React.useState(true);

    React.useEffect(() => {
        async function fetchAttendees(courseUid: string): Promise<BasicUserInfo[] | DomainRecordError<typeof schema, typeof action>> {
            const config = MiscEndpoints.getCourseAttendees(courseUid);
            const result = (await ApiFetch<BasicUserInfo[]>(config.url, user?.access_token).resolve())
                .map((v: any) => v)
                .mapErr((err: any) => {
                    return {
                        kind: "error",
                        schema: domainConfig?.id,
                        action: action,
                        value: undefined,
                        isValid: false,
                        wasCast: false,
                        errors: err,
                        stack: new Error().stack
                    } as DomainRecordError<typeof schema, typeof action>;
                });
            if (result.err) {
                return result.val.errors;
            }
            return result.val;
        }
        async function fetchCrgAndUserGroupMembership(courseUid: string): Promise<{ crg: string, userGroups: UserGroup[]; } | DomainRecordError<typeof schema, typeof action>> {
            const config = CoursesApiEndpoints.GetCourseRequirementGroups;
            const crgResult = (await ApiFetch<ApiJsonDatum[]>(config.url, user?.access_token).resolve())
                .map((v: any) => v)
                .mapErr((err: any) => {
                    return {
                        kind: "error",
                        schema: domainConfig?.id,
                        action: action,
                        value: undefined,
                        isValid: false,
                        wasCast: false,
                        errors: err,
                        stack: new Error().stack
                    } as DomainRecordError<typeof schema, typeof action>;
                });
            if (crgResult.err) {
                return crgResult.val;
            }

            const ugResult = (await ApiFetch<UserGroup[]>(`${API_URL}/data/admin/actionUserGroup/${courseUid}/ViewCourse`, user?.access_token).resolve())
                .map((v: any) => v)
                .mapErr((err: any) => {
                    return {
                        kind: "error",
                        schema: domainConfig?.id,
                        action: action,
                        value: undefined,
                        isValid: false,
                        wasCast: false,
                        errors: err,
                        stack: new Error().stack
                    } as DomainRecordError<typeof schema, typeof action>;
                });
            if (ugResult.err) {
                return ugResult.val;
            }

            let crgUid = "";
            crgResult.val.forEach((datum: { data: string; }) => {
                const crg: CourseRequirementGroup = JSON.parse(datum.data);
                if (crg.courseUids.includes(courseUid)) {
                    crgUid = crg.uid;
                }
            });

            //let ugs = ugResult.val.map(ug => JSON.parse(ug.data));

            return {
                crg: crgUid,
                userGroups: ugResult.val
            };
        }

        if (loading && props.open) {
            if (props.kind === "InPersonAdHoc" && action === "update") {
                // load attendees
                const courseUid = String(pInitialValues?.["uid"]);
                const courseName = String(pInitialValues?.["courseName"]);

                fetchAttendees(courseUid).then(result => {
                    if (Array.isArray(result)) {
                        //BasicUserInfo[]
                        setPInitialValues({ ...pInitialValues, attendees: result });
                    } else {
                        enqueueSnackbar(`Unable to load attendees for ${courseName || "Course"}`, { variant: "error" });
                    }
                }).catch(reason => enqueueSnackbar(`Unable to load attendees for ${courseName || "Course"}`, { variant: "error" })
                ).finally(() => setLoading(false));
            } else if ((props.kind === "OnlineDocReview" || props.kind === "OnlineSelfPacedModule") && action === "update") {
                // determine the crg the course belongs to
                const courseUid = String(pInitialValues?.["uid"]);
                const courseName = String(pInitialValues?.["courseName"]);
                fetchCrgAndUserGroupMembership(courseUid).then(result => {

                    if (Object.keys(result).includes("crg")) {
                        const r = result as { crg: string, userGroups: UserGroup[]; };

                        setPInitialValues({ ...pInitialValues, crg: crgUidToId(r.crg), userGroups: r.userGroups.map(g => g.uid) });
                    } else {
                        enqueueSnackbar(`Unable to determine the Course Requirement Groups and/or User Groups for ${courseName || "Course"}`, { variant: "error" });
                    }
                }).catch(reason => enqueueSnackbar(`Unable to determine the Course Requirement Groups and/or User Groups for ${courseName || "Course"}`, { variant: "error" })
                ).finally(() => setLoading(false));
            }
            else {
                setLoading(false);
            }
        }
    }, [props.open, action, domainConfig?.id, enqueueSnackbar, pInitialValues, props.kind, user?.access_token, loading]);

    let dmForm: JSX.Element = <></>;
    let dialogTitle = "Loading";
    if (!loading) {
        const drv = new DomainRecordValidator(schema);
        const { initialValues, validationSchema } = action === "create" ? drv.getPropsForFormik("create") : drv.getPropsForFormik("update", pInitialValues);
        dialogTitle = action === "create" ? `Create New ${getFriendlyKind(props.kind)} ` : `Editing ${getFriendlyKind(props.kind)} ${initialValues?.["courseName"] ? ": " + initialValues?.["courseName"] : ""}`;

        const dmFormProps: DMFormProps<DomainSchema, typeof action> = {
            domainSchema: schema,
            action: action,
            areInitialValuesValid: action === "update" ? true : false,
            onUserCancel: props.onCancel,
            forFormik: {
                initialValues: initialValues,
                validationSchema: validationSchema,
                onSubmit: (values, formikHelpers) => {
                    const { setSubmitting } = formikHelpers;
                    setSubmitting(true);

                    //TODO fix this undry switch
                    switch (props.kind) {
                        case "InPersonAdHoc":
                            saveInPersonAdHoc(values, action, authUserUid, user.access_token)
                                .then(result => {
                                    if (result.kind === "valid") {
                                        props.onSuccess();
                                        enqueueSnackbar("Course saved successfully", { variant: "success" });
                                    } else {
                                        // TODO: parse {result.errors} for a useful message (it might be an arrray of yup errors)
                                        enqueueSnackbar(<div><div>Error while saving course</div></div>, { variant: "error", persist: true });
                                    }
                                }).catch(reason => {
                                    // {reason}
                                    enqueueSnackbar(<div><div>Error while saving course</div><div></div></div>, { variant: "error", persist: true });
                                }).finally(() => {
                                    setSubmitting(false);
                                    setLoading(true); // force reload of attendees
                                });
                            break;
                        case "OnlineDocReview":
                            saveOnlineDocumentReview(values, action, authUserUid, user.access_token)
                                .then(result => {
                                    if (result.kind === "valid") {
                                        props.onSuccess();
                                        enqueueSnackbar("Course saved successfully", { variant: "success" });
                                    } else {
                                        // TODO: parse {result.errors} for a useful message (it might be an arrray of yup errors)
                                        enqueueSnackbar(<div><div>Error while saving course</div></div>, { variant: "error", persist: true });
                                    }
                                }).catch(reason => {
                                    // {reason}
                                    enqueueSnackbar(<div><div>Error while saving course</div><div></div></div>, { variant: "error", persist: true });
                                }).finally(() => {
                                    setSubmitting(false);
                                    setLoading(true); // force reload of attendees
                                });
                            break;
                        case "OnlineSelfPacedModule":
                            saveOnlineSelfPacedModule(values, action, authUserUid, user.access_token)
                            .then(result => {
                                if (result.kind === "valid") {
                                    props.onSuccess();
                                    enqueueSnackbar("Course saved successfully", { variant: "success" });
                                } else {
                                    // TODO: parse {result.errors} for a useful message (it might be an arrray of yup errors)
                                    enqueueSnackbar(<div><div>Error while saving course</div></div>, { variant: "error", persist: true });
                                }
                            }).catch(reason => {
                                // {reason}
                                enqueueSnackbar(<div><div>Error while saving course</div><div></div></div>, { variant: "error", persist: true });
                            }).finally(() => {
                                setSubmitting(false);
                                setLoading(true); // force reload of attendees
                            });
                        break;
                        case "homemd":
                            saveHome(values, action, user.access_token)
                                .then(result => {
                                    if (result.kind === "valid") {
                                        props.onSuccess();
                                        enqueueSnackbar("Home Page Content saved successfully", { variant: "success" });
                                    } else {
                                        // TODO: parse {result.errors} for a useful message (it might be an arrray of yup errors)
                                        enqueueSnackbar(<div><div>Error while saving Home Page Content</div></div>, { variant: "error", persist: true });
                                    }
                                }).catch(reason => {
                                    // {reason}
                                    enqueueSnackbar(<div><div>Error while saving Home Page Content</div><div></div></div>, { variant: "error", persist: true });
                                }).finally(() => {
                                    setSubmitting(false);
                                    setLoading(true); // force reload of attendees
                                });
                            break;
                        default:
                            enqueueSnackbar(<div><div>Error while saving</div></div>, { variant: "error", persist: true });
                            break;
                    }
                }
            }
        };
        dmForm = <DMForm {...dmFormProps} />;
    }

    return (
        <Dialog open={props.open} fullScreen={fullscreenDialogs} maxWidth={props.kind === "homemd" ? "xl" : undefined}>
            <DialogTitle>{dialogTitle}</DialogTitle>
            <DialogContent>
                {/* className={`${muiClasses.root}`} */}
                <div style={{ minWidth: "500px" }}>
                    {dmForm}
                </div>
            </DialogContent>
        </Dialog>
    );
}

function getFriendlyKind(kind: DMKind) {
    switch (kind) {
        case "InPersonAdHoc":
            return "Training Event";
        case "InPersonEvent":
            return "In-Person Event";
        case "OnlineDocReview":
            return "Online Document Review Course";
        case "OnlineEvent":
            return "Online Event";
        case "OnlineSelfPacedModule":
            return "Online Self-Paced Module";
        case "homemd":
            return "Home Page Content";
        case "download":
            return "Downloads";
        default:
            return kind;
    }
}