import { DomainRecordAction, DomainRecordDefaults, DomainRecordValid, DomainSchema } from "features/domainModel";
import { FormikConfig, FormikHelpers } from "formik";
import { StepForm } from "./StepForm";

export type DMFormAction = Exclude<DomainRecordAction, "read">;
export type DMFormValue<T extends DomainSchema, A extends DMFormAction> = (DomainRecordValid<T, A> | DomainRecordDefaults<T,A> )["value"]
export interface DMFormProps<T extends DomainSchema, A extends DMFormAction> {
    domainSchema: T,
    action: A,
    areInitialValuesValid: boolean,
    onUserCancel: () => void;
    //validator: DomainRecordValidator<T>; // is it needed?
    forFormik: {
        initialValues: FormikConfig<DMFormValue<T,A>>["initialValues"],
        validationSchema: FormikConfig<DMFormValue<T,A>>["validationSchema"],
        onSubmit: (values: DMFormValue<T,A>, formikHelpers: FormikHelpers<FormikConfig<DMFormValue<T,A>>["initialValues"]>) => any; //TODO: revise this
    }
}
export function DMForm<T extends DomainSchema, A extends DMFormAction>(props: DMFormProps<T, A>) {
    // calls to the snackbar come from onSubmit
    //
    // const { enqueueSnackbar } = useSnackbar();
    // const enqueueSnackbarProp = (message: string, type: SnackbarType) => {
    //     // discards key, assumes provider causes them to hide after a bit, may want to elevate this in the component hierarchy... maybe
    //     enqueueSnackbar(message, { variant: type });
    // };

    if (props.domainSchema.schema.kind === "stepForm") {
        return StepForm(props);
    }

    throw new Error(`DomainSchema '${props.domainSchema.name}' - schema of kind '${props.domainSchema.schema.kind}' is not implemented`);
};





