import type { DataElementType } from "../types";

export const UserProfileDataElements: DataElementType[] = [
    {
        uid: "1e58f126-0d29-4ade-93ad-c092929ce16c",
        formLabel: "Send student a welcome email with instructions for completing registration",
        reportLabel: "Welcome Email",
        formElement: "toggle"        
    },
    {
        uid: "aabb85af-eb9c-4063-97bb-3ac97d9b756f",
        formLabel: "User Id",
        reportLabel: "User Id",
        formElement: "hidden",
        validators: [{ kind: "uuid", params: ["Must be a valid uuid"] }]
    },
    {
        uid: "1ed1faf6-c346-4d22-8f4b-934bd73b711c",
        formLabel: "Email",
        reportLabel: "Email",
        formElement: "text",
        validators: [
            { kind: "email", params: ["Enter a valid email"] },
            // eslint-disable-next-line no-template-curly-in-string
            { kind: "emailAvailable", params: ["${value} is already registered. Please go back and use the Forgotten Password link to recover your account.", "/search/email/${email}"] },
        ]
    },
    {
        uid: "3fd3a796-85f7-4cf7-894f-67d77a7fd3e8",
        formLabel: "Password",
        reportLabel: "Password",
        formElement: "text",
        formElementOptions: { isPassword: true },
        validators: [
            { kind: "matches", params: ["^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})", "Password must contain at least 12 characters; one uppercase, one lowercase, one number, and one special character."] }
        ]
    },
    {
        uid: "70370903-7a06-4023-9460-8c8db023ac73",
        formLabel: "Confirm Password",
        reportLabel: "Confirm Password",
        formElement: "text",
        formElementOptions: { isPassword: true },
        validators: [{ kind: "oneOf", params: ["Must match Password", undefined, ["3fd3a796-85f7-4cf7-894f-67d77a7fd3e8"]] }]
    },
    {
        uid: "b07b14c4-fa4b-4aea-865f-718091240f94",
        formLabel: "Current Password",
        reportLabel: "Current Password",
        formElement: "text",
        formElementOptions: { isPassword: true }
    },
    {
        uid: "c1b1b5ef-1b52-40df-9804-854e4145e59a",
        formLabel: "New Password",
        reportLabel: "New Password",
        formElement: "text",
        formElementOptions: { isPassword: true },
        validators: [
            { kind: "matches", params: ["^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{12,})", { excludeEmptyString: true, message: "Password must contain at least 12 characters; one uppercase, one lowercase, one number, and one special character." }] }
        ]
    },
    {
        uid: "6717b0f1-04dd-45b5-a37d-817753518400",
        formLabel: "Confirm New Password",
        reportLabel: "Confirm New Password",
        formElement: "text",
        formElementOptions: { isPassword: true },
        validators: [{ kind: "oneOf", params: ["Must match New Password", undefined, ["c1b1b5ef-1b52-40df-9804-854e4145e59a"]] }]
    },
    {
        uid: "8424222a-69ba-40a7-960f-eb1d6a2421ff",
        formLabel: "Given Name",
        reportLabel: "Given Name",
        formElement: "text"
    },
    {
        uid: "be3fa7c6-bf88-49db-9996-75278db1d0f5",
        formLabel: "Surname",
        reportLabel: "Surname",
        formElement: "text"
    },
    {
        uid: "e748c420-15de-45e6-b475-88b10a0a7023",
        formLabel: "Organization",
        reportLabel: "Org",
        formElement: "tree",
        formElementOptions: {
            multiselect: false,
            items: [
                {
                    id: 165,
                    label: "ORARNG",
                    children: [

                        {
                            id: 249, label: "41 Infantry Brigade", children: [
                                { id: 250, label: "1-186 Infantry" },
                                { id: 252, label: "1-82 Cavalry" },
                                { id: 251, label: "141 Brigade Support Battalion" },
                                { id: 253, label: "2-162 Infantry" },
                                { id: 254, label: "2-218 Field Artillery" },
                                { id: 255, label: "741 Brigade Engineer Battalion" },

                            ]
                        },
                        {
                            id: 256, label: "82 Troop Brigade", children: [
                                { id: 257, label: "1249 Engineer Battalion" },
                                { id: 258, label: "2-641 Aviation" },
                                { id: 259, label: "3-116 Cavalry" },
                                { id: 260, label: "821 Troop Command Battalion" },
                            ]
                        },
                        { id: 283, label: "Financial Administrative Division" },
                        { id: 285, label: "Human Resources Division" },
                        {
                            id: 286, label: "Installations Division", children: [
                                { id: 287, label: "AGI - Construction" },
                                { id: 288, label: "AGI - Environmental" },
                                { id: 289, label: "AGI - Operations and Maintenance" },
                                { id: 295, label: "AGI - Support" },]
                        },
                        {
                            id: 166, label: "Joint Force Headquarters", children: [
                                { id: 261, label: "Army Band" },
                                { id: 262, label: "Biak Training Center" },
                                { id: 263, label: "Camp Rilea" },
                                { id: 264, label: "Civil Support Team" },
                                { id: 324, label: "Honor Guard" },
                                { id: 265, label: "Medical Command" },
                                { id: 266, label: "Museum" },
                                { id: 270, label: "Regional Training Institute" },
                                {
                                    id: 271, label: "State Aviation Office", children: [
                                        { id: 272, label: "Pendleton AASF" },
                                        { id: 273, label: "Salem AASF" },
                                    ]
                                },
                                {
                                    id: 274, label: "Surface Maintenance Shops", children: [
                                        { id: 313, label: "Clackamas FMS" },
                                        { id: 275, label: "COUTES" },
                                        { id: 276, label: "CRUTES" },
                                        { id: 309, label: "CSMS" },
                                        { id: 308, label: "Forest Grove FMS" },
                                        { id: 277, label: "La Grande FMS" },
                                        { id: 315, label: "Maison FMS Portland Subshop" },
                                        { id: 278, label: "Medford FMS" },
                                        { id: 279, label: "Portland FMS" },
                                        { id: 280, label: "Salem FMS" },
                                        { id: 281, label: "Springfield FMS" },
                                        { id: 314, label: "Umatilla FMS" },
                                    ]
                                },
                                { id: 284, label: "United States Property and Fiscal Office", children: [] },
                            ]
                        },
                        { id: 301, label: "Office of the Adjutant General", children: [{ id: 302, label: "Public Affairs Office" }] },
                        { id: 316, label: "Oregon Training Command" },
                        {
                            id: 303, label: "Oregon Youth Challenge Program", children: [
                                { id: 304, label: "Group Life Coordinators" },
                                { id: 305, label: "Support Staff" }
                            ]
                        },



                    ]
                },
                {
                    id: 400, // does not match legacy DB. All users with org memberships outside of 165 - ORARNG will need this added to their materialization path
                    label: "Other",
                    children: [
                        { id: 325, label: "ORANG", children: [{ id: 329, label: "116 ACS" }] },
                        {
                            id: 326,
                            label: "USAR",
                            children: [
                                {
                                    id: 330, label: "364 CA", children: [
                                        { id: 331, label: "D Co 448 CA" },
                                    ]
                                },
                                { id: 332, label: "671 EN" },
                                { id: 333, label: "909th" },
                            ]
                        },
                        { id: 327, label: "USMC" },
                        { id: 328, label: "USN" },
                    ]
                },
            ]
        }
    },
    {
        uid: "2FCF6D87-FD10-4CB1-94D4-60E80C590EA6",
        formLabel: "Do you have military duties?",
        reportLabel: "Military Duties",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 198,
                    label: "Yes"
                },
                {
                    id: 199,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "90727415-F957-4102-977D-544E36DD54EF",
        formLabel: "Are you a full-time Guard member? (AGR/ADOS)",
        reportLabel: "Full-time Guard Member",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 200,
                    label: "Yes"
                },
                {
                    id: 201,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "0750CE68-6AAD-46C3-B954-25ACAA71539A",
        formLabel: "Are you a state employee?",
        reportLabel: "State Employee",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 202,
                    label: "Yes"
                },
                {
                    id: 203,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "631AB676-6BE3-4128-B777-4AE6413D2147",
        formLabel: "Are you a full-time federal employee?",
        reportLabel: "Full-Time Federal Employee",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 204,
                    label: "Yes"
                },
                {
                    id: 205,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "915B0207-16C0-4C8B-A41C-D96F1005EFDE",
        formLabel: "OMD (State)",
        reportLabel: "OMD (State)",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 1,
                    label: "911 GIS ANALYST"
                },
                {
                    id: 2,
                    label: "911 LOCATION TECH COORD"
                },
                {
                    id: 3,
                    label: "911 PROGRAM ANALYST"
                },
                {
                    id: 4,
                    label: "ACCOUNTANT"
                },
                {
                    id: 5,
                    label: "ACCOUNTANT 1"
                },
                {
                    id: 6,
                    label: "ACCOUNTANT 3"
                },
                {
                    id: 7,
                    label: "ACCOUNTANT 4"
                },
                {
                    id: 8,
                    label: "ACCOUNTING SPECIALIST"
                },
                {
                    id: 9,
                    label: "ACCOUNTING TECHNICIAN 1"
                },
                {
                    id: 10,
                    label: "ACCOUNTING TECHNICIAN 2"
                },
                {
                    id: 11,
                    label: "ACCOUNTING TECHNICIAN 3"
                },
                {
                    id: 12,
                    label: "ADMINISTRATIVE SPECIALIST"
                },
                {
                    id: 13,
                    label: "ADMINISTRATIVE SPECIALIST 1"
                },
                {
                    id: 14,
                    label: "ADMINISTRATIVE SPECIALIST 2"
                },
                {
                    id: 15,
                    label: "AGC P&C SPEC"
                },
                {
                    id: 16,
                    label: "AGC PROCESS TECH"
                },
                {
                    id: 17,
                    label: "AGENCY RECRUITER"
                },
                {
                    id: 18,
                    label: "ALLIED TRADES DEPARTMENT MGR"
                },
                {
                    id: 19,
                    label: "ANTI TERRORISM PROGRAM MGR"
                },
                {
                    id: 20,
                    label: "ASST DIR"
                },
                {
                    id: 21,
                    label: "ASST PLATOON LEADER"
                },
                {
                    id: 22,
                    label: "ASST PROGRAM MGR"
                },
                {
                    id: 23,
                    label: "ASST TO DEP DIR ST AFFAIRS"
                },
                {
                    id: 24,
                    label: "ASST TO THE ADJUTANT GENERAL"
                },
                {
                    id: 25,
                    label: "ASST TO THE COMMANDANT"
                },
                {
                    id: 26,
                    label: "AUTOMOTIVE DEPARTMENT MGR"
                },
                {
                    id: 27,
                    label: "AUTOMOTIVE LABORER"
                },
                {
                    id: 28,
                    label: "AUTOMOTIVE PARTS"
                },
                {
                    id: 29,
                    label: "BUDGET ANALYST"
                },
                {
                    id: 30,
                    label: "CAPTAIN"
                },
                {
                    id: 31,
                    label: "CARPENTER"
                },
                {
                    id: 32,
                    label: "CLASS & POSN MGR"
                },
                {
                    id: 33,
                    label: "COMMANDANT"
                },
                {
                    id: 34,
                    label: "COMMUNICATION SYSTEMS TECH"
                },
                {
                    id: 35,
                    label: "COMMUNICATIONS OFFICER"
                },
                {
                    id: 36,
                    label: "COMMUNICATIONS SYSTEMS COORD"
                },
                {
                    id: 37,
                    label: "CONSTRUCTION INSPECTOR"
                },
                {
                    id: 38,
                    label: "CONSTRUCTION INSPECTOR"
                },
                {
                    id: 39,
                    label: "CONSTRUCTION PROJECT MANAGER 2"
                },
                {
                    id: 40,
                    label: "CONSTRUCTION PROJECT MANAGER 3"
                },
                {
                    id: 41,
                    label: "CONTRACT SPECIALIST"
                },
                {
                    id: 42,
                    label: "COOK"
                },
                {
                    id: 43,
                    label: "COOK 1"
                },
                {
                    id: 44,
                    label: "COUNSELOR"
                },
                {
                    id: 45,
                    label: "CP RILEA POST MAINT MGR"
                },
                {
                    id: 46,
                    label: "CRISIS COMM/PUB ED OFF"
                },
                {
                    id: 47,
                    label: "CSEPP ACCOUNTANT"
                },
                {
                    id: 48,
                    label: "CSEPP ADMIN SPEC"
                },
                {
                    id: 49,
                    label: "CSEPP PROG DIR - E REG"
                },
                {
                    id: 50,
                    label: "CSEPP SYS ANALYST"
                },
                {
                    id: 51,
                    label: "CSEPP TRAINING SPEC"
                },
                {
                    id: 52,
                    label: "CUSTODIAL SERVICES COORDINATOR"
                },
                {
                    id: 53,
                    label: "CUSTODIAN"
                },
                {
                    id: 54,
                    label: "CUSTODIAN KINGLSEY FIELD"
                },
                {
                    id: 55,
                    label: "DEPUTY DIRECTOR"
                },
                {
                    id: 56,
                    label: "DEPUTY FIRE CHIEF"
                },
                {
                    id: 57,
                    label: "DIRECTOR"
                },
                {
                    id: 58,
                    label: "DIRECTOR OF FINANCIAL ADMIN"
                },
                {
                    id: 59,
                    label: "DIRECTOR OF INSTALLATIONS"
                },
                {
                    id: 60,
                    label: "DOM PREP EXER COORD"
                },
                {
                    id: 61,
                    label: "DOM PREP PLANNER"
                },
                {
                    id: 62,
                    label: "DOM PREP TRNG COORD"
                },
                {
                    id: 63,
                    label: "ELECTRICIAN"
                },
                {
                    id: 64,
                    label: "ELECTRICIAN 1"
                },
                {
                    id: 65,
                    label: "ELECTRICIAN 2"
                },
                {
                    id: 66,
                    label: "ELECTRONICS MANAGER"
                },
                {
                    id: 67,
                    label: "EMERGENCY MGMNT SPEC"
                },
                {
                    id: 68,
                    label: "ENVIRONMENTAL BRANCH CHIEF"
                },
                {
                    id: 69,
                    label: "ENVIRONMENTAL FISCAL ANALYST"
                },
                {
                    id: 70,
                    label: "EVENT/MARKETING COORDINATOR"
                },
                {
                    id: 71,
                    label: "EVENT/MARKETING COORDINATOR"
                },
                {
                    id: 72,
                    label: "EXEC SUPPORT SPECIALIST 2"
                },
                {
                    id: 73,
                    label: "EXERCISE TRNG SPEC"
                },
                {
                    id: 74,
                    label: "FACILITIES ENGINEER 1"
                },
                {
                    id: 75,
                    label: "FACILITIES ENGINEER 2"
                },
                {
                    id: 76,
                    label: "FACILITIES OPERATIONS MANAGER"
                },
                {
                    id: 77,
                    label: "FACILITY ENERGY TECHNICIAN"
                },
                {
                    id: 78,
                    label: "FACILITY ENERGY TECHNICIAN 2"
                },
                {
                    id: 79,
                    label: "FACILITY ENERGY TECHNICIAN 3"
                },
                {
                    id: 80,
                    label: "FACILITY MAINT SUPERINTENDANT"
                },
                {
                    id: 81,
                    label: "FACILITY MAINTENANCE SPEC"
                },
                {
                    id: 82,
                    label: "FACILITY MANAGER"
                },
                {
                    id: 83,
                    label: "FACILITY OPERATIONS SPEC 1"
                },
                {
                    id: 84,
                    label: "FIN RECOVERY SVC SEC DIR"
                },
                {
                    id: 85,
                    label: "FIREFIGHTER"
                },
                {
                    id: 86,
                    label: "FISCAL ANALYST 1"
                },
                {
                    id: 87,
                    label: "FISCAL ANALYST 2"
                },
                {
                    id: 88,
                    label: "FISCAL ANALYST 3"
                },
                {
                    id: 89,
                    label: "FISCAL COORD"
                },
                {
                    id: 90,
                    label: "FISCAL OPERATIONS COORD"
                },
                {
                    id: 91,
                    label: "FMS - AVIATION CTR"
                },
                {
                    id: 92,
                    label: "FORCE PROTECTION LEADER"
                },
                {
                    id: 93,
                    label: "FORCE PROTECTION OFFICER"
                },
                {
                    id: 94,
                    label: "FORCE PROTECTION SENIOR OFF"
                },
                {
                    id: 95,
                    label: "FOS - REGION 3"
                },
                {
                    id: 96,
                    label: "FPO"
                },
                {
                    id: 97,
                    label: "GIS ANALYST"
                },
                {
                    id: 98,
                    label: "GRANTS COORD ACCT"
                },
                {
                    id: 99,
                    label: "GRANTS COORDINATOR"
                },
                {
                    id: 100,
                    label: "GROUNDS MAINTENANCE WORKER 1"
                },
                {
                    id: 101,
                    label: "GROUNDS MAINTENANCE WORKER 2"
                },
                {
                    id: 102,
                    label: "GROUP LIFE COORDINATOR 1"
                },
                {
                    id: 103,
                    label: "GROUP LIFE COORDINATOR 2"
                },
                {
                    id: 104,
                    label: "GROUP LIFE COORDINATOR 3"
                },
                {
                    id: 105,
                    label: "GROUP LIFE SUPERVISOR"
                },
                {
                    id: 106,
                    label: "HAZ MITIGATION OFFICER"
                },
                {
                    id: 107,
                    label: "HIGHWAY MAINTENANCE SPECIALIST"
                },
                {
                    id: 108,
                    label: "HOMELAND SEC GRANTS ACCT"
                },
                {
                    id: 109,
                    label: "HOUSEKEEPER"
                },
                {
                    id: 110,
                    label: "HUMAN RESOURCE ASSISTANT"
                },
                {
                    id: 111,
                    label: "INFO REP/OFFICE MGR"
                },
                {
                    id: 112,
                    label: "INFO SYS SPEC"
                },
                {
                    id: 113,
                    label: "INFO SYSTEMS MANAGER"
                },
                {
                    id: 114,
                    label: "INFO SYSTEMS SPECIALIST 4"
                },
                {
                    id: 115,
                    label: "INFO SYSTEMS SPECIALIST 5"
                },
                {
                    id: 116,
                    label: "INFRASTRUCTURE PROG COORD"
                },
                {
                    id: 117,
                    label: "INSTALLATIONS PROJECT MGR"
                },
                {
                    id: 118,
                    label: "INVESTIGATOR 2"
                },
                {
                    id: 119,
                    label: "ITAM COORDINATOR"
                },
                {
                    id: 120,
                    label: "LABORER 1"
                },
                {
                    id: 121,
                    label: "LABORER 2"
                },
                {
                    id: 122,
                    label: "LD FPO PANG"
                },
                {
                    id: 123,
                    label: "LEAD COOK"
                },
                {
                    id: 124,
                    label: "LIEUTENANT"
                },
                {
                    id: 125,
                    label: "LINE SUPERVISOR"
                },
                {
                    id: 126,
                    label: "LIQUID FUELS SPEC"
                },
                {
                    id: 127,
                    label: "MAINTENANCE & OPERATIONS SUPV"
                },
                {
                    id: 128,
                    label: "MAINTENANCE FOREMAN"
                },
                {
                    id: 129,
                    label: "MAJOR CONSTR BRANCH COORD"
                },
                {
                    id: 130,
                    label: "MAJOR CONSTRUCTION BRNCH CHIEF"
                },
                {
                    id: 131,
                    label: "MECHANIC"
                },
                {
                    id: 132,
                    label: "MIL RESOURCE PROTECTION PATROL"
                },
                {
                    id: 133,
                    label: "MILITARY LEASE AGENT"
                },
                {
                    id: 134,
                    label: "MILITARY POLICY ADVISER"
                },
                {
                    id: 135,
                    label: "MINOR CONSTRUCTION PROG MGR"
                },
                {
                    id: 136,
                    label: "MLA COORDINATOR"
                },
                {
                    id: 137,
                    label: "NATURAL RESOURCE SPECIALIST 2"
                },
                {
                    id: 138,
                    label: "NATURAL RESOURCE SPECIALIST 4"
                },
                {
                    id: 139,
                    label: "NETWORK ANALYST"
                },
                {
                    id: 140,
                    label: "OEM ACCOUNTANT"
                },
                {
                    id: 141,
                    label: "OEM ADMIN SPEC"
                },
                {
                    id: 142,
                    label: "OEM DIRECTOR"
                },
                {
                    id: 143,
                    label: "OEM FINANCIAL ANAL/COORD"
                },
                {
                    id: 144,
                    label: "OEM OFFICE SPEC"
                },
                {
                    id: 145,
                    label: "OEM PLANNER"
                },
                {
                    id: 146,
                    label: "OEM PROGRAM ASST"
                },
                {
                    id: 147,
                    label: "OFFICE COORDINATOR"
                },
                {
                    id: 148,
                    label: "OFFICE SPECIALIST 1"
                },
                {
                    id: 149,
                    label: "OFFICE SPECIALIST 2"
                },
                {
                    id: 150,
                    label: "OPERATIONS & POLICY ANALYST 2"
                },
                {
                    id: 151,
                    label: "ORNG SERVICES COORD"
                },
                {
                    id: 152,
                    label: "ORNG SERVICES COORDINATOR"
                },
                {
                    id: 153,
                    label: "OYCP COOK"
                },
                {
                    id: 154,
                    label: "OYCP FISCAL ANANLYST"
                },
                {
                    id: 155,
                    label: "PAINTER"
                },
                {
                    id: 156,
                    label: "PANG FACILITY MAINT SPECIALIST"
                },
                {
                    id: 157,
                    label: "PHYSCL/ELECTRNC SECRTY TECH 2"
                },
                {
                    id: 158,
                    label: "PHYSCL/ELECTRNC SECRTY TECH 3"
                },
                {
                    id: 159,
                    label: "PLANNER 3"
                },
                {
                    id: 160,
                    label: "PLANNING & PROG BRANCH CHIEF"
                },
                {
                    id: 161,
                    label: "PLANS & TRNG SVC SEC DIR"
                },
                {
                    id: 162,
                    label: "PLUMBER"
                },
                {
                    id: 163,
                    label: "POST RESIDENTIAL SUPV"
                },
                {
                    id: 164,
                    label: "PROCUREMENT & CONTRACT SPEC 1"
                },
                {
                    id: 165,
                    label: "PROCUREMENT & CONTRACT SPEC 3"
                },
                {
                    id: 166,
                    label: "PROCUREMENT AND CONTRACT ASST"
                },
                {
                    id: 167,
                    label: "PROGRAM ANALYST 1"
                },
                {
                    id: 168,
                    label: "PROGRAM ANALYST 2"
                },
                {
                    id: 169,
                    label: "PROGRAM ANALYST 4"
                },
                {
                    id: 170,
                    label: "PROGRAM COORDINATOR"
                },
                {
                    id: 171,
                    label: "PROPERTY SPECIALIST"
                },
                {
                    id: 172,
                    label: "PUBLIC AFFAIRS SPECIALIST"
                },
                {
                    id: 173,
                    label: "RANGE CONTROL"
                },
                {
                    id: 174,
                    label: "RANGE OPERATOR"
                },
                {
                    id: 175,
                    label: "REGION 2 - CARPENTER"
                },
                {
                    id: 176,
                    label: "REGIONAL COORDINATOR"
                },
                {
                    id: 177,
                    label: "RENTAL PROGRAM MANAGER"
                },
                {
                    id: 178,
                    label: "REPAIR SITE PROGRAM MANAGER"
                },
                {
                    id: 179,
                    label: "RESIDENTIAL SECURITY STAFF"
                },
                {
                    id: 180,
                    label: "RILEA CARPENTER"
                },
                {
                    id: 181,
                    label: "RILEA FOOD MGR"
                },
                {
                    id: 182,
                    label: "RPOM BRANCH CHIEF"
                },
                {
                    id: 183,
                    label: "RSMS BUDGET OFFICER"
                },
                {
                    id: 184,
                    label: "RSMS CONTRACT SPECIALIST"
                },
                {
                    id: 185,
                    label: "RSMS MECHANIC"
                },
                {
                    id: 186,
                    label: "RSMS MECHANIC 1"
                },
                {
                    id: 187,
                    label: "RSMS MECHANIC 2"
                },
                {
                    id: 188,
                    label: "RSMS PARTS SPEC 2"
                },
                {
                    id: 189,
                    label: "RSMS PARTS SPECIALIST"
                },
                {
                    id: 190,
                    label: "RSMS PARTS SPECIALIST 2"
                },
                {
                    id: 191,
                    label: "RSMS SUPPLY SPECIALIST"
                },
                {
                    id: 192,
                    label: "RSMS TOOL ROOM COORD"
                },
                {
                    id: 193,
                    label: "SAFETY OFFICER"
                },
                {
                    id: 194,
                    label: "SCIENTIFIC INSTRUMENT TECH 2"
                },
                {
                    id: 195,
                    label: "SEASONAL LABORER"
                },
                {
                    id: 196,
                    label: "SECURITY FORCES OPNS MGR"
                },
                {
                    id: 197,
                    label: "SEISMIC REHAB ACCOUNTANT"
                },
                {
                    id: 198,
                    label: "SEISMIC REHAB ADMIN SUPPORT"
                },
                {
                    id: 199,
                    label: "SEISMIC REHAB FACILITIES ENGIN"
                },
                {
                    id: 200,
                    label: "SEISMIC REHAB PROGRAM ANALYST"
                },
                {
                    id: 201,
                    label: "SENIOR HUMAN RESOURCE ANAYLST"
                },
                {
                    id: 202,
                    label: "STATE HUMAN RESOURCE DIRECTOR"
                },
                {
                    id: 203,
                    label: "SUPPLY DEPARTMENT MANAGER"
                },
                {
                    id: 204,
                    label: "SUPPORT BRANCH CHIEF"
                },
                {
                    id: 205,
                    label: "TECH/RESP SVC SEC MAN"
                },
                {
                    id: 206,
                    label: "TEMP"
                },
                {
                    id: 207,
                    label: "THE ADJUTANT GENERAL"
                },
                {
                    id: 208,
                    label: "TRADES MAINTENANCE WORKER"
                },
                {
                    id: 209,
                    label: "TRAINING OFFICER"
                },
                {
                    id: 210,
                    label: "TRANSP MAINTENANCE SPECIALST 1"
                },
                {
                    id: 211,
                    label: "TRANSPORTATION MAINT SPEC 2"
                },
                {
                    id: 212,
                    label: "WITHYCOMBE CUSTODIAN"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "D6D70570-DD28-48FB-A68E-6C92D2E99B32",
        formLabel: "What is your full-time status?",
        reportLabel: "Full-time Status",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 2,
                    label: "ADOS Position"
                },
                {
                    id: 1,
                    label: "AGR Position"
                }
            ]
        }
    },
    {
        uid: "9441F0AF-AEE6-4E17-823A-2EAF63F10DC7",
        formLabel: "What is your full-time status?",
        reportLabel: "Full-time Status",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 2,
                    label: "General Schedule (GS) Position"
                },
                {
                    id: 3,
                    label: "Wage Grade (WG) Position"
                }
            ]
        }
    },
    {
        uid: "AE98001E-42B1-4530-B639-C8D1FB96840A",
        formLabel: "Position",
        reportLabel: "ADOS Position",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 1,
                    label: "AASF COMMANDER"
                },
                {
                    id: 2,
                    label: "ADMINISTRATIVE ASSISTANT"
                },
                {
                    id: 3,
                    label: "ADMINISTRATIVE NCO"
                },
                {
                    id: 4,
                    label: "ADMINISTRATIVE NCO-LOGISTICS"
                },
                {
                    id: 5,
                    label: "ADMINISTRATIVE NCO-OPERATIONS"
                },
                {
                    id: 6,
                    label: "ADMINISTRATIVE OFFICER (PCA)"
                },
                {
                    id: 7,
                    label: "ADMINISTRATIVE SGT-OPERATIONS"
                },
                {
                    id: 8,
                    label: "ADMINISTRATIVE/SUPPLY NCO"
                },
                {
                    id: 9,
                    label: "AIDE DE CAMP"
                },
                {
                    id: 10,
                    label: "AIRCRAFT FLIGHT INSTRUCTOR (SAFETY)"
                },
                {
                    id: 11,
                    label: "AMEDD STR MANAGER"
                },
                {
                    id: 12,
                    label: "ANTI-TERRORISM PLANS"
                },
                {
                    id: 13,
                    label: "AREA NCO"
                },
                {
                    id: 14,
                    label: "ARMORER"
                },
                {
                    id: 15,
                    label: "ASSISTANT AVIATION OPERATIONS SGT"
                },
                {
                    id: 16,
                    label: "ASSISTANT COMSEC CUSTODIAN"
                },
                {
                    id: 17,
                    label: "ASSISTANT OPERATIONS NCO"
                },
                {
                    id: 18,
                    label: "ASSISTANT OPERATIONS OFFICER"
                },
                {
                    id: 19,
                    label: "ASSISTANT OPERATIONS SGT"
                },
                {
                    id: 20,
                    label: "ASSISTANT PROFESSOR MILITARY SCIENCE"
                },
                {
                    id: 21,
                    label: "ASSISTANT S3"
                },
                {
                    id: 22,
                    label: "ASSISTANT SUPPLY SGT"
                },
                {
                    id: 23,
                    label: "ASSISTANT TRAINING INSTRUCTOR"
                },
                {
                    id: 24,
                    label: "ASSISTANT TRAINING NCO"
                },
                {
                    id: 25,
                    label: "ASSISTANT TRAINING OFFICER"
                },
                {
                    id: 26,
                    label: "AUTOMATION NCO"
                },
                {
                    id: 27,
                    label: "AVIATION OPERATIONS CHIEF"
                },
                {
                    id: 28,
                    label: "AVIATION SAFETY OFFICER"
                },
                {
                    id: 29,
                    label: "BAND COMMANDANT"
                },
                {
                    id: 30,
                    label: "BN SIGNAL NCO"
                },
                {
                    id: 31,
                    label: "BUDGET MANAGER"
                },
                {
                    id: 32,
                    label: "CHIEF FIRE CONTROL SGT"
                },
                {
                    id: 33,
                    label: "CHIEF HUMAN RESOURCES SGT"
                },
                {
                    id: 34,
                    label: "CHIEF INSTRUCTOR"
                },
                {
                    id: 35,
                    label: "CHIEF MANAGEMENT ASSEST SUPERVISOR"
                },
                {
                    id: 36,
                    label: "CHIEF MILPO"
                },
                {
                    id: 37,
                    label: "CHIEF OF PLANS"
                },
                {
                    id: 38,
                    label: "CHIEF OF STAFF"
                },
                {
                    id: 39,
                    label: "CHIEF OPERATIONS SGT"
                },
                {
                    id: 40,
                    label: "CHIEF PARALEGAL NCO"
                },
                {
                    id: 41,
                    label: "CHIEF PERSONNEL SVC MANAGER"
                },
                {
                    id: 42,
                    label: "COMMAND ADMINISTRATIVE OFFICER"
                },
                {
                    id: 43,
                    label: "COMMAND PROGRAM SUPPORT SPECIALIST"
                },
                {
                    id: 44,
                    label: "COMMAND SERGEANT MAJOR"
                },
                {
                    id: 45,
                    label: "COMMAND TEAM CHIEF"
                },
                {
                    id: 46,
                    label: "COMMAND WARRANT OFFICER"
                },
                {
                    id: 47,
                    label: "COMMANDANT"
                },
                {
                    id: 48,
                    label: "COMMANDER"
                },
                {
                    id: 49,
                    label: "COMSEC MATERIAL MANAGER"
                },
                {
                    id: 50,
                    label: "COUNTER DRUG COOR"
                },
                {
                    id: 51,
                    label: "CRYPTO LINQUIST"
                },
                {
                    id: 52,
                    label: "DATA SYSTEMS INTEGRATION"
                },
                {
                    id: 53,
                    label: "DEPUTY CDR"
                },
                {
                    id: 54,
                    label: "DEPUTY COMMANDER"
                },
                {
                    id: 55,
                    label: "DEPUTY G1"
                },
                {
                    id: 56,
                    label: "DEPUTY G2"
                },
                {
                    id: 57,
                    label: "DEPUTY SJA"
                },
                {
                    id: 58,
                    label: "DEPUTY TRAINING SITE MANAGER"
                },
                {
                    id: 59,
                    label: "DET NCO"
                },
                {
                    id: 60,
                    label: "DET READINESS NCO"
                },
                {
                    id: 61,
                    label: "EDUCATION NCO"
                },
                {
                    id: 62,
                    label: "EDUCATION OFFICER"
                },
                {
                    id: 63,
                    label: "EFFECTS OFFICER"
                },
                {
                    id: 64,
                    label: "EXECUTIVE ADMINISTRATIVE ASSISTANT"
                },
                {
                    id: 65,
                    label: "FF SECT LDR"
                },
                {
                    id: 66,
                    label: "FIRE CONTROL SGT"
                },
                {
                    id: 67,
                    label: "FIRST SERGEANT/SENIOR OPERATIONS NCO"
                },
                {
                    id: 68,
                    label: "FOOD SERVICE NCO"
                },
                {
                    id: 69,
                    label: "FORCE DEV NCO"
                },
                {
                    id: 70,
                    label: "FORCE INTEGRATION OFFICER"
                },
                {
                    id: 71,
                    label: "FORCE INTEGRATION READINESS OFFICER"
                },
                {
                    id: 72,
                    label: "FORWARD SIGNAL SPT NCO"
                },
                {
                    id: 73,
                    label: "FW PILOT"
                },
                {
                    id: 74,
                    label: "FWD SIGNAL SPT NCO"
                },
                {
                    id: 75,
                    label: "HEALTH CARE NCO"
                },
                {
                    id: 76,
                    label: "HEALTH CARE SGT"
                },
                {
                    id: 77,
                    label: "HEALTH CARE SPECIALIST"
                },
                {
                    id: 78,
                    label: "HUMAN RESOURCE NCO"
                },
                {
                    id: 79,
                    label: "HUMAN RESOURCES MGMT OFFICER"
                },
                {
                    id: 80,
                    label: "HUMAN RESOURCES OFFICER"
                },
                {
                    id: 81,
                    label: "HUMAN RESOURCES SGT"
                },
                {
                    id: 82,
                    label: "INFORMATION SYSTEMS ANALYST"
                },
                {
                    id: 83,
                    label: "INFORMATION SYSTEMS SPECIALIST"
                },
                {
                    id: 84,
                    label: "INFORMATION SYSTEMS TEAM CHIEF"
                },
                {
                    id: 85,
                    label: "INFORMATION SYSTEMS TECH"
                },
                {
                    id: 86,
                    label: "INSPECTOR GENERAL"
                },
                {
                    id: 87,
                    label: "INSPECTOR GENERAL NCO"
                },
                {
                    id: 88,
                    label: "INSTRUCTOR"
                },
                {
                    id: 89,
                    label: "INTELLIGENCE ANALYST"
                },
                {
                    id: 90,
                    label: "INTELLIGENCE SGT"
                },
                {
                    id: 91,
                    label: "INTERSTATE COORDINATOR"
                },
                {
                    id: 92,
                    label: "J1/HRO"
                },
                {
                    id: 93,
                    label: "JAG OFFICER"
                },
                {
                    id: 94,
                    label: "LOGISTICS NCO"
                },
                {
                    id: 95,
                    label: "LOGISTICS OFFICER"
                },
                {
                    id: 96,
                    label: "LOGISTICS PLANNER"
                },
                {
                    id: 97,
                    label: "MAINTENANCE MANAGEMENT NCO"
                },
                {
                    id: 98,
                    label: "MARKETING NCO"
                },
                {
                    id: 99,
                    label: "MASTER GUNNER"
                },
                {
                    id: 100,
                    label: "MATERIAL CNTL/ACCTG SPC"
                },
                {
                    id: 101,
                    label: "MATERIAL CNTL/HDLG SPC"
                },
                {
                    id: 102,
                    label: "MATERIAL CONTROL / ACCOUNTING NCO"
                },
                {
                    id: 103,
                    label: "MATERIAL MANAGEMENT NCO"
                },
                {
                    id: 104,
                    label: "MATERIAL STORAGE & HANDLING NCO"
                },
                {
                    id: 105,
                    label: "MATERIAL STORAGE HANDLING NCO"
                },
                {
                    id: 106,
                    label: "MATERIAL STORE/HDLG SGT"
                },
                {
                    id: 107,
                    label: "MATERIALS MANAGEMENT SUPERVISOR"
                },
                {
                    id: 108,
                    label: "MEDICAL LOGISTICS SGT"
                },
                {
                    id: 109,
                    label: "MEDICAL OPERATIONS OFFICER"
                },
                {
                    id: 110,
                    label: "MEDICAL SUPPLY NCO"
                },
                {
                    id: 111,
                    label: "MEDICAL SUPPLY SERGEANT"
                },
                {
                    id: 112,
                    label: "MEPS GC"
                },
                {
                    id: 113,
                    label: "MIL PERSONNEL PAY CLERK/TYPIST/TECH"
                },
                {
                    id: 114,
                    label: "MOB NCOIC"
                },
                {
                    id: 115,
                    label: "MOB PLANS OFF"
                },
                {
                    id: 116,
                    label: "MOB RDNS OFF"
                },
                {
                    id: 117,
                    label: "MOBILITY NCO"
                },
                {
                    id: 118,
                    label: "MOBILITY OFFICER"
                },
                {
                    id: 119,
                    label: "NBC NCO"
                },
                {
                    id: 120,
                    label: "NBC RECON NCO"
                },
                {
                    id: 121,
                    label: "NBC STAFF NCO"
                },
                {
                    id: 122,
                    label: "NBC TEAM CHIEF"
                },
                {
                    id: 123,
                    label: "NETWORK MGMT TECHNICIAN"
                },
                {
                    id: 124,
                    label: "NETWORKING OFFICER"
                },
                {
                    id: 125,
                    label: "NUC MED SCI OFF"
                },
                {
                    id: 126,
                    label: "OCS RDNS NCO"
                },
                {
                    id: 127,
                    label: "OFF STRENGTH MANAGER"
                },
                {
                    id: 128,
                    label: "OPERATIONS & TRAINING OFF"
                },
                {
                    id: 129,
                    label: "OPERATIONS NCO"
                },
                {
                    id: 130,
                    label: "OPERATIONS NCO"
                },
                {
                    id: 131,
                    label: "OPERATIONS NCO MODELING"
                },
                {
                    id: 132,
                    label: "OPERATIONS OFFICER"
                },
                {
                    id: 133,
                    label: "OPERATIONS OFFICER-CERFP"
                },
                {
                    id: 134,
                    label: "OPERATIONS SGM"
                },
                {
                    id: 135,
                    label: "OPERATIONS SGT"
                },
                {
                    id: 137,
                    label: "OPERATIONS SGT-CERFP"
                },
                {
                    id: 136,
                    label: "OPERATIONS SGT/MASTER GUNNER"
                },
                {
                    id: 138,
                    label: "OPERATIONS/SIMS OFFICER"
                },
                {
                    id: 139,
                    label: "OPS LAW NCO"
                },
                {
                    id: 240,
                    label: "OTHER"
                },
                {
                    id: 140,
                    label: "PARALEGAL NCO"
                },
                {
                    id: 141,
                    label: "PAT/PBO"
                },
                {
                    id: 142,
                    label: "PERSONNEL ADMININSTRATION SERGEANT"
                },
                {
                    id: 143,
                    label: "PERSONNEL ADMINISTRATION TECH"
                },
                {
                    id: 144,
                    label: "PERSONNEL INFORMATION SPECIALIST"
                },
                {
                    id: 145,
                    label: "PERSONNEL INFORMATION SYSTEM SUPERVISOR"
                },
                {
                    id: 146,
                    label: "PERSONNEL NCO"
                },
                {
                    id: 147,
                    label: "PERSONNEL NCO"
                },
                {
                    id: 148,
                    label: "PERSONNEL RCDS SGT"
                },
                {
                    id: 149,
                    label: "PERSONNEL SERGEANT"
                },
                {
                    id: 150,
                    label: "PERSONNEL SERVICES SERGEANT"
                },
                {
                    id: 151,
                    label: "PHYSICIANS ASSISTANT"
                },
                {
                    id: 152,
                    label: "PLANS & OPERATIONS OFFICER"
                },
                {
                    id: 153,
                    label: "PLANS & OPS ASSISTANT"
                },
                {
                    id: 154,
                    label: "PLANS & OPS NCO"
                },
                {
                    id: 155,
                    label: "PLANS OFFICER"
                },
                {
                    id: 156,
                    label: "PLANS OPERATIONS & TRAINING OFF"
                },
                {
                    id: 157,
                    label: "PO & MS OFFICER"
                },
                {
                    id: 158,
                    label: "PROPERTY BOOK NCO"
                },
                {
                    id: 159,
                    label: "PROPERTY BOOK OFFICER"
                },
                {
                    id: 160,
                    label: "PROPERTY BOOK TEAM CHIEF"
                },
                {
                    id: 161,
                    label: "QM & CHEM EQUIP REPAIRER"
                },
                {
                    id: 162,
                    label: "RANGE OFFICER"
                },
                {
                    id: 163,
                    label: "RANGE OPERATIONS NCO"
                },
                {
                    id: 164,
                    label: "RANGE SAFETY NCO"
                },
                {
                    id: 165,
                    label: "RANGE SCHEDULE NCO"
                },
                {
                    id: 166,
                    label: "READINESS NCO"
                },
                {
                    id: 167,
                    label: "REC & RET AREA NCOIC"
                },
                {
                    id: 168,
                    label: "REC & RET MANAGER"
                },
                {
                    id: 169,
                    label: "REC & RET NCO"
                },
                {
                    id: 170,
                    label: "REC & RET SGM"
                },
                {
                    id: 171,
                    label: "REC & RET SPEC"
                },
                {
                    id: 172,
                    label: "REG ADMINISTRATIVE OFFICER"
                },
                {
                    id: 173,
                    label: "S1"
                },
                {
                    id: 174,
                    label: "S1/OIC"
                },
                {
                    id: 175,
                    label: "S2"
                },
                {
                    id: 176,
                    label: "S3/XO"
                },
                {
                    id: 177,
                    label: "S4"
                },
                {
                    id: 178,
                    label: "SATCOM OPERATIONS NCO"
                },
                {
                    id: 179,
                    label: "SECTION CHIEF"
                },
                {
                    id: 180,
                    label: "SENIOR AVIATION OPERATIONS SGT"
                },
                {
                    id: 181,
                    label: "SENIOR DATA SYSTEMS INTEGRATOR"
                },
                {
                    id: 182,
                    label: "SENIOR FW PILOT"
                },
                {
                    id: 183,
                    label: "SENIOR HUMAN RESOURCES SERGEANT"
                },
                {
                    id: 184,
                    label: "SENIOR INFORMATION SYSTEMS SP"
                },
                {
                    id: 185,
                    label: "SENIOR INSTRUCTOR"
                },
                {
                    id: 186,
                    label: "SENIOR INSTRUCTOR PILOT UAV/UAS"
                },
                {
                    id: 187,
                    label: "SENIOR INTELLIGENCE SGT"
                },
                {
                    id: 188,
                    label: "SENIOR LOGISTICS NCO"
                },
                {
                    id: 189,
                    label: "SENIOR LOGISTICS OPS NCO"
                },
                {
                    id: 190,
                    label: "SENIOR MEDICAL PERSONNEL SGT"
                },
                {
                    id: 191,
                    label: "SENIOR NETWORK OPS NCO"
                },
                {
                    id: 192,
                    label: "SENIOR OPERATIONS NCO"
                },
                {
                    id: 193,
                    label: "SENIOR PARALEGAL NCO"
                },
                {
                    id: 194,
                    label: "SENIOR PERSONNEL NCO"
                },
                {
                    id: 195,
                    label: "SENIOR PERSONNEL SVC SGT"
                },
                {
                    id: 196,
                    label: "SENIOR SIGNAL SPT NCO"
                },
                {
                    id: 197,
                    label: "SENIOR SUPPLY NCO"
                },
                {
                    id: 198,
                    label: "SENIOR SUPPLY SGT"
                },
                {
                    id: 199,
                    label: "SENIOR SUPPLY SGT"
                },
                {
                    id: 200,
                    label: "SENIOR XMSN SYSTEM OPR-MNT"
                },
                {
                    id: 201,
                    label: "SIB CHIEF"
                },
                {
                    id: 202,
                    label: "SIB SUPERVISOR"
                },
                {
                    id: 203,
                    label: "SIGNAL SPT STAFF NCO"
                },
                {
                    id: 204,
                    label: "SIGNAL SPT SYSTEMS CHIEF"
                },
                {
                    id: 205,
                    label: "SIGNAL SUPPORT NCO"
                },
                {
                    id: 206,
                    label: "SIGNAL SUPPORT TECH"
                },
                {
                    id: 207,
                    label: "SIGNAL SYSTEMS TECH"
                },
                {
                    id: 208,
                    label: "SPT NCO"
                },
                {
                    id: 209,
                    label: "SSO"
                },
                {
                    id: 210,
                    label: "STAFF NCO"
                },
                {
                    id: 211,
                    label: "STARC PBO"
                },
                {
                    id: 212,
                    label: "STATE IDT/AMMO MANAGER"
                },
                {
                    id: 213,
                    label: "STATE SCHOOLS MANAGER"
                },
                {
                    id: 214,
                    label: "STRATEGIC PLANS OFFICER"
                },
                {
                    id: 215,
                    label: "SUPERVISOR / MILITARY PERSONNEL MGMT"
                },
                {
                    id: 216,
                    label: "SUPERVISORY AIRCRAFT FLIGHT INSTRUCTOR"
                },
                {
                    id: 217,
                    label: "SUPERVISORY MILITARY PERSONNEL OFFICER"
                },
                {
                    id: 218,
                    label: "SUPPLY ACCOUNT NCO"
                },
                {
                    id: 219,
                    label: "SUPPLY NCO"
                },
                {
                    id: 220,
                    label: "SUPPLY SGT"
                },
                {
                    id: 221,
                    label: "SUPPLY SPECIALIST"
                },
                {
                    id: 222,
                    label: "SUPPLY SYSTEM TECH"
                },
                {
                    id: 223,
                    label: "SUPPLY TECHNICIAN - AMMO SPECIALIST"
                },
                {
                    id: 224,
                    label: "TEAM CHIEF"
                },
                {
                    id: 225,
                    label: "TEAM CHIEF PROPERTY BOOK OFFICER"
                },
                {
                    id: 226,
                    label: "TEAM LEADER"
                },
                {
                    id: 227,
                    label: "TRAINING CENTER MANAGER"
                },
                {
                    id: 228,
                    label: "TRAINING COMMANDANT"
                },
                {
                    id: 229,
                    label: "TRAINING INSTRUCTOR"
                },
                {
                    id: 230,
                    label: "TRAINING NCO"
                },
                {
                    id: 231,
                    label: "TRAINING OFFICER"
                },
                {
                    id: 232,
                    label: "TRAINING OPERATIONS NCO"
                },
                {
                    id: 233,
                    label: "TRAINING QA OPERATIONS SGT"
                },
                {
                    id: 234,
                    label: "TRAINING SR SGL"
                },
                {
                    id: 235,
                    label: "TRANSPORTATION NCO"
                },
                {
                    id: 236,
                    label: "USPFO"
                },
                {
                    id: 237,
                    label: "WARRANT OFFICER INSTRUCTOR"
                },
                {
                    id: 238,
                    label: "WO STRENGTH MANAGER"
                },
                {
                    id: 239,
                    label: "XO"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "772F3A83-64A8-40B9-A3CF-5F62710EB4FA",
        formLabel: "Wage Grade (WG) Position",
        reportLabel: "Wage Grade (WG) Position",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 1,
                    label: "AIRCRAFT ENGINE MECHANIC"
                },
                {
                    id: 2,
                    label: "AIRCRAFT INSPECTOR"
                },
                {
                    id: 3,
                    label: "AIRCRAFT MECHANIC"
                },
                {
                    id: 4,
                    label: "AIRCRAFT MECHANIC SUPERVISOR"
                },
                {
                    id: 5,
                    label: "AIRCRAFT PROPELLER MECHANIC"
                },
                {
                    id: 6,
                    label: "AIRCRAFT SURVIVAL FLIGHT EQUIPMENT REPAIR"
                },
                {
                    id: 7,
                    label: "ALLIED TRADES HELPER"
                },
                {
                    id: 8,
                    label: "ALLIED TRADES SUPERVISOR"
                },
                {
                    id: 9,
                    label: "AMMUNITION & EXPLOSIVES HANDLER"
                },
                {
                    id: 10,
                    label: "AMMUNITION AND EXPLOSIVES HANDLER SUPERVISOR"
                },
                {
                    id: 11,
                    label: "ARMAMENT EQUIPMENT REPAIR INSPECTOR"
                },
                {
                    id: 12,
                    label: "ARTILLERY REPAIRER"
                },
                {
                    id: 13,
                    label: "ELECTRONIC MEASUREMENT EQUIPMENT MECHANIC"
                },
                {
                    id: 14,
                    label: "ELECTRONIC MEASUREMENT EQUIPMENT MECHANIC SUPERVISOR"
                },
                {
                    id: 15,
                    label: "ELECTRONIC MECHANIC"
                },
                {
                    id: 16,
                    label: "ELECTRONIC MECHANIC SUPERVISOR"
                },
                {
                    id: 17,
                    label: "ELECTRONICS EQUIPMENT REPAIR INSPECTOR"
                },
                {
                    id: 18,
                    label: "ELECTRONICS MECHANIC"
                },
                {
                    id: 19,
                    label: "ELECTRONICS MECHANIC LEADER"
                },
                {
                    id: 20,
                    label: "ELECTRONICS MECHANIC SUPERVISOR"
                },
                {
                    id: 21,
                    label: "ELECTRONICS WORKER"
                },
                {
                    id: 22,
                    label: "FABRIC WORKER"
                },
                {
                    id: 23,
                    label: "FUEL DISTRIBUTION SYSTEM WORKER"
                },
                {
                    id: 24,
                    label: "HEAVY MOBILE EQUIPMENT MECHANIC SUPERVISOR"
                },
                {
                    id: 25,
                    label: "HEAVY MOBILE EQUIPMENT REPAIRER"
                },
                {
                    id: 26,
                    label: "MACHINIST"
                },
                {
                    id: 27,
                    label: "MATERIAL HANDLER SUPERVISOR"
                },
                {
                    id: 28,
                    label: "MATERIALS EXAMINER & IDENTIFIER"
                },
                {
                    id: 29,
                    label: "MATERIALS HANDLER"
                },
                {
                    id: 30,
                    label: "MATERIALS HANDLER SUPERVISOR"
                },
                {
                    id: 31,
                    label: "MOBILE EQUIPMENT METAL WORKER"
                },
                {
                    id: 32,
                    label: "MOTOR VEHICLE OPERATOR"
                },
                {
                    id: 33,
                    label: "OPTICAL INSTR RPR"
                },
                {
                    id: 50,
                    label: "OTHER"
                },
                {
                    id: 34,
                    label: "PAINTING WORKER"
                },
                {
                    id: 35,
                    label: "POWERED SUPPORT SYSTEMS MECHANIC"
                },
                {
                    id: 36,
                    label: "SHEET METAL MECHANIC (AIRCRAFT)"
                },
                {
                    id: 37,
                    label: "SMALL ARMS REPAIRER"
                },
                {
                    id: 38,
                    label: "SURFACE MAINTENANCE MECHANIC"
                },
                {
                    id: 39,
                    label: "SURFACE MAINTENANCE MECHANIC INSPECTOR"
                },
                {
                    id: 40,
                    label: "SURFACE MAINTENANCE MECHANIC INSPECTOR SUPERVISOR"
                },
                {
                    id: 41,
                    label: "SURFACE MAINTENANCE MECHANIC LEADER"
                },
                {
                    id: 42,
                    label: "SURFACE MAINTENANCE MECHANIC SUPERVISOR"
                },
                {
                    id: 43,
                    label: "SURFACE MAINTENANCE REPAIRER"
                },
                {
                    id: 44,
                    label: "SURFACE MAINTENANCE REPAIRER SUPERVISOR"
                },
                {
                    id: 45,
                    label: "TARGET SYSTEMS REPAIRER"
                },
                {
                    id: 46,
                    label: "TOOL AND PARTS ATTENDANT"
                },
                {
                    id: 47,
                    label: "TOOL AND PARTS ATTENDANT SUPERVISOR"
                },
                {
                    id: 48,
                    label: "WELDER"
                },
                {
                    id: 49,
                    label: "WOOD WORKER"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "69A1A490-FC90-42E5-B5D8-19D0627DD5E7",
        formLabel: "General Schedule (GS) Position",
        reportLabel: "General Schedule (GS) Position",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 1,
                    label: "ACCOUNTING OFFICER"
                },
                {
                    id: 2,
                    label: "ACCOUNTING TECHNICIAN"
                },
                {
                    id: 3,
                    label: "ADMINISTRATIVE ASSISTANT"
                },
                {
                    id: 4,
                    label: "ADMINISTRATIVE OFFICER"
                },
                {
                    id: 5,
                    label: "AGENCY PROGRAM COORDINATOR"
                },
                {
                    id: 6,
                    label: "AIR FIELD MANAGER"
                },
                {
                    id: 7,
                    label: "AIRCRAFT FLIGHT INSTRUCTOR"
                },
                {
                    id: 8,
                    label: "ASSISTANT INSPECTOR GENERAL"
                },
                {
                    id: 9,
                    label: "ATTORNEY-ADVISOR (GENERAL)"
                },
                {
                    id: 10,
                    label: "AUDITOR"
                },
                {
                    id: 11,
                    label: "BUDGET ANALYST"
                },
                {
                    id: 12,
                    label: "BUDGET CLERK"
                },
                {
                    id: 13,
                    label: "BUDGET OFFICER"
                },
                {
                    id: 14,
                    label: "BUDGET TECHNICIAN"
                },
                {
                    id: 15,
                    label: "CHAPLAIN ASSISTANT"
                },
                {
                    id: 16,
                    label: "CHIEF OF THE JOINT STAFF"
                },
                {
                    id: 17,
                    label: "CIVIL ENGINEER"
                },
                {
                    id: 18,
                    label: "CIVIL ENGINEERING TECHNICIAN"
                },
                {
                    id: 19,
                    label: "COMMUNICATIONS OPERATIONS TECHNICIAN"
                },
                {
                    id: 20,
                    label: "COMMUNITY RELATIONS SPECIALIST"
                },
                {
                    id: 21,
                    label: "COMPUTER ASSISTANT"
                },
                {
                    id: 22,
                    label: "CONTRACT SPECIALIST"
                },
                {
                    id: 23,
                    label: "DEPUTY HUMAN RESOURCES OFFICER"
                },
                {
                    id: 24,
                    label: "DEPUTY LOGISTICS MANAGEMENT OFFICER"
                },
                {
                    id: 25,
                    label: "EDUCATION PROGRAM ADMINISTRATOR"
                },
                {
                    id: 26,
                    label: "EDUCATION TECHNICIAN"
                },
                {
                    id: 27,
                    label: "ENVIRONMENTAL PROTECTION SPECIALIST"
                },
                {
                    id: 28,
                    label: "EQUAL EMPLOYMENT MANAGER"
                },
                {
                    id: 29,
                    label: "EQUAL EMPLOYMENT SPECIALIST"
                },
                {
                    id: 30,
                    label: "EQUIPMENT SPECIALIST"
                },
                {
                    id: 31,
                    label: "FACILITY MANAGEMENT SPECIALIST"
                },
                {
                    id: 32,
                    label: "FACILITY PLANS & PROGRAMS MANAGEMENT SPECIALIST"
                },
                {
                    id: 33,
                    label: "FAMILY PROGRAM ASSISTANT"
                },
                {
                    id: 34,
                    label: "FAMILY PROGRAM SPECIALIST"
                },
                {
                    id: 35,
                    label: "FINANCIAL MANAGER"
                },
                {
                    id: 36,
                    label: "FINANCIAL TECHNICIAN"
                },
                {
                    id: 37,
                    label: "FIREFIGHTER"
                },
                {
                    id: 38,
                    label: "FISCAL SYSTEMS ANALYST"
                },
                {
                    id: 39,
                    label: "FLIGHT ENGINEER (INSTRUCTOR)"
                },
                {
                    id: 40,
                    label: "FLIGHT OPERATIONS SPECIALIST"
                },
                {
                    id: 41,
                    label: "FOOD SERVICE PROGRAM SPECIALIST"
                },
                {
                    id: 42,
                    label: "FORCE INTEGRATION READINESS ANALYST"
                },
                {
                    id: 43,
                    label: "HEALTH SYSTEMS SPECIALIST"
                },
                {
                    id: 44,
                    label: "HELICOPTER FLIGHT INSTRUCTOR"
                },
                {
                    id: 45,
                    label: "HOUSING MANAGEMENT ASSISTANT"
                },
                {
                    id: 46,
                    label: "HOUSING OFFICER"
                },
                {
                    id: 47,
                    label: "HUMAN RESOURCES ASSISTANT"
                },
                {
                    id: 48,
                    label: "HUMAN RESOURCES ASSISTANT (MILITARY"
                },
                {
                    id: 49,
                    label: "HUMAN RESOURCES ASSISTANT (MILITARY)"
                },
                {
                    id: 50,
                    label: "HUMAN RESOURCES ASSISTANT (OA)"
                },
                {
                    id: 51,
                    label: "HUMAN RESOURCES ASSISTANT (READINESS TECHNICIAN)"
                },
                {
                    id: 52,
                    label: "HUMAN RESOURCES SPECIALIST"
                },
                {
                    id: 53,
                    label: "INDUSTRIAL HYGIENE TECHNICIAN"
                },
                {
                    id: 54,
                    label: "INFORMATION TECHNOLOGY SPECIALIST"
                },
                {
                    id: 55,
                    label: "INTERNATIONAL PARTNERSHIP SPECIALIST"
                },
                {
                    id: 56,
                    label: "LEAD ACCOUNTING TECHNICIAN"
                },
                {
                    id: 57,
                    label: "LEAD HUMAN RESOURCES ASSISTANT (MILITARY)"
                },
                {
                    id: 58,
                    label: "LEAD MILITARY PAY TECHNICIAN"
                },
                {
                    id: 59,
                    label: "LEGAL ASSISTANT (OA)"
                },
                {
                    id: 60,
                    label: "LOGISTICS MANAGEMENT OFFICER"
                },
                {
                    id: 61,
                    label: "LOGISTICS MANAGEMENT SPECIALIST"
                },
                {
                    id: 62,
                    label: "MAIL ASSISTANT"
                },
                {
                    id: 63,
                    label: "MAINTENANCE SPECIALIST"
                },
                {
                    id: 64,
                    label: "MAINTENANCE TEST PILOT"
                },
                {
                    id: 65,
                    label: "MANAGEMENT & PROGRAM ANALYST"
                },
                {
                    id: 66,
                    label: "MANAGEMENT ANALYST"
                },
                {
                    id: 67,
                    label: "MANAGEMENT ASSISTANT"
                },
                {
                    id: 68,
                    label: "MILITARY PAY TECHNICIAN"
                },
                {
                    id: 69,
                    label: "MILITARY PERSONNEL CLERK"
                },
                {
                    id: 70,
                    label: "MILITARY PERSONNEL OFFICER"
                },
                {
                    id: 71,
                    label: "MILITARY PERSONNEL TECHNICIAN"
                },
                {
                    id: 72,
                    label: "MOBILIZATION PLANS SPECIALIST"
                },
                {
                    id: 73,
                    label: "MOBILIZATION READINESS OFFICER"
                },
                {
                    id: 74,
                    label: "OCCUPATIONAL HEALTH NURSE"
                },
                {
                    id: 75,
                    label: "OCCUPATIONAL HEALTH TECHNICIAN"
                },
                {
                    id: 76,
                    label: "OFFICE AUTOMATION CLERK"
                },
                {
                    id: 77,
                    label: "OPERATIONS & TRAINING SPECIALIST"
                },
                {
                    id: 128,
                    label: "OTHER"
                },
                {
                    id: 78,
                    label: "PERSONNEL OFFICER"
                },
                {
                    id: 79,
                    label: "PLANS & OPERATIONS SPECIALIST"
                },
                {
                    id: 80,
                    label: "PLANS, TRAINING, AND MOBILIZATION MANAGER"
                },
                {
                    id: 81,
                    label: "PROCUREMENT TECHNICIAN"
                },
                {
                    id: 82,
                    label: "PRODUCTION CONTROLLER"
                },
                {
                    id: 83,
                    label: "PROGRAM ANALYST"
                },
                {
                    id: 84,
                    label: "PUBLIC AFFAIRS ASSISTANT"
                },
                {
                    id: 85,
                    label: "PUBLIC AFFAIRS SPECIALIST"
                },
                {
                    id: 86,
                    label: "READINESS TECHNICIAN"
                },
                {
                    id: 87,
                    label: "SAFETY & OCCUPATIONAL HEALTH MANAGER"
                },
                {
                    id: 88,
                    label: "SAFETY SPECIALIST"
                },
                {
                    id: 89,
                    label: "SAFETY TECHNICIAN"
                },
                {
                    id: 90,
                    label: "SECRETARY"
                },
                {
                    id: 91,
                    label: "SECURITY ASSISTANT"
                },
                {
                    id: 92,
                    label: "SECURITY OFFICER"
                },
                {
                    id: 93,
                    label: "SECURITY SPECIALIST"
                },
                {
                    id: 94,
                    label: "SPECIAL SECURITY OFFICER"
                },
                {
                    id: 95,
                    label: "STAFF ACCOUNTANT"
                },
                {
                    id: 96,
                    label: "STATE BENEFITS ADVISOR"
                },
                {
                    id: 97,
                    label: "STATE CONSTRUCTION & FACILITIES MANAGEMENT OFFICER"
                },
                {
                    id: 98,
                    label: "SUPERVISORY AIRCRAFT PILOT"
                },
                {
                    id: 99,
                    label: "SUPERVISORY AUDITOR"
                },
                {
                    id: 100,
                    label: "SUPERVISORY CONTRACT SPECIALIST"
                },
                {
                    id: 101,
                    label: "SUPERVISORY EQUIPMENT SPECIALIST"
                },
                {
                    id: 102,
                    label: "SUPERVISORY FACILITY MANAGEMENT SPECIALIST"
                },
                {
                    id: 103,
                    label: "SUPERVISORY FINANCIAL TECHNICIAN"
                },
                {
                    id: 104,
                    label: "SUPERVISORY HUMAN RESOURCES SPECIALIST"
                },
                {
                    id: 105,
                    label: "SUPERVISORY INFORMATION TECHNOLOGY SPECIALIST"
                },
                {
                    id: 106,
                    label: "SUPERVISORY LOGISTICS MANAGEMENT SPECIALIST"
                },
                {
                    id: 107,
                    label: "SUPERVISORY MAINTENANCE TEST PILOT"
                },
                {
                    id: 108,
                    label: "SUPERVISORY PROGRAM ANALYST"
                },
                {
                    id: 109,
                    label: "SUPERVISORY SUPPLY SYSTEMS ANALYST"
                },
                {
                    id: 110,
                    label: "SUPERVISORY SUPPLY TECHNICIAN"
                },
                {
                    id: 111,
                    label: "SUPERVISORY SURFACE MAINTENANCE SPECIALIST"
                },
                {
                    id: 112,
                    label: "SUPPLY MANAGEMENT OFFICER"
                },
                {
                    id: 113,
                    label: "SUPPLY MANAGEMENT SPECIALIST"
                },
                {
                    id: 114,
                    label: "SUPPLY SYSTEMS ANALYST"
                },
                {
                    id: 115,
                    label: "SUPPLY TECHNICIAN"
                },
                {
                    id: 116,
                    label: "SUPPORT SERVICES SPECIALIST"
                },
                {
                    id: 117,
                    label: "SUPPORT SERVICES SUPERVISOR"
                },
                {
                    id: 118,
                    label: "SURFACE MAINTENANCE MANAGER"
                },
                {
                    id: 119,
                    label: "TELECOMMUNICATIONS SPECIALIST"
                },
                {
                    id: 120,
                    label: "TRAFFIC MANAGEMENT SPECIALIST"
                },
                {
                    id: 121,
                    label: "TRAFFIC MANAGER"
                },
                {
                    id: 122,
                    label: "TRAINING ADMINISTRATOR"
                },
                {
                    id: 123,
                    label: "TRAINING SPECIALIST"
                },
                {
                    id: 124,
                    label: "TRAINING TECHNICIAN"
                },
                {
                    id: 125,
                    label: "TRANSPORTATION ASSISTANT"
                },
                {
                    id: 126,
                    label: "VISUAL INFORMATION SPECIALIST"
                },
                {
                    id: 127,
                    label: "VOUCHER EXAMINER"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "794C726B-94E1-4AE5-9969-1F919D89386A",
        formLabel: "Position",
        reportLabel: "AGR Position",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 1,
                    label: "AASF COMMANDER"
                },
                {
                    id: 2,
                    label: "ADMINISTRATIVE ASSISTANT"
                },
                {
                    id: 3,
                    label: "ADMINISTRATIVE NCO"
                },
                {
                    id: 4,
                    label: "ADMINISTRATIVE NCO-LOGISTICS"
                },
                {
                    id: 5,
                    label: "ADMINISTRATIVE NCO-OPERATIONS"
                },
                {
                    id: 6,
                    label: "ADMINISTRATIVE OFFICER (PCA)"
                },
                {
                    id: 7,
                    label: "ADMINISTRATIVE SGT-OPERATIONS"
                },
                {
                    id: 8,
                    label: "ADMINISTRATIVE/SUPPLY NCO"
                },
                {
                    id: 9,
                    label: "AIDE DE CAMP"
                },
                {
                    id: 10,
                    label: "AIRCRAFT FLIGHT INSTRUCTOR (SAFETY)"
                },
                {
                    id: 11,
                    label: "AMEDD STR MANAGER"
                },
                {
                    id: 12,
                    label: "ANTI-TERRORISM PLANS"
                },
                {
                    id: 13,
                    label: "AREA NCO"
                },
                {
                    id: 14,
                    label: "ARMORER"
                },
                {
                    id: 15,
                    label: "ASSISTANT AVIATION OPERATIONS SGT"
                },
                {
                    id: 16,
                    label: "ASSISTANT COMSEC CUSTODIAN"
                },
                {
                    id: 17,
                    label: "ASSISTANT OPERATIONS NCO"
                },
                {
                    id: 18,
                    label: "ASSISTANT OPERATIONS OFFICER"
                },
                {
                    id: 19,
                    label: "ASSISTANT OPERATIONS SGT"
                },
                {
                    id: 20,
                    label: "ASSISTANT PROFESSOR MILITARY SCIENCE"
                },
                {
                    id: 21,
                    label: "ASSISTANT S3"
                },
                {
                    id: 22,
                    label: "ASSISTANT SUPPLY SGT"
                },
                {
                    id: 23,
                    label: "ASSISTANT TRAINING INSTRUCTOR"
                },
                {
                    id: 24,
                    label: "ASSISTANT TRAINING NCO"
                },
                {
                    id: 25,
                    label: "ASSISTANT TRAINING OFFICER"
                },
                {
                    id: 26,
                    label: "AUTOMATION NCO"
                },
                {
                    id: 27,
                    label: "AVIATION OPERATIONS CHIEF"
                },
                {
                    id: 28,
                    label: "AVIATION SAFETY OFFICER"
                },
                {
                    id: 29,
                    label: "BAND COMMANDANT"
                },
                {
                    id: 30,
                    label: "BN SIGNAL NCO"
                },
                {
                    id: 31,
                    label: "BUDGET MANAGER"
                },
                {
                    id: 32,
                    label: "CHIEF FIRE CONTROL SGT"
                },
                {
                    id: 33,
                    label: "CHIEF HUMAN RESOURCES SGT"
                },
                {
                    id: 34,
                    label: "CHIEF INSTRUCTOR"
                },
                {
                    id: 35,
                    label: "CHIEF MANAGEMENT ASSEST SUPERVISOR"
                },
                {
                    id: 36,
                    label: "CHIEF MILPO"
                },
                {
                    id: 37,
                    label: "CHIEF OF PLANS"
                },
                {
                    id: 38,
                    label: "CHIEF OF STAFF"
                },
                {
                    id: 39,
                    label: "CHIEF OPERATIONS SGT"
                },
                {
                    id: 40,
                    label: "CHIEF PARALEGAL NCO"
                },
                {
                    id: 41,
                    label: "CHIEF PERSONNEL SVC MANAGER"
                },
                {
                    id: 42,
                    label: "COMMAND ADMINISTRATIVE OFFICER"
                },
                {
                    id: 43,
                    label: "COMMAND PROGRAM SUPPORT SPECIALIST"
                },
                {
                    id: 44,
                    label: "COMMAND SERGEANT MAJOR"
                },
                {
                    id: 45,
                    label: "COMMAND TEAM CHIEF"
                },
                {
                    id: 46,
                    label: "COMMAND WARRANT OFFICER"
                },
                {
                    id: 47,
                    label: "COMMANDANT"
                },
                {
                    id: 48,
                    label: "COMMANDER"
                },
                {
                    id: 49,
                    label: "COMSEC MATERIAL MANAGER"
                },
                {
                    id: 50,
                    label: "COUNTER DRUG COOR"
                },
                {
                    id: 51,
                    label: "CRYPTO LINQUIST"
                },
                {
                    id: 52,
                    label: "DATA SYSTEMS INTEGRATION"
                },
                {
                    id: 53,
                    label: "DEPUTY CDR"
                },
                {
                    id: 54,
                    label: "DEPUTY COMMANDER"
                },
                {
                    id: 55,
                    label: "DEPUTY G1"
                },
                {
                    id: 56,
                    label: "DEPUTY G2"
                },
                {
                    id: 57,
                    label: "DEPUTY SJA"
                },
                {
                    id: 58,
                    label: "DEPUTY TRAINING SITE MANAGER"
                },
                {
                    id: 59,
                    label: "DET NCO"
                },
                {
                    id: 60,
                    label: "DET READINESS NCO"
                },
                {
                    id: 61,
                    label: "EDUCATION NCO"
                },
                {
                    id: 62,
                    label: "EDUCATION OFFICER"
                },
                {
                    id: 63,
                    label: "EFFECTS OFFICER"
                },
                {
                    id: 64,
                    label: "EXECUTIVE ADMINISTRATIVE ASSISTANT"
                },
                {
                    id: 65,
                    label: "FF SECT LDR"
                },
                {
                    id: 66,
                    label: "FIRE CONTROL SGT"
                },
                {
                    id: 67,
                    label: "FIRST SERGEANT/SENIOR OPERATIONS NCO"
                },
                {
                    id: 68,
                    label: "FOOD SERVICE NCO"
                },
                {
                    id: 69,
                    label: "FORCE DEV NCO"
                },
                {
                    id: 70,
                    label: "FORCE INTEGRATION OFFICER"
                },
                {
                    id: 71,
                    label: "FORCE INTEGRATION READINESS OFFICER"
                },
                {
                    id: 72,
                    label: "FORWARD SIGNAL SPT NCO"
                },
                {
                    id: 73,
                    label: "FW PILOT"
                },
                {
                    id: 74,
                    label: "FWD SIGNAL SPT NCO"
                },
                {
                    id: 75,
                    label: "HEALTH CARE NCO"
                },
                {
                    id: 76,
                    label: "HEALTH CARE SGT"
                },
                {
                    id: 77,
                    label: "HEALTH CARE SPECIALIST"
                },
                {
                    id: 78,
                    label: "HUMAN RESOURCE NCO"
                },
                {
                    id: 79,
                    label: "HUMAN RESOURCES MGMT OFFICER"
                },
                {
                    id: 80,
                    label: "HUMAN RESOURCES OFFICER"
                },
                {
                    id: 81,
                    label: "HUMAN RESOURCES SGT"
                },
                {
                    id: 82,
                    label: "INFORMATION SYSTEMS ANALYST"
                },
                {
                    id: 83,
                    label: "INFORMATION SYSTEMS SPECIALIST"
                },
                {
                    id: 84,
                    label: "INFORMATION SYSTEMS TEAM CHIEF"
                },
                {
                    id: 85,
                    label: "INFORMATION SYSTEMS TECH"
                },
                {
                    id: 86,
                    label: "INSPECTOR GENERAL"
                },
                {
                    id: 87,
                    label: "INSPECTOR GENERAL NCO"
                },
                {
                    id: 88,
                    label: "INSTRUCTOR"
                },
                {
                    id: 89,
                    label: "INTELLIGENCE ANALYST"
                },
                {
                    id: 90,
                    label: "INTELLIGENCE SGT"
                },
                {
                    id: 91,
                    label: "INTERSTATE COORDINATOR"
                },
                {
                    id: 92,
                    label: "J1/HRO"
                },
                {
                    id: 93,
                    label: "JAG OFFICER"
                },
                {
                    id: 94,
                    label: "LOGISTICS NCO"
                },
                {
                    id: 95,
                    label: "LOGISTICS OFFICER"
                },
                {
                    id: 96,
                    label: "LOGISTICS PLANNER"
                },
                {
                    id: 97,
                    label: "MAINTENANCE MANAGEMENT NCO"
                },
                {
                    id: 98,
                    label: "MARKETING NCO"
                },
                {
                    id: 99,
                    label: "MASTER GUNNER"
                },
                {
                    id: 100,
                    label: "MATERIAL CNTL/ACCTG SPC"
                },
                {
                    id: 101,
                    label: "MATERIAL CNTL/HDLG SPC"
                },
                {
                    id: 102,
                    label: "MATERIAL CONTROL / ACCOUNTING NCO"
                },
                {
                    id: 103,
                    label: "MATERIAL MANAGEMENT NCO"
                },
                {
                    id: 104,
                    label: "MATERIAL STORAGE & HANDLING NCO"
                },
                {
                    id: 105,
                    label: "MATERIAL STORAGE HANDLING NCO"
                },
                {
                    id: 106,
                    label: "MATERIAL STORE/HDLG SGT"
                },
                {
                    id: 107,
                    label: "MATERIALS MANAGEMENT SUPERVISOR"
                },
                {
                    id: 108,
                    label: "MEDICAL LOGISTICS SGT"
                },
                {
                    id: 109,
                    label: "MEDICAL OPERATIONS OFFICER"
                },
                {
                    id: 110,
                    label: "MEDICAL SUPPLY NCO"
                },
                {
                    id: 111,
                    label: "MEDICAL SUPPLY SERGEANT"
                },
                {
                    id: 112,
                    label: "MEPS GC"
                },
                {
                    id: 113,
                    label: "MIL PERSONNEL PAY CLERK/TYPIST/TECH"
                },
                {
                    id: 114,
                    label: "MOB NCOIC"
                },
                {
                    id: 115,
                    label: "MOB PLANS OFF"
                },
                {
                    id: 116,
                    label: "MOB RDNS OFF"
                },
                {
                    id: 117,
                    label: "MOBILITY NCO"
                },
                {
                    id: 118,
                    label: "MOBILITY OFFICER"
                },
                {
                    id: 119,
                    label: "NBC NCO"
                },
                {
                    id: 120,
                    label: "NBC RECON NCO"
                },
                {
                    id: 121,
                    label: "NBC STAFF NCO"
                },
                {
                    id: 122,
                    label: "NBC TEAM CHIEF"
                },
                {
                    id: 123,
                    label: "NETWORK MGMT TECHNICIAN"
                },
                {
                    id: 124,
                    label: "NETWORKING OFFICER"
                },
                {
                    id: 125,
                    label: "NUC MED SCI OFF"
                },
                {
                    id: 126,
                    label: "OCS RDNS NCO"
                },
                {
                    id: 127,
                    label: "OFF STRENGTH MANAGER"
                },
                {
                    id: 128,
                    label: "OPERATIONS & TRAINING OFF"
                },
                {
                    id: 129,
                    label: "OPERATIONS NCO"
                },
                {
                    id: 130,
                    label: "OPERATIONS NCO"
                },
                {
                    id: 131,
                    label: "OPERATIONS NCO MODELING"
                },
                {
                    id: 132,
                    label: "OPERATIONS OFFICER"
                },
                {
                    id: 133,
                    label: "OPERATIONS OFFICER-CERFP"
                },
                {
                    id: 134,
                    label: "OPERATIONS SGM"
                },
                {
                    id: 135,
                    label: "OPERATIONS SGT"
                },
                {
                    id: 137,
                    label: "OPERATIONS SGT-CERFP"
                },
                {
                    id: 136,
                    label: "OPERATIONS SGT/MASTER GUNNER"
                },
                {
                    id: 138,
                    label: "OPERATIONS/SIMS OFFICER"
                },
                {
                    id: 139,
                    label: "OPS LAW NCO"
                },
                {
                    id: 240,
                    label: "OTHER"
                },
                {
                    id: 140,
                    label: "PARALEGAL NCO"
                },
                {
                    id: 141,
                    label: "PAT/PBO"
                },
                {
                    id: 142,
                    label: "PERSONNEL ADMININSTRATION SERGEANT"
                },
                {
                    id: 143,
                    label: "PERSONNEL ADMINISTRATION TECH"
                },
                {
                    id: 144,
                    label: "PERSONNEL INFORMATION SPECIALIST"
                },
                {
                    id: 145,
                    label: "PERSONNEL INFORMATION SYSTEM SUPERVISOR"
                },
                {
                    id: 146,
                    label: "PERSONNEL NCO"
                },
                {
                    id: 147,
                    label: "PERSONNEL NCO"
                },
                {
                    id: 148,
                    label: "PERSONNEL RCDS SGT"
                },
                {
                    id: 149,
                    label: "PERSONNEL SERGEANT"
                },
                {
                    id: 150,
                    label: "PERSONNEL SERVICES SERGEANT"
                },
                {
                    id: 151,
                    label: "PHYSICIANS ASSISTANT"
                },
                {
                    id: 152,
                    label: "PLANS & OPERATIONS OFFICER"
                },
                {
                    id: 153,
                    label: "PLANS & OPS ASSISTANT"
                },
                {
                    id: 154,
                    label: "PLANS & OPS NCO"
                },
                {
                    id: 155,
                    label: "PLANS OFFICER"
                },
                {
                    id: 156,
                    label: "PLANS OPERATIONS & TRAINING OFF"
                },
                {
                    id: 157,
                    label: "PO & MS OFFICER"
                },
                {
                    id: 158,
                    label: "PROPERTY BOOK NCO"
                },
                {
                    id: 159,
                    label: "PROPERTY BOOK OFFICER"
                },
                {
                    id: 160,
                    label: "PROPERTY BOOK TEAM CHIEF"
                },
                {
                    id: 161,
                    label: "QM & CHEM EQUIP REPAIRER"
                },
                {
                    id: 162,
                    label: "RANGE OFFICER"
                },
                {
                    id: 163,
                    label: "RANGE OPERATIONS NCO"
                },
                {
                    id: 164,
                    label: "RANGE SAFETY NCO"
                },
                {
                    id: 165,
                    label: "RANGE SCHEDULE NCO"
                },
                {
                    id: 166,
                    label: "READINESS NCO"
                },
                {
                    id: 167,
                    label: "REC & RET AREA NCOIC"
                },
                {
                    id: 168,
                    label: "REC & RET MANAGER"
                },
                {
                    id: 169,
                    label: "REC & RET NCO"
                },
                {
                    id: 170,
                    label: "REC & RET SGM"
                },
                {
                    id: 171,
                    label: "REC & RET SPEC"
                },
                {
                    id: 172,
                    label: "REG ADMINISTRATIVE OFFICER"
                },
                {
                    id: 173,
                    label: "S1"
                },
                {
                    id: 174,
                    label: "S1/OIC"
                },
                {
                    id: 175,
                    label: "S2"
                },
                {
                    id: 176,
                    label: "S3/XO"
                },
                {
                    id: 177,
                    label: "S4"
                },
                {
                    id: 178,
                    label: "SATCOM OPERATIONS NCO"
                },
                {
                    id: 179,
                    label: "SECTION CHIEF"
                },
                {
                    id: 180,
                    label: "SENIOR AVIATION OPERATIONS SGT"
                },
                {
                    id: 181,
                    label: "SENIOR DATA SYSTEMS INTEGRATOR"
                },
                {
                    id: 182,
                    label: "SENIOR FW PILOT"
                },
                {
                    id: 183,
                    label: "SENIOR HUMAN RESOURCES SERGEANT"
                },
                {
                    id: 184,
                    label: "SENIOR INFORMATION SYSTEMS SP"
                },
                {
                    id: 185,
                    label: "SENIOR INSTRUCTOR"
                },
                {
                    id: 186,
                    label: "SENIOR INSTRUCTOR PILOT UAV/UAS"
                },
                {
                    id: 187,
                    label: "SENIOR INTELLIGENCE SGT"
                },
                {
                    id: 188,
                    label: "SENIOR LOGISTICS NCO"
                },
                {
                    id: 189,
                    label: "SENIOR LOGISTICS OPS NCO"
                },
                {
                    id: 190,
                    label: "SENIOR MEDICAL PERSONNEL SGT"
                },
                {
                    id: 191,
                    label: "SENIOR NETWORK OPS NCO"
                },
                {
                    id: 192,
                    label: "SENIOR OPERATIONS NCO"
                },
                {
                    id: 193,
                    label: "SENIOR PARALEGAL NCO"
                },
                {
                    id: 194,
                    label: "SENIOR PERSONNEL NCO"
                },
                {
                    id: 195,
                    label: "SENIOR PERSONNEL SVC SGT"
                },
                {
                    id: 196,
                    label: "SENIOR SIGNAL SPT NCO"
                },
                {
                    id: 197,
                    label: "SENIOR SUPPLY NCO"
                },
                {
                    id: 198,
                    label: "SENIOR SUPPLY SGT"
                },
                {
                    id: 199,
                    label: "SENIOR SUPPLY SGT"
                },
                {
                    id: 200,
                    label: "SENIOR XMSN SYSTEM OPR-MNT"
                },
                {
                    id: 201,
                    label: "SIB CHIEF"
                },
                {
                    id: 202,
                    label: "SIB SUPERVISOR"
                },
                {
                    id: 203,
                    label: "SIGNAL SPT STAFF NCO"
                },
                {
                    id: 204,
                    label: "SIGNAL SPT SYSTEMS CHIEF"
                },
                {
                    id: 205,
                    label: "SIGNAL SUPPORT NCO"
                },
                {
                    id: 206,
                    label: "SIGNAL SUPPORT TECH"
                },
                {
                    id: 207,
                    label: "SIGNAL SYSTEMS TECH"
                },
                {
                    id: 208,
                    label: "SPT NCO"
                },
                {
                    id: 209,
                    label: "SSO"
                },
                {
                    id: 210,
                    label: "STAFF NCO"
                },
                {
                    id: 211,
                    label: "STARC PBO"
                },
                {
                    id: 212,
                    label: "STATE IDT/AMMO MANAGER"
                },
                {
                    id: 213,
                    label: "STATE SCHOOLS MANAGER"
                },
                {
                    id: 214,
                    label: "STRATEGIC PLANS OFFICER"
                },
                {
                    id: 215,
                    label: "SUPERVISOR / MILITARY PERSONNEL MGMT"
                },
                {
                    id: 216,
                    label: "SUPERVISORY AIRCRAFT FLIGHT INSTRUCTOR"
                },
                {
                    id: 217,
                    label: "SUPERVISORY MILITARY PERSONNEL OFFICER"
                },
                {
                    id: 218,
                    label: "SUPPLY ACCOUNT NCO"
                },
                {
                    id: 219,
                    label: "SUPPLY NCO"
                },
                {
                    id: 220,
                    label: "SUPPLY SGT"
                },
                {
                    id: 221,
                    label: "SUPPLY SPECIALIST"
                },
                {
                    id: 222,
                    label: "SUPPLY SYSTEM TECH"
                },
                {
                    id: 223,
                    label: "SUPPLY TECHNICIAN - AMMO SPECIALIST"
                },
                {
                    id: 224,
                    label: "TEAM CHIEF"
                },
                {
                    id: 225,
                    label: "TEAM CHIEF PROPERTY BOOK OFFICER"
                },
                {
                    id: 226,
                    label: "TEAM LEADER"
                },
                {
                    id: 227,
                    label: "TRAINING CENTER MANAGER"
                },
                {
                    id: 228,
                    label: "TRAINING COMMANDANT"
                },
                {
                    id: 229,
                    label: "TRAINING INSTRUCTOR"
                },
                {
                    id: 230,
                    label: "TRAINING NCO"
                },
                {
                    id: 231,
                    label: "TRAINING OFFICER"
                },
                {
                    id: 232,
                    label: "TRAINING OPERATIONS NCO"
                },
                {
                    id: 233,
                    label: "TRAINING QA OPERATIONS SGT"
                },
                {
                    id: 234,
                    label: "TRAINING SR SGL"
                },
                {
                    id: 235,
                    label: "TRANSPORTATION NCO"
                },
                {
                    id: 236,
                    label: "USPFO"
                },
                {
                    id: 237,
                    label: "WARRANT OFFICER INSTRUCTOR"
                },
                {
                    id: 238,
                    label: "WO STRENGTH MANAGER"
                },
                {
                    id: 239,
                    label: "XO"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "5EB73E3A-DDD3-40B1-966F-040255A7773E",
        formLabel: "MOS Type",
        reportLabel: "MOS Type",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 1,
                    label: "Enlisted"
                },
                {
                    id: 3,
                    label: "Officer"
                },
                {
                    id: 2,
                    label: "Warrant"
                }
            ]
        }
    },
    {
        uid: "1C46A95C-1463-49C0-BB05-C885C3CC3FB5",
        formLabel: "Primary MOS (Enlisted)",
        reportLabel: "Primary MOS (Enlisted)",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 2,
                    label: "00F - MOS IMMATERIAL NATIONAL GUARD BUREAU (NGB)"
                },
                {
                    id: 3,
                    label: "00G - MOS IMMATERIAL U.S. ARMY RESERVE (USAR)"
                },
                {
                    id: 4,
                    label: "00S - SPECIAL DUTY ASSIGNMENT AFSC"
                },
                {
                    id: 5,
                    label: "00Z - CMD SGT MAJOR"
                },
                {
                    id: 6,
                    label: "09B - TRAINEE UNASSIGNED"
                },
                {
                    id: 7,
                    label: "09C - TRAINEE LANGUAGE"
                },
                {
                    id: 8,
                    label: "09D - COLLEGE TRAINEE"
                },
                {
                    id: 9,
                    label: "09G - NATIONAL GUARD (NG) ON ACTIVE DUTY MEDICAL HOLD"
                },
                {
                    id: 10,
                    label: "09H - U.S. ARMY RESERVE (USAR) ON ACTIVE DUTY MEDICAL HOLD"
                },
                {
                    id: 11,
                    label: "09J - GED COMPLETION PROGRAM"
                },
                {
                    id: 12,
                    label: "09L - INTERPRETER/TRANSLATOR"
                },
                {
                    id: 13,
                    label: "09M - MARCH 2 SUCCESS"
                },
                {
                    id: 14,
                    label: "09N - NURSE CORPS CANDIDATE"
                },
                {
                    id: 15,
                    label: "09R - SIMULTANEOUS MBR PROG"
                },
                {
                    id: 16,
                    label: "09S - COMM OFF CANDIDATE"
                },
                {
                    id: 17,
                    label: "09T - COLLEGE STUDENT ARMY NATIONAL GUARD OFFICER PROGRAM"
                },
                {
                    id: 18,
                    label: "09W - WO CANDIDATE"
                },
                {
                    id: 19,
                    label: "11B - INFANTRYMAN"
                },
                {
                    id: 20,
                    label: "11C - INDIRECT FIRE INFANTRYMAN"
                },
                {
                    id: 21,
                    label: "11X - INFANTRY RECRUIT"
                },
                {
                    id: 22,
                    label: "11Z - INFANTRY SENIOR SERGEANT"
                },
                {
                    id: 23,
                    label: "12B - COMBAT ENGINEER"
                },
                {
                    id: 24,
                    label: "12C - BRIDGE CREWMEMBER"
                },
                {
                    id: 25,
                    label: "12D - DIVER"
                },
                {
                    id: 26,
                    label: "12G - QUARRYING SPECIALIST (RC)"
                },
                {
                    id: 27,
                    label: "12H - CONSTRUCTION ENGINEERING SUPERVISOR"
                },
                {
                    id: 28,
                    label: "12K - PLUMBER"
                },
                {
                    id: 29,
                    label: "12M - FIREFIGHTER"
                },
                {
                    id: 30,
                    label: "12N - HORIZONTAL CONSTRUCTION ENGINEER"
                },
                {
                    id: 31,
                    label: "12P - PRIME POWER PRODUCTION SPECIALIST"
                },
                {
                    id: 32,
                    label: "12Q - TRANSMISSION AND DISTRIBUTION SPECIALIST (RC)"
                },
                {
                    id: 33,
                    label: "12R - INTERIOR ELECTRICIAN"
                },
                {
                    id: 34,
                    label: "12T - TECHNICAL ENGINEER"
                },
                {
                    id: 35,
                    label: "12V - CONCRETE AND ASPHALT EQUIPMENT OPERATOR"
                },
                {
                    id: 36,
                    label: "12W - CARPENTRY AND MASONRY SPECIALIST"
                },
                {
                    id: 37,
                    label: "12X - GENERAL ENGINEERING SUPERVISOR"
                },
                {
                    id: 38,
                    label: "12Y - TERRAIN DATA SPECIALIST"
                },
                {
                    id: 39,
                    label: "12Z - COMBAT ENGINEERING SENIOR SERGEANT"
                },
                {
                    id: 40,
                    label: "13B - CANNON CREWMEMBER"
                },
                {
                    id: 41,
                    label: "13C - TACTICAL AUTOMATED FIRE CONTROL SYSTEMS SPECIALIST"
                },
                {
                    id: 42,
                    label: "13D - FIELD ARTILLERY AUTOMATED TACTICAL DATA SYSTEM SPECIALIST"
                },
                {
                    id: 43,
                    label: "13E - CANNON FIRE DIRECTION SPECIALIST"
                },
                {
                    id: 44,
                    label: "13F - FIRE SUPPORT SPECIALIST"
                },
                {
                    id: 45,
                    label: "13M - MULTIPLE LAUNCH ROCKET SYSTEM (MLRS)/HIGH MOBILITY ARTILLERY ROCKET SYST EM (HIMARS) CREWMEMBER"
                },
                {
                    id: 46,
                    label: "13P - MULTIPLE LAUNCH ROCKET SYSTEM (MLRS) OPERATIONAL FIRE DIRECTION SPECIALIST"
                },
                {
                    id: 47,
                    label: "13R - FIELD ARTILLERY FIREFINDER RADAR OPERATOR"
                },
                {
                    id: 48,
                    label: "13T - FIELD ARTILLERY SURVEYOR/METEOROLOGICAL CREWMEMBER"
                },
                {
                    id: 49,
                    label: "13X - FIELD ARTILLERY COMPUTER SYSTEMS SPECIALIST"
                },
                {
                    id: 50,
                    label: "13Z - FIELD ARTILLERY SENIOR SERGEANT"
                },
                {
                    id: 51,
                    label: "14E - PATRIOT FIRE CONTROL ENHANCED OPERATOR/MAINTAINER"
                },
                {
                    id: 52,
                    label: "14J - AIR DEFENSE COMMAND, CONTROL, COMMUNICATIONS, COMPUTERS, AND INTELLIGENCE TACTICAL OPERATIONS CENTER ENHANCED OPERATOR/MAINTAINER"
                },
                {
                    id: 53,
                    label: "14M - MAN PORTABLE AIR DEFENSE SYSTEM CREWMEMBER (RC)"
                },
                {
                    id: 54,
                    label: "14S - AIR AND MISSILE DEFENSE (AMD) CREWMEMBER"
                },
                {
                    id: 55,
                    label: "14T - PATRIOT LAUNCHING STATION ENHANCED OPERATOR/MAINTAINER"
                },
                {
                    id: 56,
                    label: "14X - SPACE AND MISSILE DEFENSE OPERATIONS"
                },
                {
                    id: 57,
                    label: "14Z - AIR DEFENSE ARTILLERY SENIOR SERGEANT"
                },
                {
                    id: 58,
                    label: "15A - AVIATION LIFE SUPPORT SYSTEM (ALSS) REPAIRER"
                },
                {
                    id: 59,
                    label: "15B - AIRCRAFT POWERPLANT REPAIRER"
                },
                {
                    id: 60,
                    label: "15D - AIRCRAFT POWERTRAIN REPAIRER"
                },
                {
                    id: 61,
                    label: "15F - AIRCRAFT ELECTRICIAN"
                },
                {
                    id: 62,
                    label: "15G - AIRCRAFT STRUCTURAL REPAIRER"
                },
                {
                    id: 63,
                    label: "15H - AIRCRAFT PNEUDRAULICS REPAIRER"
                },
                {
                    id: 64,
                    label: "15J - OH-58D/ARH ARMAMENT/ELECTRICAL/AVIONICS SYSTEMS REPAIRER"
                },
                {
                    id: 65,
                    label: "15K - AIRCRAFT COMPONENTS REPAIR SUPERVISOR"
                },
                {
                    id: 66,
                    label: "15M - UH-1 HELICOPTER REPAIRER (RC)"
                },
                {
                    id: 67,
                    label: "15N - AVIONIC MECHANIC"
                },
                {
                    id: 68,
                    label: "15P - AVIATION OPERATIONS SPECIALIST"
                },
                {
                    id: 69,
                    label: "15Q - AIR TRAFFIC CONTROL OPERATOR"
                },
                {
                    id: 70,
                    label: "15R - AH-64 ATTACK HELICOPTER REPAIRER"
                },
                {
                    id: 71,
                    label: "15S - OH-58D/ARH HELICOPTER REPAIRER"
                },
                {
                    id: 72,
                    label: "15T - UH-60 HELICOPTER REPAIRER"
                },
                {
                    id: 73,
                    label: "15U - CH-47 HELICOPTER REPAIRER"
                },
                {
                    id: 74,
                    label: "15V - OBSERVATION/SCOUT HELICOPTER REPAIRER (RC)"
                },
                {
                    id: 75,
                    label: "15W - UNMANNED AERIAL VEHICLE OPERATOR"
                },
                {
                    id: 76,
                    label: "15X - AH-64A ARMAMENT/ELECTRICAL/AVIONICS SYSTEMS REPAIRER"
                },
                {
                    id: 77,
                    label: "15Y - AH-64D ARMAMENT/ELECTRICAL/AVIONIC SYSTEMS REPAIRER"
                },
                {
                    id: 78,
                    label: "15Z - AIRCRAFT MAINTENANCE SENIOR SERGEANT"
                },
                {
                    id: 79,
                    label: "18B - SPECIAL FORCES WEAPONS SERGEANT"
                },
                {
                    id: 80,
                    label: "18C - SPECIAL FORCES ENGINEER SERGEANT"
                },
                {
                    id: 81,
                    label: "18D - SPECIAL FORCES MEDICAL SERGEANT"
                },
                {
                    id: 82,
                    label: "18E - SPECIAL FORCES COMMUNICATIONS SERGEANT"
                },
                {
                    id: 83,
                    label: "18F - SPECIAL FORCES ASSISTANT OPERATIONS AND INTELLIGENCE SERGEANT"
                },
                {
                    id: 84,
                    label: "18X - SPECIAL FORCES RECRUIT"
                },
                {
                    id: 85,
                    label: "18Z - SPECIAL FORCES SENIOR SERGEANT"
                },
                {
                    id: 86,
                    label: "19D - CAVALRY SCOUT"
                },
                {
                    id: 87,
                    label: "19K - M1 ARMOR CREWMAN"
                },
                {
                    id: 88,
                    label: "19Z - ARMOR SENIOR SERGEANT"
                },
                {
                    id: 89,
                    label: "21Y - TERRAIN DATA SPECIALIST"
                },
                {
                    id: 90,
                    label: "25B - INFORMATION TECHNOLOGY SPECIALIST"
                },
                {
                    id: 91,
                    label: "25C - RADIO OPERATOR-MAINTAINER"
                },
                {
                    id: 92,
                    label: "25E - ELECTROMAGNETIC SPECTRUM MANAGER"
                },
                {
                    id: 93,
                    label: "25F - NETWORK SWITCHING SYSTEMS OPERATOR-MAINTAINER"
                },
                {
                    id: 94,
                    label: "25L - CABLE SYSTEMS INSTALLER-MAINTAINER"
                },
                {
                    id: 95,
                    label: "25M - MULTIMEDIA ILLUSTRATOR"
                },
                {
                    id: 96,
                    label: "25N - NODAL NETWORK SYSTEMS OPERATOR-MAINTAINER"
                },
                {
                    id: 97,
                    label: "25P - MICROWAVE SYSTEMS OPERATOR-MAINTAINER"
                },
                {
                    id: 98,
                    label: "25Q - MULTICHANNEL TRANSMISSION SYSTEMS OPERATOR-MAINTAINER"
                },
                {
                    id: 99,
                    label: "25R - VISUAL INFORMATION EQUIPMENT OPERATOR-MAINTAINER"
                },
                {
                    id: 100,
                    label: "25S - SATELLITE COMMUNICATION SYSTEMS OPERATOR-MAINTAINER"
                },
                {
                    id: 101,
                    label: "25T - SATELLITE/MICROWAVE SYSTEMS CHIEF"
                },
                {
                    id: 102,
                    label: "25U - SIGNAL SUPPORT SYSTEMS SPECIALIST"
                },
                {
                    id: 103,
                    label: "25V - COMBAT DOCUMENTATION/PRODUCTION SPECIALIST"
                },
                {
                    id: 104,
                    label: "25W - TELECOMMUNICATIONS OPERATIONS CHIEF"
                },
                {
                    id: 105,
                    label: "25X - SENIOR SIGNAL SERGEANT"
                },
                {
                    id: 106,
                    label: "25Z - VISUAL INFORMATION OPERATIONS CHIEF"
                },
                {
                    id: 107,
                    label: "27D - PARALEGAL SPECIALIST"
                },
                {
                    id: 108,
                    label: "31B - MILITARY POLICE"
                },
                {
                    id: 109,
                    label: "31D - CID SPECIAL AGENT"
                },
                {
                    id: 110,
                    label: "31E - INTERNMENT/RESETTLEMENT SPECIALIST"
                },
                {
                    id: 111,
                    label: "35F - INTELLIGENCE ANALYST"
                },
                {
                    id: 112,
                    label: "35G - IMAGERY ANALYST"
                },
                {
                    id: 113,
                    label: "35H - COMMON GROUND STATION (CGS) ANALYST"
                },
                {
                    id: 114,
                    label: "35L - COUNTER INTELLIGENCE AGENT"
                },
                {
                    id: 115,
                    label: "35M - HUMAN INTELLIGENCE COLLECTOR"
                },
                {
                    id: 116,
                    label: "35N - SIGNALS INTELLIGENCE ANALYST"
                },
                {
                    id: 117,
                    label: "35P - CRYPTOLOGIC LINGUIST"
                },
                {
                    id: 118,
                    label: "35S - SIGNALS COLLECTOR/ANALYST"
                },
                {
                    id: 119,
                    label: "35T - MILITARY INTELLIGENCE SYSTEMS MAINTAINER/INTEGRATOR"
                },
                {
                    id: 120,
                    label: "35W - EW/SIGINT RECRUIT"
                },
                {
                    id: 121,
                    label: "35X - INTELLIGENCE SENIOR SERGEANT/CHIEF INTELLIGENCE SERGEANT"
                },
                {
                    id: 122,
                    label: "35Y - CHIEF COUNTER INTELLIGENCE/HUMAN INTELLIGENCE SERGEANT"
                },
                {
                    id: 123,
                    label: "35Z - SIGNALS INTELLIGENCE (ELECTRONIC WARFARE) / SENIOR SERGEANT / CHIEF"
                },
                {
                    id: 124,
                    label: "36B - FINANCIAL MANAGEMENT TECHNICIAN"
                },
                {
                    id: 125,
                    label: "37F - PSYCHOLOGICAL OPERATIONS SPECIALIST"
                },
                {
                    id: 126,
                    label: "38B - CIVIL AFFAIRS SPECIALIST"
                },
                {
                    id: 127,
                    label: "42A - HUMAN RESOURCES SPECIALIST"
                },
                {
                    id: 128,
                    label: "42F - HUMAN RESOURCES INFORMATION SYSTEMS MANAGEMENT SPECIALIST"
                },
                {
                    id: 129,
                    label: "42R - ARMY BANDPERSON"
                },
                {
                    id: 130,
                    label: "42S - SPECIAL BAND MEMBER"
                },
                {
                    id: 131,
                    label: "46Q - PUBLIC AFFAIRS SPECIALIST"
                },
                {
                    id: 132,
                    label: "46R - PUBLIC AFFAIRS BROADCAST SPECIALIST"
                },
                {
                    id: 133,
                    label: "46Z - CHIEF PUBLIC AFFAIRS NCO"
                },
                {
                    id: 134,
                    label: "51C - ACQUISITION, LOGISTICS & TECHNOLOGY (AL&T) CONTRACTING NCO"
                },
                {
                    id: 135,
                    label: "56M - CHAPLAIN ASSISTANT"
                },
                {
                    id: 136,
                    label: "68A - BIOMEDICAL EQUIPMENT SPECIALIST"
                },
                {
                    id: 137,
                    label: "68D - OPERATING ROOM SPECIALIST"
                },
                {
                    id: 138,
                    label: "68E - DENTAL SPECIALIST"
                },
                {
                    id: 139,
                    label: "68G - PATIENT ADMINISTRATION SPECIALIST"
                },
                {
                    id: 140,
                    label: "68H - OPTICAL LABORATORY SPECIALIST"
                },
                {
                    id: 141,
                    label: "68J - MEDICAL LOGISTICS SPECIALIST"
                },
                {
                    id: 142,
                    label: "68K - MEDICAL LABORATORY SPECIALIST"
                },
                {
                    id: 143,
                    label: "68M - NUTRITION CARE SPECIALIST"
                },
                {
                    id: 144,
                    label: "68P - RADIOLOGY SPECIALIST"
                },
                {
                    id: 145,
                    label: "68Q - PHARMACY SPECIALIST"
                },
                {
                    id: 146,
                    label: "68R - VETERINARY FOOD INSPECTION SPECIALIST"
                },
                {
                    id: 147,
                    label: "68S - PREVENTIVE MEDICINE SPECIALIST"
                },
                {
                    id: 148,
                    label: "68T - ANIMAL CARE SPECIALIST"
                },
                {
                    id: 149,
                    label: "68V - RESPIRATORY SPECIALIST"
                },
                {
                    id: 150,
                    label: "68W - HEALTH CARE SPECIALIST"
                },
                {
                    id: 151,
                    label: "68X - MENTAL HEALTH SPECIALIST"
                },
                {
                    id: 152,
                    label: "68Z - CHIEF MEDICAL NCO"
                },
                {
                    id: 153,
                    label: "74D - CHEMICAL, BIOLOGICAL, RADIOLOGICAL AND NUCLEAR (CBRN) SPECIALIST"
                },
                {
                    id: 154,
                    label: "79R - RECRUITER"
                },
                {
                    id: 155,
                    label: "79S - CAREER COUNSELOR"
                },
                {
                    id: 156,
                    label: "79T - RECRUITING AND RETENTION NCO (ARMY NATIONAL GUARD OF THE UNITED STATES)"
                },
                {
                    id: 157,
                    label: "79V - RETENTION AND TRANSITION NCO, USAR"
                },
                {
                    id: 158,
                    label: "88H - CARGO SPECIALIST"
                },
                {
                    id: 159,
                    label: "88K - WATERCRAFT OPERATOR"
                },
                {
                    id: 160,
                    label: "88L - WATERCRAFT ENGINEER"
                },
                {
                    id: 161,
                    label: "88M - MOTOR TRANSPORT OPERATOR"
                },
                {
                    id: 162,
                    label: "88N - TRANSPORTATION MANAGEMENT COORDINATOR"
                },
                {
                    id: 163,
                    label: "88P - RAILWAY EQUIPMENT REPAIRER (RC)"
                },
                {
                    id: 164,
                    label: "88T - RAILWAY SECTION REPAIRER (RC)"
                },
                {
                    id: 165,
                    label: "88U - RAILWAY OPERATIONS CREWMEMBER (RC)"
                },
                {
                    id: 166,
                    label: "88Z - TRANSPORTATION SENIOR SERGEANT"
                },
                {
                    id: 167,
                    label: "89A - AMMUNITION STOCK CONTROL AND ACCOUNTING SPECIALIST"
                },
                {
                    id: 168,
                    label: "89B - AMMUNITION SPECIALIST"
                },
                {
                    id: 169,
                    label: "89D - EXPLOSIVE ORDNANCE DISPOSAL SPECIALIST"
                },
                {
                    id: 170,
                    label: "91A - M1 ABRAMS TANK SYSTEM MAINTAINER"
                },
                {
                    id: 171,
                    label: "91B - WHEELED VEHICLE MECHANIC"
                },
                {
                    id: 172,
                    label: "91C - UTILITIES EQUIPMENT REPAIRER"
                },
                {
                    id: 173,
                    label: "91D - POWER-GENERATION EQUIPMENT REPAIRER"
                },
                {
                    id: 174,
                    label: "91E - MACHINIST"
                },
                {
                    id: 175,
                    label: "91F - SMALL ARMS/ARTILLERY REPAIRER"
                },
                {
                    id: 176,
                    label: "91G - FIRE CONTROL REPAIRER"
                },
                {
                    id: 177,
                    label: "91H - TRACK VEHICLE REPAIRER"
                },
                {
                    id: 178,
                    label: "91J - QUARTERMASTER AND CHEMICAL EQUIPMENT REPAIRER"
                },
                {
                    id: 179,
                    label: "91K - ARMAMENT REPAIRER"
                },
                {
                    id: 180,
                    label: "91L - CONSTRUCTION EQUIPMENT REPAIRER"
                },
                {
                    id: 181,
                    label: "91M - BRADLEY FIGHTING VEHICLE SYSTEM MAINTAINER"
                },
                {
                    id: 182,
                    label: "91P - ARTILLERY MECHANIC"
                },
                {
                    id: 183,
                    label: "91W - METAL WORKER"
                },
                {
                    id: 184,
                    label: "91X - MAINTENANCE SUPERVISOR"
                },
                {
                    id: 185,
                    label: "91Z - MECHANICAL MAINTENANCE SUPERVISOR"
                },
                {
                    id: 186,
                    label: "92A - AUTOMATED LOGISTICAL SPECIALIST"
                },
                {
                    id: 187,
                    label: "92F - PETROLEUM SUPPLY SPECIALIST"
                },
                {
                    id: 188,
                    label: "92G - FOOD SERVICE SPECIALIST"
                },
                {
                    id: 189,
                    label: "92L - PETROLEUM LABORATORY SPECIALIST"
                },
                {
                    id: 190,
                    label: "92M - MORTUARY AFFAIRS SPECIALIST"
                },
                {
                    id: 191,
                    label: "92R - PARACHUTE RIGGER"
                },
                {
                    id: 192,
                    label: "92S - SHOWER/LAUNDRY AND CLOTHING REPAIR SPECIALIST"
                },
                {
                    id: 193,
                    label: "92W - WATER TREATMENT SPECIALIST"
                },
                {
                    id: 194,
                    label: "92Y - UNIT SUPPLY SPECIALIST"
                },
                {
                    id: 195,
                    label: "92Z - SENIOR NONCOMMISSIONED LOGISTICIAN"
                },
                {
                    id: 196,
                    label: "94A - LAND COMBAT ELECTRONIC MISSILE SYSTEM REPAIRER"
                },
                {
                    id: 197,
                    label: "94D - AIR TRAFFIC CONTROL EQUIPMENT REPAIRER"
                },
                {
                    id: 198,
                    label: "94E - RADIO AND COMMUNICATIONS SECURITY (COMSEC) REPAIRER"
                },
                {
                    id: 199,
                    label: "94F - COMPUTER DETECTION SYSTEMS REPAIRER"
                },
                {
                    id: 200,
                    label: "94H - TEST, MEASUREMENT, AND DIAGNOSTIC EQUIPMENT (TMDE) MAINTENANCE SUPPORT SPECIALIST"
                },
                {
                    id: 201,
                    label: "94K - APACHE ATTACK HELICOPTER SYSTEMS REPAIRER"
                },
                {
                    id: 202,
                    label: "94L - AVIONIC COMMUNICATIONS EQUIPMENT REPAIRER"
                },
                {
                    id: 203,
                    label: "94M - RADAR REPAIRER"
                },
                {
                    id: 204,
                    label: "94P - MULTIPLE LAUNCH ROCKET SYSTEM REPAIRER"
                },
                {
                    id: 205,
                    label: "94R - AVIONIC AND SURVIVABILITY EQUIPMENT REPAIRER"
                },
                {
                    id: 206,
                    label: "94S - PATRIOT SYSTEM REPAIRER"
                },
                {
                    id: 207,
                    label: "94T - AVENGER SYSTEM REPAIRER"
                },
                {
                    id: 208,
                    label: "94W - ELECTRONIC MAINTENANCE CHIEF"
                },
                {
                    id: 209,
                    label: "94X - SENIOR MISSILE SYSTEMS MAINTAINER"
                },
                {
                    id: 210,
                    label: "94Y - INTEGRATED FAMILY OF TEST EQUIPMENT (IFTE) OPERATOR AND MAINTAINER"
                },
                {
                    id: 211,
                    label: "94Z - SENIOR ELECTRONIC MAINTENANCE CHIEF"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "841F1263-13EE-44C4-A9C1-1362970B4BB5",
        formLabel: "Primary MOS (Warrant)",
        reportLabel: "Primary MOS (Warrant)",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 2,
                    label: "125D - GEOSPATIAL INFORMATION TECHNICIAN"
                },
                {
                    id: 3,
                    label: "131A - FIELD ARTILLERY TARGETING TECHNICIAN"
                },
                {
                    id: 4,
                    label: "140A - COMMAND AND CONTROL SYSTEMS INTEGRATOR"
                },
                {
                    id: 5,
                    label: "140E - PATRIOT SYSTEMS TECHNICIAN"
                },
                {
                    id: 6,
                    label: "140X - AIR DEFENSE ARTILLERY (ADA) IMMATERIAL"
                },
                {
                    id: 7,
                    label: "150A - AIR TRAFFIC AND AIR SPACE MANAGEMENT TECHNICIAN"
                },
                {
                    id: 8,
                    label: "150U - TACTICAL UNMANNED AERIAL VEHICLE (TUAV) OPERATIONS TECHNICIAN"
                },
                {
                    id: 9,
                    label: "151A - AVIATION MAINTENANCE TECHNICIAN (NONRATED)"
                },
                {
                    id: 10,
                    label: "152B - OH-58A/C SCOUT PILOT (RC)"
                },
                {
                    id: 11,
                    label: "152C - OH-6 PILOT"
                },
                {
                    id: 12,
                    label: "152D - OH-58D PILOT"
                },
                {
                    id: 13,
                    label: "152E - ARH-XX PILOT"
                },
                {
                    id: 14,
                    label: "152F - AH-64A ATTACK PILOT"
                },
                {
                    id: 15,
                    label: "152H - AH-64D ATTACK PILOT"
                },
                {
                    id: 16,
                    label: "153A - ROTARY WING AVIATOR (AIRCRAFT NONSPECIFIC)"
                },
                {
                    id: 17,
                    label: "153B - UH-1 PILOT (RC)"
                },
                {
                    id: 18,
                    label: "153D - UH-60 PILOT"
                },
                {
                    id: 19,
                    label: "153E - MH-60 PILOT"
                },
                {
                    id: 20,
                    label: "153L - UH-72A PILOT"
                },
                {
                    id: 21,
                    label: "153M - UH-60M PILOT"
                },
                {
                    id: 22,
                    label: "154C - CH-47D PILOT"
                },
                {
                    id: 23,
                    label: "154E - MH-47 PILOT"
                },
                {
                    id: 24,
                    label: "154F - CH-47F PILOT"
                },
                {
                    id: 25,
                    label: "155A - FIXED WING AVIATOR (AIRCRAFT NONSPECIFIC)"
                },
                {
                    id: 26,
                    label: "155E - C-12 PILOT"
                },
                {
                    id: 27,
                    label: "155F - JET AIRCRAFT PILOT"
                },
                {
                    id: 28,
                    label: "155G - 0-5A/EO-5B/RC-7 PILOT"
                },
                {
                    id: 29,
                    label: "180A - SPECIAL FORCES WARRANT OFFICER"
                },
                {
                    id: 30,
                    label: "250N - NETWORK MANAGEMENT TECHNICIAN"
                },
                {
                    id: 31,
                    label: "251A - INFORMATION SYSTEMS TECHNICIAN"
                },
                {
                    id: 32,
                    label: "254A - SIGNAL SYSTEMS SUPPORT TECHNICIAN"
                },
                {
                    id: 33,
                    label: "255Z - SENIOR SIGNAL SYSTEMS TECHNICIAN"
                },
                {
                    id: 34,
                    label: "270A - LEGAL ADMINISTRATOR"
                },
                {
                    id: 35,
                    label: "311A - CID SPECIAL AGENT"
                },
                {
                    id: 36,
                    label: "350F - ALL SOURCE INTELLIGENCE TECHNICIAN"
                },
                {
                    id: 37,
                    label: "350G - IMAGERY INTELLIGENCE TECHNICIAN"
                },
                {
                    id: 38,
                    label: "350Z - ATTACHE TECHNICIAN"
                },
                {
                    id: 39,
                    label: "351L - COUNTERINTELLIGENCE TECHNICIAN"
                },
                {
                    id: 40,
                    label: "351M - HUMAN INTELLIGENCE COLLECTION TECHNICIAN"
                },
                {
                    id: 41,
                    label: "351Y - AREA INTELLIGENCE TECHNICIAN"
                },
                {
                    id: 42,
                    label: "352N - SIGNALS INTELLIGENCE ANALYSIS TECHNICIAN"
                },
                {
                    id: 43,
                    label: "352P - VOICE INTERCEPT TECHNICIAN"
                },
                {
                    id: 44,
                    label: "352S - SIGNALS COLLECTION TECHNICIAN"
                },
                {
                    id: 45,
                    label: "353T - IEW SYSTEMS MAINTENANCE TECHNICIAN"
                },
                {
                    id: 46,
                    label: "420A - HUMAN RESOURCES TECHNICIAN"
                },
                {
                    id: 47,
                    label: "420C - BANDMASTER"
                },
                {
                    id: 48,
                    label: "640A - VETERINARY SERVICES TECHNICIAN"
                },
                {
                    id: 49,
                    label: "670A - HEALTH SERVICES MAINTENANCE TECHNICIAN"
                },
                {
                    id: 50,
                    label: "880A - MARINE DECK OFFICER"
                },
                {
                    id: 51,
                    label: "881A - MARINE ENGINEERING OFFICER"
                },
                {
                    id: 52,
                    label: "882A - MOBILITY OFFICER"
                },
                {
                    id: 53,
                    label: "890A - AMMUNITION TECHNICIAN"
                },
                {
                    id: 54,
                    label: "913A - ARMAMENT SYSTEMS MAINTENANCE WARRANT OFFICER"
                },
                {
                    id: 55,
                    label: "914A - ALLIED TRADES WARRANT OFFICER"
                },
                {
                    id: 56,
                    label: "915A - AUTOMOTIVE MAINTENANCE WARRANT OFFICER"
                },
                {
                    id: 57,
                    label: "915E - SENIOR AUTOMOTIVE MAINTENANCE OFFICER/SENIOR ORDNANCE LOGISTICS OFFICER"
                },
                {
                    id: 58,
                    label: "919A - ENGINEER EQUIPMENT MAINTENANCE WARRANT OFFICER"
                },
                {
                    id: 59,
                    label: "920A - PROPERTY ACCOUNTING TECHNICIAN"
                },
                {
                    id: 60,
                    label: "920B - SUPPLY SYSTEMS TECHNICIAN"
                },
                {
                    id: 61,
                    label: "921A - AIRDROP SYSTEMS TECHNICIAN"
                },
                {
                    id: 62,
                    label: "922A - FOOD SERVICE TECHNICIAN"
                },
                {
                    id: 63,
                    label: "923A - PETROLEUM TECHNICIAN"
                },
                {
                    id: 64,
                    label: "948B - ELECTRONIC SYSTEMS MAINTENANCE WARRANT OFFICER"
                },
                {
                    id: 65,
                    label: "948D - ELECTRONICS-MISSILE MAINTENANCE WARRANT OFFICER"
                },
                {
                    id: 66,
                    label: "948E - SENIOR ELECTRONICS MAINTENANCE WARRANT OFFICER"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "5A1C9CD0-9C5E-40BA-9909-F99412B72D8B",
        formLabel: "Primary MOS (Officer)",
        reportLabel: "Primary MOS (Officer)",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 1,
                    label: "11A - INFANTRY"
                },
                {
                    id: 2,
                    label: "12A - ENGINEER, GENERAL"
                },
                {
                    id: 3,
                    label: "12B - COMBAT ENGINEER"
                },
                {
                    id: 4,
                    label: "12D - FACILITIES/CONTRACT CONSTRUCTION MANAGEMENT ENGINEER (FCCME)"
                },
                {
                    id: 5,
                    label: "13A - FIELD ARTILLERY, GENERAL"
                },
                {
                    id: 6,
                    label: "14A - AIR DEFENSE ARTILLERY OFFICER"
                },
                {
                    id: 7,
                    label: "15A - AVIATION, GENERAL"
                },
                {
                    id: 8,
                    label: "15B - AVIATION COMBINED ARMS OPERATIONS"
                },
                {
                    id: 9,
                    label: "15C - AVIATION ALL-SOURCE INTELLIGENCE"
                },
                {
                    id: 10,
                    label: "18A - SPECIAL FORCES"
                },
                {
                    id: 11,
                    label: "19A - ARMOR, GENERAL"
                },
                {
                    id: 12,
                    label: "19B - ARMOR"
                },
                {
                    id: 13,
                    label: "19C - CAVALRY"
                },
                {
                    id: 14,
                    label: "25A - SIGNAL, GENERAL"
                },
                {
                    id: 15,
                    label: "27A - JUDGE ADVOCATE GENERAL"
                },
                {
                    id: 16,
                    label: "27B - MILITARY JUDGE"
                },
                {
                    id: 17,
                    label: "31A - MILITARY POLICE"
                },
                {
                    id: 18,
                    label: "35C - IMAGERY INTELLIGENCE (IMINT)"
                },
                {
                    id: 19,
                    label: "35D - ALL SOURCE INTELLIGENCE"
                },
                {
                    id: 20,
                    label: "35E - COUNTER INTELLIGENCE (CI)"
                },
                {
                    id: 21,
                    label: "35F - HUMAN INTELLIGENCE (HUMINT)"
                },
                {
                    id: 22,
                    label: "35G - SIGNALS INTELLIGENCE/ELECTRONIC WARFARE (SIGINT/EW)"
                },
                {
                    id: 23,
                    label: "36A - FINANCIAL MANAGER"
                },
                {
                    id: 24,
                    label: "37A - PSYCHOLOGICAL OPERATIONS"
                },
                {
                    id: 25,
                    label: "37X - PSYCHOLOGICAL OPERATIONS, DESIGNATED"
                },
                {
                    id: 26,
                    label: "38A - CIVIL AFFAIRS (AA AND USAR)"
                },
                {
                    id: 27,
                    label: "38X - CIVIL AFFAIRS, DESIGNATED"
                },
                {
                    id: 28,
                    label: "42B - HUMAN RESOURCES OFFICER"
                },
                {
                    id: 29,
                    label: "42C - ARMY BANDS"
                },
                {
                    id: 30,
                    label: "42H - SENIOR HUMAN RESOURCES OFFICER"
                },
                {
                    id: 31,
                    label: "56A - COMMAND AND UNIT CHAPLAIN"
                },
                {
                    id: 32,
                    label: "56D - CLINICAL PASTORAL EDUCATOR"
                },
                {
                    id: 33,
                    label: "60A - OPERATIONAL MEDICINE"
                },
                {
                    id: 34,
                    label: "60B - NUCLEAR MEDICINE OFFICER"
                },
                {
                    id: 35,
                    label: "60C - PREVENTIVE MEDICINE OFFICER"
                },
                {
                    id: 36,
                    label: "60D - OCCUPATIONAL MEDICINE OFFICER"
                },
                {
                    id: 37,
                    label: "60F - PULMONARY DISEASE/CRITICAL CARE OFFICER"
                },
                {
                    id: 38,
                    label: "60G - GASTROENTEROLOGIST"
                },
                {
                    id: 39,
                    label: "60H - CARDIOLOGIST"
                },
                {
                    id: 40,
                    label: "60J - OBSTETRICIAN AND GYNECOLOGIST"
                },
                {
                    id: 41,
                    label: "60K - UROLOGIST"
                },
                {
                    id: 42,
                    label: "60L - DERMATOLOGIST"
                },
                {
                    id: 43,
                    label: "60M - ALLERGIST, CLINICAL IMMUNOLOGIST"
                },
                {
                    id: 44,
                    label: "60N - ANESTHESIOLOGIST"
                },
                {
                    id: 45,
                    label: "60P - PEDIATRICIAN"
                },
                {
                    id: 46,
                    label: "60Q - PEDIATRIC SUB-SPECIALIST"
                },
                {
                    id: 47,
                    label: "60R - CHILD NEUROLOGIST"
                },
                {
                    id: 48,
                    label: "60S - OPHTHALMOLOGIST"
                },
                {
                    id: 49,
                    label: "60T - OTOLARYNGOLOGIST"
                },
                {
                    id: 50,
                    label: "60U - CHILD PSYCHIATRIST"
                },
                {
                    id: 51,
                    label: "60V - NEUROLOGIST"
                },
                {
                    id: 52,
                    label: "60W - PSYCHIATRIST"
                },
                {
                    id: 53,
                    label: "61A - NEPHROLOGIST"
                },
                {
                    id: 54,
                    label: "61B - MEDICAL ONCOLOGIST/HEMATOLOGIST"
                },
                {
                    id: 55,
                    label: "61C - ENDOCRINOLOGIST"
                },
                {
                    id: 56,
                    label: "61D - RHEUMATOLOGIST"
                },
                {
                    id: 57,
                    label: "61E - CLINICAL PHARMACOLOGIST"
                },
                {
                    id: 58,
                    label: "61F - INTERNIST"
                },
                {
                    id: 59,
                    label: "61G - INFECTIOUS DISEASE OFFICER"
                },
                {
                    id: 60,
                    label: "61H - FAMILY MEDICINE"
                },
                {
                    id: 61,
                    label: "61J - GENERAL SURGEON"
                },
                {
                    id: 62,
                    label: "61K - THORACIC SURGEON"
                },
                {
                    id: 63,
                    label: "61L - PLASTIC SURGEON"
                },
                {
                    id: 64,
                    label: "61M - ORTHOPEDIC SURGEON"
                },
                {
                    id: 65,
                    label: "61N - FLIGHT SURGEON"
                },
                {
                    id: 66,
                    label: "61P - PHYSIATRIST"
                },
                {
                    id: 67,
                    label: "61Q - RADIATION ONCOLOGIST"
                },
                {
                    id: 68,
                    label: "61R - DIAGNOSTIC RADIOLOGIST"
                },
                {
                    id: 69,
                    label: "61U - PATHOLOGIST"
                },
                {
                    id: 70,
                    label: "61W - PERIPHERAL VASCULAR SURGEON"
                },
                {
                    id: 71,
                    label: "61Z - NEUROSURGEON"
                },
                {
                    id: 72,
                    label: "62A - EMERGENCY PHYSICIAN"
                },
                {
                    id: 73,
                    label: "62B - FIELD SURGEON"
                },
                {
                    id: 74,
                    label: "63A - GENERAL DENTIST"
                },
                {
                    id: 75,
                    label: "63B - COMPREHENSIVE DENTIST"
                },
                {
                    id: 76,
                    label: "63D - PERIODONTIST"
                },
                {
                    id: 77,
                    label: "63E - ENDODONTIST"
                },
                {
                    id: 78,
                    label: "63F - PROSTHODONIST"
                },
                {
                    id: 79,
                    label: "63H - PUBLIC HEALTH DENTIST"
                },
                {
                    id: 80,
                    label: "63K - PEDIATRIC DENTIST"
                },
                {
                    id: 81,
                    label: "63M - ORTHODONTIST"
                },
                {
                    id: 82,
                    label: "63N - ORAL AND MAXILLOFACIAL SURGEON"
                },
                {
                    id: 83,
                    label: "63P - ORAL PATHOLOGIST"
                },
                {
                    id: 84,
                    label: "63R - EXECUTIVE DENTIST"
                },
                {
                    id: 85,
                    label: "64A - FIELD VETERINARY SERVICE"
                },
                {
                    id: 86,
                    label: "64B - VETERINARY PREVENTIVE MEDICINE"
                },
                {
                    id: 87,
                    label: "64C - VETERINARY LABORATORY ANIMAL MEDICINE"
                },
                {
                    id: 88,
                    label: "64D - VETERINARY PATHOLOGY"
                },
                {
                    id: 89,
                    label: "64E - VETERINARY COMPARATIVE MEDICINE"
                },
                {
                    id: 90,
                    label: "64F - VETERINARY CLINICAL MEDICINE"
                },
                {
                    id: 91,
                    label: "64Z - SENIOR VETERINARIAN (IMMATERIAL)"
                },
                {
                    id: 92,
                    label: "65A - OCCUPATIONAL THERAPY"
                },
                {
                    id: 93,
                    label: "65B - PHYSICAL THERAPY"
                },
                {
                    id: 94,
                    label: "65C - DIETITIAN"
                },
                {
                    id: 95,
                    label: "65D - PHYSICIAN ASSISTANT"
                },
                {
                    id: 96,
                    label: "65X - SPECIALIST ALLIED OPERATIONS"
                },
                {
                    id: 97,
                    label: "66B - ARMY PUBLIC HEALTH NURSE"
                },
                {
                    id: 98,
                    label: "66C - PSYCHIATRIC/MENTAL HEALTH NURSE"
                },
                {
                    id: 99,
                    label: "66E - PERIOPERATIVE NURSE"
                },
                {
                    id: 100,
                    label: "66F - NURSE ANESTHETIST"
                },
                {
                    id: 101,
                    label: "66G - OBSTETRICS AND GYNECO"
                },
                {
                    id: 102,
                    label: "66H - MEDICAL-SURGICAL NURSE"
                },
                {
                    id: 103,
                    label: "66N - GENERALIST NURSE"
                },
                {
                    id: 104,
                    label: "66P - FAMILY NURSE PRACTITI"
                },
                {
                    id: 105,
                    label: "67A - HEALTH SERVICES"
                },
                {
                    id: 106,
                    label: "67B - LABORATORY SCIENCES"
                },
                {
                    id: 107,
                    label: "67C - PREVENTIVE MEDICINE SCIENCES"
                },
                {
                    id: 108,
                    label: "67D - BEHAVIORAL SCIENCES"
                },
                {
                    id: 109,
                    label: "67E - PHARMACY"
                },
                {
                    id: 110,
                    label: "67F - OPTOMETRY"
                },
                {
                    id: 111,
                    label: "67G - PODIATRY"
                },
                {
                    id: 112,
                    label: "67J - AEROMEDICAL EVACUATION"
                },
                {
                    id: 113,
                    label: "74A - CHEMICAL, BIOLOGICAL, RADIOLOGICAL AND NUCLEAR (CBRN)"
                },
                {
                    id: 114,
                    label: "88A - TRANSPORTATION, GENERAL"
                },
                {
                    id: 115,
                    label: "88B - TRAFFIC MANAGEMENT"
                },
                {
                    id: 116,
                    label: "88C - MARINE AND TERMINAL OPERATIONS"
                },
                {
                    id: 117,
                    label: "88D - MOTOR/RAIL TRANSPORTATION"
                },
                {
                    id: 118,
                    label: "89E - EXPLOSIVE ORDNANCE DISPOSAL"
                },
                {
                    id: 119,
                    label: "91A - MAINTENANCE & MUNITIONS MATERIEL OFFICER"
                },
                {
                    id: 120,
                    label: "92A - QUARTERMASTER, GENERAL"
                },
                {
                    id: 121,
                    label: "92D - AERIAL DELIVERY AND MATERIEL"
                },
                {
                    id: 122,
                    label: "92F - PETROLEUM AND WATER"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    },
    {
        uid: "dc024ff8-7046-422f-8628-fcd7b247a72e",
        formLabel: "Are you an EPOC?",
        reportLabel: "EPOC",
        helpText: ["Example: State maintenance worker at an armory/readiness center, full time supply sergeant, shop EPOC, etc."],
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 1,
                    label: "Yes"
                },
                {
                    id: 2,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "f2cc7462-c956-41ad-bab6-1e3bb6c84f43",
        formLabel: "Do you work at a maintenance shop?",
        reportLabel: "Works at Shop",
        helpText: ["Example: UTES, CSMS, FMS."],
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 1,
                    label: "Yes"
                },
                {
                    id: 2,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "29e85428-6d03-4d9f-b07e-8255fc462232",
        formLabel: "Are you a CSMS Section EPOC?",
        reportLabel: "CSMS Section EPOC",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 1,
                    label: "Yes"
                },
                {
                    id: 2,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "5744bda3-9db6-4dbd-b2dc-a876b6c46dd4",
        formLabel: "Are you a supervisor of an EPOC?",
        reportLabel: "EPOC Supervisor",
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 1,
                    label: "Yes"
                },
                {
                    id: 2,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "482425c2-1394-4788-ba9a-ef341c8886f3",
        formLabel: "Do you move hazardous waste from  one location to another at an LQG/SQG site?",
        reportLabel: "Handles HW",
        helpText: ["Example: Physically moving hazardous waste from Camp Rilea range control to UTES for turn-in."],
        formElement: "choice",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                {
                    id: 1,
                    label: "Yes"
                },
                {
                    id: 2,
                    label: "No"
                }
            ]
        }
    },
    {
        uid: "FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E",
        formLabel: "Facility",
        reportLabel: "Facility",
        formElement: "choice",
        helpText: ["Please select all facilities where you have full-time or part-time duties."],
        formElementOptions: {
            multiselect: true,
            asCheckBoxOrRadio: false,
            items: [
                {
                    id: 55,
                    label: "Albany Armory"
                },
                {
                    id: 53,
                    label: "Camp Withycombe"
                },
                {
                    id: 54,
                    label: "Najaf Training Center (Camp Adair)"
                },
                {
                    id: 52,
                    label: "Salem Reserve Center"
                },
                {
                    id: 34,
                    label: "Anderson Readiness Center (Salem)"
                },
                {
                    id: 4,
                    label: "Ashland Armory"
                },
                {
                    id: 7,
                    label: "Baker City Readiness Center"
                },
                {
                    id: 1,
                    label: "Bend COTEF (YCP)"
                },
                {
                    id: 9,
                    label: "Biak Training Center"
                },
                {
                    id: 21,
                    label: "Bill Healey Armory (Bend)"
                },
                {
                    id: 23,
                    label: "Boardman Range Complex"
                },
                {
                    id: 44,
                    label: "Burns Armory"
                },
                {
                    id: 48,
                    label: "Camp Rilea"
                },
                {
                    id: 40,
                    label: "Camp Umatilla"
                },
                {
                    id: 49,
                    label: "Christmas Valley"
                },
                {
                    id: 2,
                    label: "Coos Bay Armory"
                },
                {
                    id: 30,
                    label: "Corvallis Armory"
                },
                {
                    id: 37,
                    label: "COUTES"
                },
                {
                    id: 51,
                    label: "Dallas AFRC"
                },
                {
                    id: 18,
                    label: "Forest Grove Armory"
                },
                {
                    id: 41,
                    label: "Grants Pass Armory"
                },
                {
                    id: 25,
                    label: "Gresham Armory"
                },
                {
                    id: 13,
                    label: "Hermiston Armory"
                },
                {
                    id: 16,
                    label: "Hillsboro Armory"
                },
                {
                    id: 14,
                    label: "Hood River Armory"
                },
                {
                    id: 26,
                    label: "Jackson Armory"
                },
                {
                    id: 24,
                    label: "Kingsley Base"
                },
                {
                    id: 27,
                    label: "Kliever Memorial Armory"
                },
                {
                    id: 8,
                    label: "La Grande Armory"
                },
                {
                    id: 29,
                    label: "LaGrande FMS"
                },
                {
                    id: 43,
                    label: "Lebanon Armory"
                },
                {
                    id: 42,
                    label: "Lebanon Storage Site"
                },
                {
                    id: 19,
                    label: "McMinnville Armory"
                },
                {
                    id: 45,
                    label: "McNary Field Salem AASF"
                },
                {
                    id: 3,
                    label: "Medford Armory"
                },
                {
                    id: 12,
                    label: "Milton-Freewater Armory"
                },
                {
                    id: 31,
                    label: "Newport Armory"
                },
                {
                    id: 50,
                    label: "Ontario AFRC"
                },
                {
                    id: 22,
                    label: "ORARNG RTI"
                },
                {
                    id: 33,
                    label: "Owen Summers JFHQ"
                },
                {
                    id: 11,
                    label: "Pendleton AASF2"
                },
                {
                    id: 10,
                    label: "Pendleton Armory"
                },
                {
                    id: 38,
                    label: "Prineville Army Readiness Center"
                },
                {
                    id: 36,
                    label: "Redmond Armory"
                },
                {
                    id: 5,
                    label: "Roseburg Armory"
                },
                {
                    id: 35,
                    label: "Salem Armory"
                },
                {
                    id: 32,
                    label: "Salem FMS"
                },
                {
                    id: 28,
                    label: "Springfield FMS / AFRC"
                },
                {
                    id: 17,
                    label: "St. Helens Armory"
                },
                {
                    id: 39,
                    label: "The Dalles Armory"
                },
                {
                    id: 20,
                    label: "Woodburn Armory"
                },
                {
                    id: 1000,
                    label: "OTHER"
                }
            ]
        }
    }
];