import { DomainField, ComparisonOperators } from "../types";

export const UserProfileFields: DomainField = {
    uid: {
        sortOrder: 0,
        dataElementUid: "aabb85af-eb9c-4063-97bb-3ac97d9b756f",
        onAction: {
            base: { required: ["Required"] },
            create: { required: false, omit: true }
        }
    },
    email: {
        sortOrder: 1,
        dataElementUid: "1ed1faf6-c346-4d22-8f4b-934bd73b711c",
        onAction: {
            base: { required: ["Required"] }
        },
        dataElementOverrides: {
            // remove emailAvailable validator from update & read
            update: {                
                validators: [                    
                    { kind: "email", params: ["Enter a valid email"] }
                ]
            },
            read: {                
                validators: [                    
                    { kind: "email", params: ["Enter a valid email"] }
                ]
            }
        }
    },
    password: {
        sortOrder: 2,
        dataElementUid: "3fd3a796-85f7-4cf7-894f-67d77a7fd3e8",
        onAction: {
            base: { required: ["Required"] },
            update: { omit: true },
            read: { omit: true }
        }
    },
    confirmPassword: {
        sortOrder: 3,
        dataElementUid: "70370903-7a06-4023-9460-8c8db023ac73",
        onAction: {
            base: { required: ["Required"] },
            update: { omit: true },
            read: { omit: true }
        }
    },
    currentPassword: {
        sortOrder: 4,
        dataElementUid: "b07b14c4-fa4b-4aea-865f-718091240f94",
        onAction: {
            base: { required: false },
            create: { omit: true },
            read: { omit: true }
        }
    },
    newPassword: {
        sortOrder: 5,
        dataElementUid: "c1b1b5ef-1b52-40df-9804-854e4145e59a",
        onAction: {
            base: { required: false },
            create: { omit: true },
            read: { omit: true }
        }
    },
    confirmNewPassword: {
        sortOrder: 6,
        dataElementUid: "6717b0f1-04dd-45b5-a37d-817753518400",
        onAction: {
            base: { required: false },
            create: { omit: true },
            read: { omit: true }
        }
    },
    givenName: {
        sortOrder: 7,
        dataElementUid: "8424222a-69ba-40a7-960f-eb1d6a2421ff",
        onAction: {
            base: { required: ["Required"] },
        }
    },
    surname: {
        sortOrder: 8,
        dataElementUid: "be3fa7c6-bf88-49db-9996-75278db1d0f5",
        onAction: {
            base: { required: ["Required"] },
        }
    },
    organization: {
        sortOrder: 9,
        dataElementUid: "e748c420-15de-45e6-b475-88b10a0a7023",
        onAction: {
            base: { required: ["Required"] },
        }
    },
    militaryDuties: {
        sortOrder: 10,
        dataElementUid: "2FCF6D87-FD10-4CB1-94D4-60E80C590EA6",
        onAction: {
            base: { required: ["Required"] },
            read: { from: ["2FCF6D87-FD10-4CB1-94D4-60E80C590EA6"] }
        }
    },
    mosType: {
        sortOrder: 11,
        dataElementUid: "5EB73E3A-DDD3-40B1-966F-040255A7773E",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "2FCF6D87-FD10-4CB1-94D4-60E80C590EA6",
                    operator: ComparisonOperators.eq,
                    value: 198
                },
            },
            read: { from: ["5EB73E3A-DDD3-40B1-966F-040255A7773E"] }
        },
        showUiIndented: 1
    },
    mosEnlisted: {
        sortOrder: 12,
        dataElementUid: "1C46A95C-1463-49C0-BB05-C885C3CC3FB5",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "5EB73E3A-DDD3-40B1-966F-040255A7773E",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            },
            read: { from: ["1C46A95C-1463-49C0-BB05-C885C3CC3FB5"] }
        },
        showUiIndented: 1
    },
    mosWarrant: {
        sortOrder: 13,
        dataElementUid: "841F1263-13EE-44C4-A9C1-1362970B4BB5",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "5EB73E3A-DDD3-40B1-966F-040255A7773E",
                    operator: ComparisonOperators.eq,
                    value: 2
                }
            },
            read: { from: ["841F1263-13EE-44C4-A9C1-1362970B4BB5"] }
        },
        showUiIndented: 1
    },
    mosOfficer: {
        sortOrder: 14,
        dataElementUid: "5A1C9CD0-9C5E-40BA-9909-F99412B72D8B",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "5EB73E3A-DDD3-40B1-966F-040255A7773E",
                    operator: ComparisonOperators.eq,
                    value: 3
                }
            },
            read: { from: ["5A1C9CD0-9C5E-40BA-9909-F99412B72D8B"] }
        },
        showUiIndented: 1

    },
    fulltimeGuardMember: {
        sortOrder: 15,
        dataElementUid: "90727415-F957-4102-977D-544E36DD54EF",
        onAction: {
            base: { required: ["Required"] },
            read: { from: ["90727415-F957-4102-977D-544E36DD54EF"] }
        }
    },
    fulltimeStatusAGRADOS: {
        sortOrder: 16,
        dataElementUid: "D6D70570-DD28-48FB-A68E-6C92D2E99B32",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "90727415-F957-4102-977D-544E36DD54EF",
                    operator: ComparisonOperators.eq,
                    value: 200
                }
            },
            read: { from: ["D6D70570-DD28-48FB-A68E-6C92D2E99B32"] }
        },
        showUiIndented: 1
    },
    agrPosition: {
        sortOrder: 17,
        dataElementUid: "794C726B-94E1-4AE5-9969-1F919D89386A",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "D6D70570-DD28-48FB-A68E-6C92D2E99B32",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            },
            read: { from: ["794C726B-94E1-4AE5-9969-1F919D89386A"] }
        },
        showUiIndented: 1
    },
    adosPosition: {
        sortOrder: 18,
        dataElementUid: "AE98001E-42B1-4530-B639-C8D1FB96840A",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "D6D70570-DD28-48FB-A68E-6C92D2E99B32",
                    operator: ComparisonOperators.eq,
                    value: 2
                }
            },
            read: { from: ["AE98001E-42B1-4530-B639-C8D1FB96840A"] }
        },
        showUiIndented: 1
    },
    stateEmployee: {
        sortOrder: 19,
        dataElementUid: "0750CE68-6AAD-46C3-B954-25ACAA71539A",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "90727415-F957-4102-977D-544E36DD54EF",
                    operator: ComparisonOperators.eq,
                    value: 201
                }
            },
            read: { from: ["0750CE68-6AAD-46C3-B954-25ACAA71539A"] }
        }
    },
    omdState: {
        sortOrder: 20,
        dataElementUid: "915B0207-16C0-4C8B-A41C-D96F1005EFDE",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "0750CE68-6AAD-46C3-B954-25ACAA71539A",
                    operator: ComparisonOperators.eq,
                    value: 202
                }
            },
            read: { from: ["915B0207-16C0-4C8B-A41C-D96F1005EFDE"] }
        },
        showUiIndented: 1
    },
    fulltimeFederalEmployee: {
        sortOrder: 21,
        dataElementUid: "631AB676-6BE3-4128-B777-4AE6413D2147",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "0750CE68-6AAD-46C3-B954-25ACAA71539A",
                    operator: ComparisonOperators.eq,
                    value: 203
                }
            },
            read: { from: ["631AB676-6BE3-4128-B777-4AE6413D2147"] }
        }
    },
    fulltimeStatusGSWG: {
        sortOrder: 22,
        dataElementUid: "9441F0AF-AEE6-4E17-823A-2EAF63F10DC7",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "631AB676-6BE3-4128-B777-4AE6413D2147",
                    operator: ComparisonOperators.eq,
                    value: 204
                }
            },
            read: { from: ["9441F0AF-AEE6-4E17-823A-2EAF63F10DC7"] }
        },
        showUiIndented: 1
    },
    wgPosition: {
        sortOrder: 23,
        dataElementUid: "772F3A83-64A8-40B9-A3CF-5F62710EB4FA",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "9441F0AF-AEE6-4E17-823A-2EAF63F10DC7",
                    operator: ComparisonOperators.eq,
                    value: 3
                }
            },
            read: { from: ["772F3A83-64A8-40B9-A3CF-5F62710EB4FA"] }
        },
        showUiIndented: 1
    },
    gsPosition: {
        sortOrder: 24,
        dataElementUid: "69A1A490-FC90-42E5-B5D8-19D0627DD5E7",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "9441F0AF-AEE6-4E17-823A-2EAF63F10DC7",
                    operator: ComparisonOperators.eq,
                    value: 2
                }
            },
            read: { from: ["69A1A490-FC90-42E5-B5D8-19D0627DD5E7"] }
        },
        showUiIndented: 1
    },
    epoc: {
        sortOrder: 25,
        dataElementUid: "dc024ff8-7046-422f-8628-fcd7b247a72e",
        onAction: {
            base: {
                required: ["Required"],
                overwriteValueWhen: {
                    newValue: 2,
                    when: {
                        dataElementUid: "29e85428-6d03-4d9f-b07e-8255fc462232",
                        operator: ComparisonOperators.eq,
                        value: 1
                    }
                }
            },
            read: { from: ["dc024ff8-7046-422f-8628-fcd7b247a72e"] }
        }
    },
    shop: {
        sortOrder: 26,
        dataElementUid: "f2cc7462-c956-41ad-bab6-1e3bb6c84f43",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "dc024ff8-7046-422f-8628-fcd7b247a72e",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            },
            read: { from: ["f2cc7462-c956-41ad-bab6-1e3bb6c84f43"] }
        },
        showUiIndented: 1
    },
    csmsSectionEpoc: {
        sortOrder: 27,
        dataElementUid: "29e85428-6d03-4d9f-b07e-8255fc462232",
        onAction: {
            base: { required: ["Required"] },
            read: { from: ["29e85428-6d03-4d9f-b07e-8255fc462232"] }
        }
    },
    epocSupervisor: {
        sortOrder: 28,
        dataElementUid: "5744bda3-9db6-4dbd-b2dc-a876b6c46dd4",
        onAction: {
            base: { required: ["Required"] },
            read: { from: ["5744bda3-9db6-4dbd-b2dc-a876b6c46dd4"] }
        }
    },
    handlesHW: {
        sortOrder: 29,
        dataElementUid: "482425c2-1394-4788-ba9a-ef341c8886f3",
        onAction: {
            base: { required: ["Required"] },
            read: { from: ["482425c2-1394-4788-ba9a-ef341c8886f3"] }
        }
    },
    facility: {
        sortOrder: 30,
        dataElementUid: "FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E",
        onAction: {
            base: { required: ["Required"] },
            read: { from: ["FFA9B940-99C4-4A04-BE6E-3F0C5AACEB6E"] }
        }
    }
};