import { DomainField, ComparisonOperators } from "../types";

export const OnlineDocumentReviewFields: DomainField = {
    uid: {
        sortOrder: 0,
        dataElementUid: "67cd1940-678f-4cc3-aff2-2f1938666cca",
        onAction: {
            base: { required: ["Required"] },
            create: { required: false, omit: true }
        }
    },
    courseName: {
        sortOrder: 1,
        dataElementUid: "489eb28a-adc1-482d-8b17-3a8a8abb86b5",
        onAction: {
            base: { required: ["Required"] }
        }
    },
    description: {
        sortOrder: 2,
        dataElementUid: "bdc8884a-7acf-4482-98df-f0adb2cc2853",
        onAction: {
            base: { required: false } // , default: ["Please download and review each document below."]
        },
        dataElementOverrides: {
            all: {
                helpText: ["Describe the course and direct the student to review each document (ex: \"Please download and review each document.\")"]
            }
        }
    },
    color: {
        sortOrder: 3,
        dataElementUid: "e7164789-3792-4f29-8a26-418a27512719",
        onAction: {
            base: { required: ["Required"] }
        }
    },
    userGroups: {
        sortOrder: 4,
        dataElementUid: "3ac22684-e65e-417d-aa96-b8f82ce58149",
        onAction: {
            base: { required: ["Required"] }
        },
        dataElementOverrides: {
            "all": {
                formElementOptions: {
                    areaName: "Admin",
                    moduleName: "Courses",
                    actionKey: "ManageODR"
                }
            }
        }
    },
    crg: {
        sortOrder: 4,
        dataElementUid: "044a3d56-4ea8-4c0f-92d2-fbeef536544f",
        onAction: {
            base: { required: ["Required"] }
        }
    },
    active: {
        sortOrder: 5,
        dataElementUid: "174ffbae-a090-4b9b-8463-0434cd99809b",
        onAction: {
            base: { required: ["Required"] }
        }
    },
    trackingMethod: {
        sortOrder: 10,
        dataElementUid: "52b7461a-4176-4ef7-a66d-69fbc49e0c0f",
        onAction: {
            base: { required: ["Required"] }
        }
    },
    certificateYN: {
        sortOrder: 11,
        dataElementUid: "785fc9b3-0030-43d4-ba31-68aaf89f6505",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "52b7461a-4176-4ef7-a66d-69fbc49e0c0f",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            }
        },
        showUiIndented: 1
    },
    certificateCourseName: {
        sortOrder: 12,
        dataElementUid: "2cd7b7e4-e1ae-4d18-bb74-a8ff9a9e688c",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "785fc9b3-0030-43d4-ba31-68aaf89f6505",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            }
        },
        showUiIndented: 2
    },
    expires: {
        sortOrder: 13,
        dataElementUid: "a7868363-73f1-405e-bf13-860687ad9f26",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "52b7461a-4176-4ef7-a66d-69fbc49e0c0f",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            }
        },
        showUiIndented: 1
    },
    expiryMo: {
        sortOrder: 14,
        dataElementUid: "1b403e52-6e3d-4059-a371-7976218c8504",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "a7868363-73f1-405e-bf13-860687ad9f26",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            }
        },
        showUiIndented: 2
    },
    docs: {
        sortOrder: 20,
        dataElementUid: "33153202-a53b-4b83-a40e-3e0e5cb9a375",
        onAction: {
            base: {
                required: false
            }
        }
    }
};