import { AppBar, InputAdornment, TextField, Toolbar } from "@mui/material";
import { SearchIcon } from "features/courses/CoursesIcons";
import { DomainRecordKey, DomainSchema } from "features/domainModel";
import DMColumnChooser from "./DMColumnChooser";


interface DMTopBarProps<T extends DomainSchema> {
    chosenFields: Array<DomainRecordKey<T>>;
    allFieldLabels: Record<DomainRecordKey<T>, string>;
    onChosenFieldsChange: (fields: Array<DomainRecordKey<T>>) => void;
    onSearchChange: (value: string) => void;
}

export default function DMTopBar(props: DMTopBarProps<DomainSchema>) {

    return (
        // TODO add the backgroundColor for the Admin Grid AppBar to the Theme
        <AppBar position="static" variant="outlined" style={{ backgroundColor: "#DDE1E4" }}>
            <Toolbar style={{ flexGrow: 1 }} variant="dense">
                <DMColumnChooser
                    chosenFields={props.chosenFields}
                    allFieldLabels={props.allFieldLabels}
                    onChange={props.onChosenFieldsChange}
                />
                <span style={{ flexGrow: 1 }} />
                <TextField
                    placeholder="Search"
                    type="search"
                    size="small"
                    
                    onChange={(event) => props.onSearchChange(event.target.value)}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <SearchIcon />
                            </InputAdornment>
                        )
                    }}
                />
            </Toolbar>
        </AppBar>
    );
}