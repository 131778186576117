import { API_URL } from "../../app/runtimeConstants";
import { CourseRequirementGroup } from "../courses/courseRequirementGroups";
import { Course } from "../courses/data/course";
import { ApiJsonDatum, ApiEndpoint } from "./api";

export const CoursesApiEndpoints = {
    GetAllCourses: {
        url: `${API_URL}/data/schemaname/course/current?a=ViewCourse`,
        authorizationRequired: true,
        method: "GET",
        requestBody: undefined,
        okResponseMapper: async (response) => {
            return (await response.json() as ApiJsonDatum[]).map(datum => {
                const data = JSON.parse(datum.data) as Omit<Course, "uid">;

                //TODO: standardize on upper or lower cased uids
                const mapped = Object.assign({ uid: datum.uid }, data);
                return mapped;
            });
        }
    } as ApiEndpoint<undefined, Course[]>,
    GetCourseRequirementGroups: {
        url: `${API_URL}/data/schemaname/courseRequirementGroup/current?a=ViewCourse`,
        authorizationRequired: true,
        method: "GET",
        requestBody: undefined,
        okResponseMapper: async (response) => {
            return (await response.json() as ApiJsonDatum[]).map(datum => {
                const data = JSON.parse(datum.data) as Omit<CourseRequirementGroup, "uid">;

                //TODO: standardize on upper or lower cased uids
                const mapped = Object.assign({ uid: datum.uid }, data);
                return mapped;
            });
        }
    } as ApiEndpoint<undefined, CourseRequirementGroup[]>
};
export const HomeEndpoints = {
    GetHomeMarkdown: {
        url: `${API_URL}/data/schemaName/homemd/current?a=ViewHome`,
        authorizationRequired: true,
        method: "GET",
        okResponseMapper: async (response) => {
            const datum: ApiJsonDatum[] = await response.json();
            return JSON.parse(datum[0].data).md;
        }
    } as ApiEndpoint<undefined, string>
};