import { API_URL } from "app/runtimeConstants";
import * as yup from "yup";
import { FieldCondition, DomainField, DomainFieldSchema, DataElement, StringValidator, NumberValidator, ComparisonOperators, DomainSchema, DomainRecordAction, ActionValidator, DataElementType } from "../";

type OnActionValidations = Partial<ActionValidator> & { from?: [fromKey: string]; }; // same as DomainFieldSchema["onAction"]["read"]
type ActionType = "create" | "read" | "update" | "readOptional"; // same as DomainRecordAction | "readOptional"
type SchemaType = "string" | "number" | "array" | "boolean" | "object";

export default function buildValidatorSchema(ds: DomainSchema) {
    // check that the domainSchema version is supported
    const minVersion = 0;
    const maxVersion = 1;
    if (ds.version > maxVersion || ds.version < minVersion) {
        throw new Error(`Version '${ds.version}' for Domain Schema '${ds.name} (${ds.uid})' is not compatible.`);
    }

    //: Record<DomainRecordAction, yup.Schema>
    const schemas = Object.getOwnPropertyNames(ds.fields).reduce(
        (schemas, fieldName) => {
            const dfs = ds.fields[fieldName];
            //iterate through the actions 
            Object.getOwnPropertyNames(schemas).forEach((action: string) => {
                const de = getDataElement(dfs.dataElementUid, ds.dataElements, { action: action as DomainRecordAction, dataElementOverrides: dfs.dataElementOverrides });
                let oav = getOnActionValidations(
                    dfs["onAction"],
                    action === "readOptional" ? "read" : action as DomainRecordAction,
                    action === "readOptional" ? { required: false } : undefined);
                if (!oav.omit) {
                    //this field is not omitted from the schema for action
                    schemas[action as ActionType] = schemas[action as ActionType].concat(buildValidatorSchemaForField(fieldName, ds.fields, de, oav, action === "readOptional"));
                }
            });
            return schemas;
        },
        {
            create: yup.object(),
            update: yup.object(),
            read: yup.object(),
            readOptional: yup.object()
        }
    );

    return schemas;
}

function buildValidatorSchemaForField(fieldName: string, allFields: DomainField, de: DataElementType, oav: OnActionValidations, readOptional: boolean = false) {
    const { required, onlyShowWhen, from } = oav;
    const isMultiselect = de.formElementOptions && de.formElementOptions.multiselect ? true : false;
    const schemaType = getSchemaType(de.formElement, isMultiselect);
    const schemaDefaultValue = getDefaultValue(schemaType, oav);

    let validator;
    switch (schemaType) {
        case "boolean":
            validator = yup.boolean().default(schemaDefaultValue);
            if (required) validator = validator.required(...required);
            break;
        case "string":
            validator = yup.string().default(schemaDefaultValue);
            if (required) validator = validator.required(...required);
            if (de.formElement === "text" || de.formElement === "hidden") {
                if (de.validators && Array.isArray(de.validators)) {
                    validator = applyDataElementStringValidators(validator, allFields, (de.validators as StringValidator[]));
                }
            } else if (de.formElement === "color") {

            } else if (de.formElement === "markdown") {

            } else if (de.formElement === "course") {
                // url
            }            
            else {
                throw new Error(`Schema of type '${schemaType}' for DataElementUid '${de.uid}' has a FormElementType of '${de.formElement}' but 'text' | 'hidden' was expected`);
            }

            break;
        case "number":
            validator = yup.number().default(schemaDefaultValue);
            if (required) validator = validator.required(...required);
            if (de.formElement === "number") {
                if (de.validators && Array.isArray(de.validators)) {
                    validator = applyDataElementNumberValidators(validator, allFields, (de as DataElement<"number">).validators);
                }
            }
            break;
        case "array":
            validator = yup.array().default(schemaDefaultValue).ensure();
            if (required) validator = validator.min(1, ...required);
            if (de.formElement === "user") {
                validator = validator.of(yup.object({
                    uid: yup.string().required(), // .uuid()
                    email: yup.string().email().required(),
                    givenName: yup.string().required(),
                    surname: yup.string().required()
                }));
            }
            break;
        // case "object":
        //     validator = yup.object().default(schemaDefaultValue);
        //     if (required) validator = validator.required(...required);
        //     break;
        default: throw new Error(`Schema of type '${schemaType}' for DataElementUid '${de.uid}': FormElementType of '${de.formElement} is not implemented.'`);
    }
    if (!readOptional) {
        validator = applyOnlyShowWhenFieldCondition(validator, allFields, onlyShowWhen);
    }
    //apply transform after applying onlyShowWhen instead of on the base validator for the number schema type
    validator = validator.transform(
        (value: any, raw: any) =>
            raw === '' && typeof value === "number" && !Number.isFinite(value) ? undefined : value);

    let objValidator = yup.object({ [fieldName]: validator });
    if (from) {
        objValidator = objValidator.from(...from, fieldName);
    }
    return objValidator;
}

function applyOnlyShowWhenFieldCondition(base: yup.Schema, fields: DomainField, onlyShowWhen?: FieldCondition) {
    if (onlyShowWhen) {
        if (onlyShowWhen.operator === ComparisonOperators.eq && onlyShowWhen.value) {
            const depFieldName = getFieldName(onlyShowWhen.dataElementUid, fields);
            const validatorForWhenIs = base.clone();
            //return yup.mixed().when(depFieldName, { is: onlyShowWhen.value, then: (schema) => validatorForWhenIs });
            const optionalBase = base.clone().optional().notRequired();
            return yup.mixed().optional().notRequired().when(depFieldName, {
                is: (val: any) =>
                    // eslint-disable-next-line eqeqeq
                    val == onlyShowWhen.value,
                then: () =>
                    validatorForWhenIs
            });
        } else {
            throw new Error("FieldConditions and FieldConditions[] for onlyShowWhen are not implemented. Only the eq comparison operator is implemented.");
        }
    }
    return base;
}

function applyDataElementStringValidators(base: yup.StringSchema, fields: DomainField, validators?: Array<StringValidator>): yup.StringSchema {
    let s = base.clone();
    if (validators) {
        validators.forEach((v) => {
            if (v.kind === "min") s = s[v.kind](...v.params);
            if (v.kind === "max") s = s[v.kind](...v.params);
            if (v.kind === "email") s = s[v.kind](...v.params);
            if (v.kind === "url") s = s[v.kind](...v.params);
            // if (v.kind === "uuid") s = s[v.kind](...v.params); // TODO figure out why this validator doesn't always like .NET CORE generated UUID/GUID's
            if (v.kind === "matches") s = s[v.kind](new RegExp(v.params[0]), v.params[1]);
            if (v.kind === "oneOf") s = s[v.kind](...getOneOfArgs(fields, v.params));
            if (v.kind === "dateInPast") {
                s = s.test("dateInPast", v.params[0], (value) => Boolean(value && new Date() >= new Date(value)));
            }
            if (v.kind === "emailAvailable") {
                // TODO: should this validator be handled differently? the fetch call and url construction seem odd
                s = s.test("emailAvailable", v.params[0], async (value) => {
                    if (!value) return false;

                    // eslint-disable-next-line no-template-curly-in-string
                    const url = API_URL + v.params[1].replace("${email}", encodeURIComponent(value));
                    const val = await (await fetch(url)).text();
                    if (val.includes("true")) return true;
                    if (val.includes("false")) return false;

                    throw new Error(`Unexpected email availability lookup result. Expected true/false, got: ${val}`);
                });
            }
        });
    }
    return s;
}

function applyDataElementNumberValidators(base: yup.NumberSchema, fields: DomainField, validators?: Array<NumberValidator>) {
    let s = base.clone();
    if (validators) {
        validators.forEach((v) => {
            if (v.kind === "min") s = s[v.kind](...v.params);
            if (v.kind === "max") s = s[v.kind](...v.params);
            if (v.kind === "lessThan") s = s[v.kind](...v.params);
            if (v.kind === "moreThan") s = s[v.kind](...v.params);
            if (v.kind === "positive") s = s[v.kind](...v.params);
            if (v.kind === "integer") s = s[v.kind](...v.params);
            if (v.kind === "oneOf") s = s[v.kind](...getOneOfArgs(fields, v.params));
        });
    }
    return s;
}

function getOneOfArgs(fields: DomainField, args: [message: string, values?: Array<any>, dataElementUid?: Array<string>]): [arrayOfValues: Array<any>, message: string] {
    const [message, values, dataElementUidRef] = args;
    const refs = dataElementUidRef ? dataElementUidRef.map(uid => yup.ref(getFieldName(uid, fields))) : [];
    const vals = values || [];
    return [refs.concat(vals), message];
}

function getFieldName(dataElementUid: string, fields: DomainField) {
    let fieldName = "";
    Object.getOwnPropertyNames(fields).forEach((key) => {
        const dfs = fields[key];
        if (dfs.dataElementUid.toLowerCase() === dataElementUid.toLowerCase())
            fieldName = key;
    });
    if (fieldName) {
        return fieldName;
    }
    throw new Error(`Unable to locate DomainField name for DataElement '${dataElementUid}'`);
}

function getDefaultValue(schemaType: SchemaType, oav: OnActionValidations) {
    // may not need action to determine default value anymore

    const { required, default: defaultValue, onlyShowWhen, from } = oav;

    if (defaultValue &&
        !(typeof defaultValue[0] === "string"
            || typeof defaultValue[0] === "number"
            || typeof defaultValue[0] === "boolean"))
        throw new Error(`Support for default value with type '${typeof defaultValue[0]}' not implemented`);

    if (schemaType === "array") {
        if (defaultValue) {
            return [...defaultValue];
        }
        return undefined; // [] - .ensure() will set it to empty array
    }

    if (schemaType === "object") {
        return {};
    }

    if (schemaType === "boolean")
        return defaultValue ? defaultValue[0] : false;
    if (schemaType === "string")
        return defaultValue ? defaultValue[0] : ""; // ""
    if (schemaType === "number")
        return defaultValue ? defaultValue[0] : undefined; // "" | null | undefined ?

    throw new Error(`SchemaType of '${schemaType}' not implemented`);
}

function getSchemaType(fet: DataElementType["formElement"], isMultiselect: boolean): SchemaType {
    let schemaType: SchemaType;
    switch (fet) {
        case "text": case "hidden": case "color": case "course": schemaType = "string"; break;
        case "tree": case "number": schemaType = "number"; break;
        case "user": case "doc": case "userGroup": schemaType = "array"; break;
        case "choice": schemaType = isMultiselect ? "array" : "number"; break;
        case "toggle": schemaType = "boolean"; break;
        case "markdown": schemaType = "string"; break;
        // case "course": schemaType = "object"; break;
        default: throw new Error(`FormElementType '${fet}' not implemented`);
    }
    if (isMultiselect && schemaType !== "array") {
        throw new Error(`Multiselect for FormElementType '${fet}' not implementd`);
    }
    return schemaType;
}

function getOnActionValidations(onAction: DomainFieldSchema["onAction"], which: DomainRecordAction, overrides?: Partial<DomainFieldSchema["onAction"]["create"] | DomainFieldSchema["onAction"]["update"] | DomainFieldSchema["onAction"]["read"]>): OnActionValidations {
    return { ...onAction.base, ...onAction[which], ...overrides };
}

function getDataElement<T extends DataElementType>(dataElementUid: string, dataElements: T[], overrides?: { action: DomainRecordAction, dataElementOverrides: DomainFieldSchema["dataElementOverrides"]; }): T {
    const de = dataElements.find(f => f.uid.toLowerCase() === dataElementUid.toLowerCase());
    if (de) {
        return { ...de, ...overrides?.dataElementOverrides?.all, ...overrides?.dataElementOverrides?.[overrides?.action] };
    }
    throw new Error(`Unable to locate DataElement with uid '${dataElementUid}'`);
}