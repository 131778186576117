import { Box } from "@mui/system";
import { useAppSelector } from "app/hooks";
import { API_URL } from "app/runtimeConstants";
import React from 'react';
export default function ReportsPage() {
    const userId = useAppSelector(state => state.auth.user?.uid);
    const reportsUrl = `${API_URL}/index.html?u=${userId}`;

    return (
        <Box>
            <iframe style={{width: "100%", border: "none", height: "calc(100vh - 200px)"}} title="Reports" src={reportsUrl} />
        </Box>
    )
}