import { ListItem, ListItemAvatar, Avatar, ListItemText, ListItemSecondaryAction, IconButton, List, Typography, InputLabel, FormControl, Button, TextField, Link, Badge } from "@mui/material";
import { userManager } from "features/auth";
import { ApiFetchOLD } from "features/common/api";
import { BinaryDatumEndpoints } from "features/common/elevatedApiEndpoints";
import { FMuiProps, FMuiDocProps, getFMuiFieldErrorState } from "./internal";
import AttachFileIcon from '@mui/icons-material/AttachFile';
import DeleteIcon from '@mui/icons-material/Delete';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import React from "react";
import { useField } from "formik";
import DoneIcon from '@mui/icons-material/Done';
import { BYTE_LIMIT } from "app/runtimeConstants";

type DocReviewUrls = {
    description?: string;
    name: string;
    uid?: string;
    type?: string;
    url: string;
};

export function getDocList(urls: DocReviewUrls[], removeDoc?: (doc: DocReviewUrls) => void, onDownloadClick?: (uid?: string) => void, showIfDownloaded?: string[]) {
    const items =
        urls.length === 0
            ? <ListItem key={0}>
                <ListItemAvatar>
                    <Avatar>
                        <AttachFileIcon />
                    </Avatar>
                </ListItemAvatar>
                <ListItemText primary={"No documents have been uploaded"} />
            </ListItem>
            : urls.map((doc, idx) =>
                <ListItem key={doc.uid || idx}>
                    <ListItemAvatar>
                        <Badge
                            overlap="circular"
                            anchorOrigin={{
                                vertical: "bottom",
                                horizontal: "right"
                            }}
                            badgeContent={
                                showIfDownloaded && doc.uid
                                    ? showIfDownloaded.includes(doc.uid) ? <DoneIcon htmlColor="green" /> : null
                                    : null
                            }
                        >
                            <Avatar>
                                <Link href={doc.url} download target="_blank" onClick={onDownloadClick ? () => onDownloadClick(doc.uid) : undefined}>
                                    <AttachFileIcon />
                                </Link>
                            </Avatar>
                        </Badge>
                    </ListItemAvatar>
                    <Link href={doc.url} download target="_blank" onClick={onDownloadClick ? () => onDownloadClick(doc.uid) : undefined}>
                        <ListItemText primary={doc.name} secondary={doc.description} />
                    </Link>

                    {removeDoc !== undefined &&
                        <ListItemSecondaryAction>
                            <IconButton
                                edge="end"
                                aria-label="delete"
                                onClick={() => removeDoc(doc)}
                                size="large">
                                <DeleteIcon />
                            </IconButton>
                        </ListItemSecondaryAction>}
                </ListItem >);

    return (
        <List>{items}</List>
    );
};


export default function FMuiDocField(props: FMuiProps<FMuiDocProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    // const [docReviewUrls, setDocReviewUrls] = React.useState<DocReviewUrls[]>([]);
    const [newDocName, setNewDocName] = React.useState("");
    const [newDocDesc, setNewDocDesc] = React.useState("");

    const [error, setError] = React.useState("");

    const docReviewUrls: DocReviewUrls[] = field.value || [];
    const setDocReviewUrls = (d: DocReviewUrls[]) => helpers.setValue(d);


    const removeDoc = (doc: DocReviewUrls) => {
        setDocReviewUrls(docReviewUrls.filter(d => d.uid !== doc.uid));
    };
    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <Typography>Type in a Name and Description then Click "Upload File" to select and upload a file.</Typography>
            <FormControl>
                <InputLabel></InputLabel>
            </FormControl>
            <FormControl>
                <TextField
                    label="Name"
                    value={newDocName}
                    onChange={(event) => setNewDocName(event.target.value)}
                    fullWidth
                />
            </FormControl>
            <FormControl>
                <TextField
                    label="Description"
                    value={newDocDesc}
                    onChange={(event) => setNewDocDesc(event.target.value)}
                    fullWidth
                />
            </FormControl>
            <FormControl>
                <Button
                    variant="contained"
                    component="label"
                    color="primary"
                    startIcon={<CloudUploadIcon />}
                    disabled={!Boolean(newDocName)}
                >
                    Upload File
                    <input
                        type="file"
                        id="file-upload"
                        name="file-upload"
                        hidden
                        onChange={(event) => {
                            setError("");
                            const file = event.target.files ? event.target.files[0] : null;
                            if (file) {
                                if (file.size > BYTE_LIMIT) {
                                    setError(`'${file.name}' is too large. Must be ${BYTE_LIMIT.toLocaleString()} bytes or less.`);
                                    event.target.value = "";
                                } else {


                                    var fd = new FormData();
                                    fd.append("file", file);
                                    fd.append("name", newDocName);
                                    fd.append("description", newDocDesc);
                                    userManager.getUser().then(user => {
                                        ApiFetchOLD(BinaryDatumEndpoints.upload(fd), fd, user?.access_token).then(result => {
                                            // store uid to associate with course when its uploaded
                                            setDocReviewUrls([...docReviewUrls, { uid: result.uid, name: result.name, description: result.description, type: result.mimeType, url: result.url }]);
                                            setNewDocDesc("");
                                            setNewDocName("");
                                            event.target.value = "";
                                        });
                                    });
                                }
                            }
                        }}
                    />
                </Button>
                {error && <Typography color="error">{error}</Typography>}
            </FormControl>
            <FormControl>
            </FormControl>
            <FormControl>
                {getDocList(docReviewUrls, removeDoc)}
            </FormControl>

        </FormControl>
    );
}