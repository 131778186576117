import { Dialog, DialogContent, DialogTitle } from "@mui/material";
// import { AppTheme } from "components/Theme";
import { doRegister } from "features/auth/authAPI";
import { BasicUserInfo } from "features/common/elevatedApiEndpoints";
import { DMForm, DMFormProps } from "features/dmForms";
import { DomainRecordValid, DomainRecordValidator, DomainRecordValues, QuickUserDomainConfig, QuickUserDomainSchema, UserProfileDomainConfig, UserProfileDomainSchema } from "features/domainModel";
import React from "react";
import Snackbar from '@mui/material/Snackbar';
import MuiAlert, { AlertProps } from '@mui/material/Alert';

function Alert(props: AlertProps) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export interface CreateQuickUserDialogProps {
    onUserCancel: () => void;
    onCreatedQuickUser: (newUser: DomainRecordValues<QuickUserDomainSchema>) => void;
    open: boolean;
}
export default function CreateQuickUserDialog(props: CreateQuickUserDialogProps) {
    const [submissionError, setSubmissionError] = React.useState(false);

    const schema = QuickUserDomainConfig.schema;
    const drv = new DomainRecordValidator(schema);
    const drvUp = new DomainRecordValidator(UserProfileDomainConfig.schema);

    const { initialValues, validationSchema } = drv.getPropsForFormik("create");
    // const muiClasses = AppTheme.useMuiStyles();


    const dmeProps: DMFormProps<typeof schema, "create"> = {
        domainSchema: schema,
        action: "create",
        onUserCancel: props.onUserCancel,
        areInitialValuesValid: false,
        forFormik: {
            initialValues: initialValues,
            validationSchema: validationSchema,
            onSubmit: (values, formikHelpers) => {
                const { setSubmitting } = formikHelpers;
                setSubmitting(true);

                drv.validate(values, "create")
                    .map(valid => {
                        // TODO authApi needs its logic broken up so it can be used here
                        // TODO create an api endpoint for creating quick user to better handle password
                        // create a fake valid user profile
                        delete valid.value.welcomeEmail;

                        const up = drvUp.getDefaultValues("create");
                        const vUp: DomainRecordValid<UserProfileDomainSchema, "create"> = {
                            kind: "valid",
                            isValid: true,
                            value: { ...up.value, ...valid.value, password: "QuickUserTemp!123", confirmPassword:"QuickUserTemp!123", organization: 165 },
                            schema: UserProfileDomainConfig.id,
                            action: "create"
                        };

                        return doRegister(vUp, false)
                            .then(value => {
                                setSubmitting(false);
                                setSubmissionError(false);
                                props.onCreatedQuickUser({
                                    uid: value.uid,
                                    email: value.email,
                                    surname: value.profile?.value?.["surname"] || "",
                                    givenName: value.profile?.value?.["givenName"] || ""
                                });

                            }).catch(err => {
                                setSubmitting(false);
                                setSubmissionError(true);
                            });
                    })
                    .mapErr(notValid => {
                        //shouldnt happen since formik already validated
                        setSubmitting(false);
                        setSubmissionError(true);
                    })
                    .resolve();
            }
        }
    };

    return (
        <Dialog open={props.open}>
            {/* <DialogTitle>Create Student</DialogTitle> */}
            <DialogContent>
                {/* className={`${muiClasses.root}`} */}
                <div style={{ minWidth: "400px" }}>
                    <DMForm {...dmeProps} />
                </div>
            </DialogContent>
            <Snackbar
                open={submissionError}
                onClose={() => setSubmissionError(false)}
            >
                <>
                    <Alert severity="error" onClose={() => setSubmissionError(false)}>An unknown error was encountered. Please try again later.</Alert>
                </>
            </Snackbar>
        </Dialog>
    );
}