import * as yup from "yup";
import type { DomainSchema, DomainRecordAction, DomainRecord, DomainRecordDefaults, DomainRecordError, DomainRecordValid, DomainRecordValues } from "../types";
import { AsyncResultWrapper, Ok, Err } from "../asyncApi";
import buildValidatorSchema from "./buildValidatorSchema";

export class DomainRecordValidator<T extends DomainSchema> {
    readonly schema:DomainRecord<T,DomainRecordAction>["schema"];
    readonly #ds: DomainSchema;
    readonly #schemas: ReturnType<typeof buildValidatorSchema>;

    constructor(domainSchema: T) {
        this.#ds = domainSchema;
        this.#schemas = buildValidatorSchema(domainSchema);
        this.schema = [this.#ds.name, this.#ds.uid, this.#ds.version]
    }

    getPropsForFormik(action: DomainRecordAction, record?: any) {        
        return {
            initialValues: { ...this.#schemas[action].getDefault(), ...record },
            validationSchema: this.#schemas[action]
        };
    }

    getDefaultValues<A extends DomainRecordAction>(action: A): DomainRecordDefaults<T,A> {
        return {
            kind: "defaults",
            schema: this.schema,
            action: action,
            isValid: false,
            value: this.#schemas[action].getDefault() as DomainRecordValues<T>
        };
    }

    validate<A extends DomainRecordAction>(record: any, action: A) {
        
        const schemaDefaults = this.#schemas[action].getDefault() as Record<keyof T["fields"], any> | undefined;

        const arw = new AsyncResultWrapper(async () => {
            try {
                const validateResult = await this.#schemas[action].validate(record, {
                    abortEarly: false,
                    strict: false,
                    stripUnknown: true
                }) as DomainRecordValues<T>;
                return new Ok<DomainRecordValid<T,A>>({
                    kind: "valid",
                    schema: this.schema,
                    action: action,
                    value: { ...schemaDefaults, ...validateResult },
                    isValid: true
                });
            } catch (error: unknown) {
                let castResult: DomainRecordValues<T> | undefined = undefined;
                try {
                    castResult = this.#schemas[action === "read" ? "readOptional" : action].cast(record, { stripUnknown: true }) as DomainRecordValues<T>;
                } catch (error) {
                    console.warn(`Unable to cast record for domainSchema: '${this.#ds.name}', action: '${action}'. The error was handled.`, error);
                }
                return new Err<DomainRecordError<T,A>>({
                    kind: "error",
                    schema: this.schema,
                    action: action,
                    value: schemaDefaults && castResult ? { ...schemaDefaults, ...castResult } : undefined,
                    isValid: false,
                    wasCast: castResult !== undefined,
                    stack: hasStack(error) ? error.stack : new Error().stack,
                    errors: error instanceof yup.ValidationError
                        ? error.inner.reduce(
                            (acc: any, { path, message }) => ({
                                ...acc,
                                [path || "root"]: (acc[path || "root"] || []).concat(message)
                            }), {})
                        : error
                });
            }
        });
        return arw;
    }
}

function hasStack(e: unknown): e is { stack: string; } {
    return typeof e === "object" && e !== null && "stack" in e && typeof e === "string";
}