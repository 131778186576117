import { API_URL } from "app/runtimeConstants";
import { ApiJsonDatum } from "features/common/api";
import { CourseRequirementGroupEndPoints, JsonDatumEndpoints } from "features/common/elevatedApiEndpoints";
import { Course } from "features/courses/data/course";
import { DomainRecordValidator } from "..";
import { ApiFetch } from "../asyncApi";
import { DomainConfig, DomainRecordAction, DomainRecordError, DomainRecordValid, DomainRecordValues, DomainSchema } from "../types";
import { crgUidToId, crgIdToUid } from "./domainConfigOnlineDocumentReview";
import { onlineSelfPacedModuleDomainSchema } from "./domainSchemaOnlineSelfPacedModule";

export interface OnlineSelfPacedModuleDomainSchema extends DomainSchema { };

export const OnlineSelfPacedModuleDomainConfig: DomainConfig<OnlineSelfPacedModuleDomainSchema> = {
    id: [onlineSelfPacedModuleDomainSchema.name, onlineSelfPacedModuleDomainSchema.uid, onlineSelfPacedModuleDomainSchema.version],
    schema: onlineSelfPacedModuleDomainSchema as OnlineSelfPacedModuleDomainSchema
};


async function mapOnlineSelfPacedModule<T extends DomainRecordAction>(values: DomainRecordValues<OnlineSelfPacedModuleDomainSchema>, action: T)
    : Promise<{ course: Omit<Course, "prereqsSatisfied">, crg: string, targetGroupUids: string[]; }> {

    const drv = new DomainRecordValidator(OnlineSelfPacedModuleDomainConfig.schema);
    const result = await drv.validate(values, action)
        .map((v: any) => v)
        .mapErr((v: any) => v)
        .resolve();
    if (result.err) {
        throw new Error(`Recieved invalid values for mapping: ${result.val.errors}`);
    }
    const v = result.val.value;

    // CRG/UID/ID workaround (because of fmuiselectfield)
    let crg = String(v["crg"]).includes("-") ? v["crg"] : crgIdToUid(Number(v["crg"]));

    let course: Omit<Course, "prereqsSatisfied"> = {
        active: v["active"] === 1 ? true : false,
        certificate: v["certificateYN"] === 1 ? { name: v["certificateCourseName"] } : false,
        details: {
            kind: "OnlineSelfPacedModule",
            allowReview: true,
            target: {
                externalClose: true,
                height: 0,
                width: 0
            },
            trackingMethod: "SCORM",
            url: v["url"]
        },
        header: {
            backgroundColor: v["color"],
            imgUrl: undefined
        },
        description: v["description"],
        name: v["courseName"],
        uid: v["uid"],
        timeToExpiry: v["expiryMo"] && Number(v["expiryMo"]) > 0 ? { unit: "months", value: Number(v["expiryMo"]) } : undefined
    };

    return {
        course: course,
        crg: crg,
        targetGroupUids: v["userGroups"]
    };
}
export function unmapOnlineSelfPacedModule(c: Omit<Course, "prereqsSatisfied">): Omit<DomainRecordValues<OnlineSelfPacedModuleDomainSchema>, "crg"> {
    if (c.details.kind !== "OnlineSelfPacedModule") {
        throw Error(`Expected OnlineSelfPacedModule, got ${c.details.kind}`);
    }

    return {
        uid: c.uid,
        url: c.details.url,
        courseName: c.name,
        description: c.description,
        active: c.active ? 1 : 2,
        certificateYN: typeof c.certificate === "object" ? 1 : 2,
        certificateCourseName: typeof c.certificate === "object" ? c.certificate.name : "",
        expires: typeof c.timeToExpiry === "object" && c.timeToExpiry.value > 0 ? 1 : 2,
        expiryMo: typeof c.timeToExpiry === "object" && c.timeToExpiry.value > 0 ? c.timeToExpiry.value : undefined, // assumes unit is months
        color: c.header.backgroundColor
    };
}

export async function saveOnlineSelfPacedModule<T extends Exclude<DomainRecordAction, "read">>
    (values: DomainRecordValues<OnlineSelfPacedModuleDomainSchema>, action: T, userUid: string, token: string)
    : Promise<DomainRecordValid<OnlineSelfPacedModuleDomainSchema, "read"> | DomainRecordError<OnlineSelfPacedModuleDomainSchema, T | "read">> {

    const mapped = await mapOnlineSelfPacedModule(values, action);
    if (mapped.course.details.kind !== "OnlineSelfPacedModule") {
        throw Error(`Expected OnlineSelfPacedModule, got ${mapped.course.details.kind}`);
    }

    const uid = action === "create" ? null : mapped.course.uid;
    const payload: Partial<Course> = { ...mapped.course };
    delete payload.uid;

    const datum: ApiJsonDatum = {
        uid: uid,
        schemaName: "course",
        userId: userUid,
        created: null,
        active: true,
        data: JSON.stringify(payload)
    };

    // Save JsonDatum for Course
    const config = JsonDatumEndpoints.post(datum, "course");
    const fetchResult = (await ApiFetch<ApiJsonDatum>(config.url, token, config.requestBody).resolve())
        .map((v: any) => v)
        .mapErr((err: any) => {
            return {
                kind: "error",
                schema: OnlineSelfPacedModuleDomainConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<OnlineSelfPacedModuleDomainSchema, typeof action>;
        });

    if (fetchResult.err) return fetchResult.val;

    const fCourseUid = String(fetchResult.val.uid);

    // Add JsonDatum to ActionObjects
    // TODO add a config endpoint for this method and clean up the entire fetch API thingies
    const targetGroupUids = mapped.targetGroupUids;
    targetGroupUids.forEach(async group => {
        const aoResult = (await ApiFetch<undefined>(`${API_URL}/data/admin/actionobject/${group}/${fCourseUid}/ViewCourse`, token).resolve())
            .map((v: any) => v)
            .mapErr((err: any) => {
                return {
                    kind: "error",
                    schema: OnlineSelfPacedModuleDomainConfig.id,
                    action: action,
                    value: undefined,
                    isValid: false,
                    wasCast: false,
                    errors: err,
                    stack: new Error().stack
                } as DomainRecordError<OnlineSelfPacedModuleDomainSchema, typeof action>;
            });
        if (aoResult.err) return aoResult.val;
    });


    // Save to CRG
    const crgUid = mapped.crg;
    const crgConfig = CourseRequirementGroupEndPoints.addCourseToCrg(crgUid, fCourseUid);
    const crgResult = (await ApiFetch<string>(crgConfig.url, token).resolve())
        .map((v: any) => v)
        .mapErr((err: any) => {
            return {
                kind: "error",
                schema: OnlineSelfPacedModuleDomainConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<OnlineSelfPacedModuleDomainSchema, typeof action>;
        });

    if (crgResult.err) return crgResult.val;

    // TODO Rework add/remove from CRG's as this will only work when Shop Training Materials and Annual Environmental Awareness Training are the only admin selectable groups
    const removeCrgUid = crgUid.toUpperCase() === "C9613306-7794-47B5-B6F9-55C0C4E774E2" ? "F294CA68-5B9D-41A0-A499-9A10053F4A95" : "C9613306-7794-47B5-B6F9-55C0C4E774E2";
    const removeCrgConfig = CourseRequirementGroupEndPoints.removeCourseFromCrg(removeCrgUid, fCourseUid);
    const removeCrgResult = (await ApiFetch<string>(removeCrgConfig.url, token).resolve())
        .map((v: any) => v)
        .mapErr((err: any) => {
            return {
                kind: "error",
                schema: OnlineSelfPacedModuleDomainConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<OnlineSelfPacedModuleDomainSchema, typeof action>;
        });

    if (removeCrgResult.err) return removeCrgResult.val;
    // TODO get crgResult.val for returned CRG even though it should match crgUid already

    const fetchedCourse = { ...unmapOnlineSelfPacedModule(JSON.parse(fetchResult.val.data)), uid: fetchResult.val.uid, crg: crgUidToId(crgUid), userGroups: targetGroupUids };

    const drvResult = await new DomainRecordValidator(OnlineSelfPacedModuleDomainConfig.schema)
        .validate(fetchedCourse, "read").resolve();

    if (drvResult.err) return drvResult.val;

    const valid = drvResult.val; // does not contain crg yet

    return valid;

}