import { AsyncResultWrapper, AsyncResult, AsyncErr, AsyncOk } from 'ts-async-results';
import { Ok, Err } from "ts-results";

export { AsyncErr, Err, AsyncOk, Ok, AsyncResultWrapper };


/*
The fetching function 
    - should have an instance of userManager or some other internal way to get the auth token
        - NOT THIS ... have the token passed as a param, let it be supplied by the slice
    - can have more params than FetchApiFunction because it should be wrapped in another func that matches FetchApiFunction's signature
        - one of the additional params should be a DocumentRecordValidator instance
            - NOT THIS ... the DRV should be part of whatever processes 
    - should implement the AsyncResults pattern

May need to have a DomainRecordMixed type for result arrays
    - if a record cannot be successfully cast, perhaps just include the parsed JSON
    - try to avoid overly verbose objects (an array of DomainRecordValid objects will have a lot of redundant info)

*/

export function ApiFetch<T = any>(url: string, bearerToken?: string, body?: FormData | object | string): AsyncResult<T, string> {
    const init: RequestInit = {
        method: body ? "POST" : "GET",
        headers: {
            ...(bearerToken ? { "Authorization": `Bearer ${bearerToken}` } : undefined),
            ...(typeof body === "object" ? { "content-type": "application/json" } : undefined)
        },
        credentials: bearerToken ? "include" : undefined,
        body: !body ? undefined
            : body instanceof FormData ? body
                : typeof body === "object" ? JSON.stringify(body)
                    : body
    };

    return new AsyncResultWrapper(async () => {
        try {
            const response = await fetch(url, init);
            if (response.ok) {
                const contentType = response.headers.get("content-type");
                if (!contentType || !contentType.includes("application/json")) {
                    throw new TypeError(`An unsupported content-type was recieved of '${contentType}' but expected 'application/json'`);
                }
                return Ok(await response.json());
            } else {
                throw new Error(await response.text());
            }
        } catch (error) {
            return new Err(String(error));
        }
    }) as AsyncResult<T, string>;
}