
import styled from "styled-components";
import { useRef, useEffect, useLayoutEffect as useOriginalLayoutEffect } from "react";

const useLayoutEffect = typeof window === 'undefined' ? useEffect : useOriginalLayoutEffect;

function useFocusRef<T extends HTMLOrSVGElement>(isSelected: boolean) {
    const ref =useRef<T>(null);
  
    useLayoutEffect(() => {
      if (!isSelected) return;
      ref.current?.focus({ preventScroll: true });
    }, [isSelected]);
  
    return {
      ref,
      tabIndex: isSelected ? 0 : -1
    };
  }


const CellExpand = styled.div`
  float: right;
  display: table;
  height: 100%;
  > span {
    display: table-cell;
    vertical-align: middle;
    cursor: pointer;
  }
`;

interface CellExpanderFormatterProps {
  isCellSelected: boolean;
  expanded: boolean;
  onCellExpand: () => void;
}

export default function CellExpanderFormatter({
  isCellSelected,
  expanded,
  onCellExpand
}: CellExpanderFormatterProps) {
  const { ref, tabIndex } = useFocusRef<HTMLSpanElement>(isCellSelected);

  function handleKeyDown(e: React.KeyboardEvent<HTMLSpanElement>) {
    if (e.key === ' ' || e.key === 'Enter') {
      e.preventDefault();
      onCellExpand();
    }
  }

  return (
    <CellExpand>
      <span onClick={onCellExpand} onKeyDown={handleKeyDown}>
        <span ref={ref} tabIndex={tabIndex}>
          {expanded ? '\u25BC' : '\u25B6'}
        </span>
      </span>
    </CellExpand>
  );
}