import { DataElementType, DomainSchema } from "../types";
import { DomainConfig } from "../types/domainConfig";

const DownloadsDataElements: DataElementType[] = [
    {
        formElement: "hidden",
        uid: "f41d9790-ac29-4138-8f4b-df09bc72dda5",
        formLabel: "Download Id",
        reportLabel: "Download Id"
    },
    {
        formElement: "doc",
        uid: "28053e27-6de0-428d-a7ac-0e4f372596f3",
        formLabel: "Downloads",
        reportLabel: "Downloads"
    }
];

const DownloadsSchema: DomainSchema = {
    uid: "b3d5931d-5e09-435a-811c-e7d0d9191d5a",
    name: "download",
    version: 0,
    dataElements: DownloadsDataElements,
    fields: {
        uid: {
            sortOrder: 0,
            dataElementUid: "f41d9790-ac29-4138-8f4b-df09bc72dda5",
            onAction: {
                base: { required: ["Required"] },
                create: { required: false, omit: true }
            }
        },
        downloads: {
            sortOrder: 1,
            dataElementUid: "28053e27-6de0-428d-a7ac-0e4f372596f3",
            onAction: {
                base: {
                    required: false
                }
            }
        }
    },
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "Downloads",
                description: "Any files below will be available to users from the Downloads page.",
                fields: ["uid", "downloads"]
            }
        ]
    }
}

export interface DownloadsDomainSchema extends DomainSchema {}
export const DownloadsDomainConfig: DomainConfig<DownloadsDomainSchema> = {
    id: [DownloadsSchema.name, DownloadsSchema.uid, DownloadsSchema.version],
    schema: DownloadsSchema as DownloadsDomainSchema
}