import React, { useEffect, useRef, useState } from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import { Button, IconButton, InputAdornment, TextField, Typography } from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useHistory } from "react-router-dom";
import { AUTH_URL } from "../../app/runtimeConstants";
import { useSnackbar } from "notistack";

export default function ResetPassword() {
    const history = useHistory();
    const [showPassword, setShowPassword] = useState(false);
    const handleClickShowPassword = () => setShowPassword(!showPassword);
    const handleMouseDownPassword = () => setShowPassword(!showPassword);
    const [resetSubmissionState, setResetSubmissionState] = useState<"idle" | "pending" | "success" | "fail">("idle");
    const [resetSubmissionError, setResetSubmissionError] = useState("");
    const { enqueueSnackbar } = useSnackbar();

    const getResetCode = () => {
        const urlParams = new URLSearchParams(window.location.search);
        return urlParams.get("code");
    };

    const validation = yup.object({
        email: yup
            .string()
            .email("Enter a valid email")
            .required("Email is required"),
        password: yup
            .string()
            .required("Password is required")
            ///^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/
            .matches(/^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})/, "Password must contain at least 12 characters, one uppercase, one lowercase, one number, and one special character."),
        confirmPassword: yup
            .string()
            .oneOf([yup.ref("password"), null] as any[], "Passwords must match")
    });

    const formik = useFormik({
        initialValues: { password: "", confirmPassword: "", email: "" },
        validationSchema: validation,
        onSubmit: (values) => {
            setResetSubmissionState("pending");
            const body = {
                Password: values.password,
                ConfirmPassword: values.confirmPassword,
                Code: getResetCode() || ""
            };

            let formData = new FormData();
            formData.append("Email", values.email);
            formData.append("Password", values.password);
            formData.append("ConfirmPassword", values.confirmPassword);
            formData.append("Code", getResetCode() || "");

            fetch(`${AUTH_URL}/Identity/Account/ResetPassword`, {
                method: "post",
                body: formData
            }).then(response => {
                if (response.ok) {
                    enqueueSnackbar("Password reset successful!", { variant: "success" });
                    history.replace("/signin");
                } else {
                    response.text().then(error => {
                        setResetSubmissionState("fail");
                        setResetSubmissionError(error);                        
                    });
                }
            }).catch(rejected => {
                setResetSubmissionState("fail");
                setResetSubmissionError("An unknown error has occured.");
            });
        },
    });

    return (
        <div>
            <h4>Reset Password</h4>
            {resetSubmissionError && <Typography color="error">{<>{resetSubmissionError.split("\n").map((e, i) => <span key={i}>{e.trim()}<br /></span>)}</>}</Typography>}
            <form onSubmit={formik.handleSubmit}>
                <TextField
                    fullWidth
                    variant="outlined"
                    id="email"
                    name="email"
                    label="Email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    id="password"
                    name="password"
                    label="Password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.password}
                    onChange={formik.handleChange}
                    error={formik.touched.password && Boolean(formik.errors.password)}
                    helperText={formik.touched.password && formik.errors.password}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    size="large">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <TextField
                    fullWidth
                    variant="outlined"
                    id="confirmPassword"
                    name="confirmPassword"
                    label="Confirm Password"
                    type={showPassword ? "text" : "password"}
                    value={formik.values.confirmPassword}
                    onChange={formik.handleChange}
                    error={formik.touched.confirmPassword && Boolean(formik.errors.confirmPassword)}
                    helperText={formik.touched.confirmPassword && formik.errors.confirmPassword}
                    autoComplete="off"
                    InputProps={{
                        endAdornment: (
                            <InputAdornment position="end">
                                <IconButton
                                    aria-label="toggle password visibility"
                                    onClick={handleClickShowPassword}
                                    onMouseDown={handleMouseDownPassword}
                                    size="large">
                                    {showPassword ? <Visibility /> : <VisibilityOff />}
                                </IconButton>
                            </InputAdornment>
                        )
                    }}
                />
                <Button variant="contained" color="primary" style={{ float: "right" }} type="submit">Reset Password</Button>
            </form>
        </div>
    );

}