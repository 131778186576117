import { DomainSchema, DomainConfigAdmin, DomainRecordValues, RecordKeyValueType, DomainRecordError, DomainRecordValid } from "../types";
import { userProfileAdminDomainSchema } from "./domainSchema";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import DMActionEditor from "features/dmAdminGrid/DMActionOpener";
export interface UserProfileAdminDomainSchema extends DomainSchema {}

export const UserProfileAdminDomainConfig: DomainConfigAdmin<UserProfileAdminDomainSchema> = {
    id: [userProfileAdminDomainSchema.name, userProfileAdminDomainSchema.uid, userProfileAdminDomainSchema.version],
    schema: userProfileAdminDomainSchema as UserProfileAdminDomainSchema,
    defaultViewFields: ["email", "surname", "givenName"],
    searchFields: ["email", "surname", "givenName"],
    defaultSort: ["email", "ASC"],
    getRecordKeyValue: function (record: DomainRecordValues<UserProfileAdminDomainSchema>): RecordKeyValueType {
        return record["uid"];
    },
    getAll: function (token: string): Promise<(DomainRecordValid<UserProfileAdminDomainSchema, "read"> | DomainRecordError<UserProfileAdminDomainSchema, "read">)[]> {
        throw new Error("Function not implemented.");
    },
    get: function (recordKeyValue: string | number, token: string): DomainRecordValid<UserProfileAdminDomainSchema, "read"> | DomainRecordError<UserProfileAdminDomainSchema, "read"> {
        throw new Error("Function not implemented.");
    },
    actions: [
        // {
        //     title: "Edit",
        //     isBatch: false,
        //     icon: "OpenInNew",            
        // }
    ]
}