import { Dvr } from "@mui/icons-material";
import { ApiFetchOLD, ApiJsonDatum } from "features/common/api";
import { BasicUserInfo, JsonDatumEndpoints, MiscEndpoints } from "features/common/elevatedApiEndpoints";
import { Course } from "features/courses/data/course";
import { ApiFetch } from "../asyncApi";
import { DomainRecordAction, DomainRecordError, DomainRecordValid, DomainRecordValues, DomainSchema } from "../types";
import { DomainConfig } from "../types/domainConfig";
import { DomainRecordValidator } from "../util";
import { inPersonAdHocDomainSchema } from "./domainSchemaInPersonAdHoc";

export  { OnlineDocumentReviewConfig as onlineDocumentReviewConfig } from "./domainConfigOnlineDocumentReview";


export interface InPersonAdHocDomainSchema extends DomainSchema { }
export interface OnlineDocumentReviewDomainSchema extends DomainSchema { }
export const InPersonAdHocDomainConfig: DomainConfig<InPersonAdHocDomainSchema> = {
    id: [inPersonAdHocDomainSchema.name, inPersonAdHocDomainSchema.uid, inPersonAdHocDomainSchema.version],
    schema: inPersonAdHocDomainSchema as InPersonAdHocDomainSchema
};


async function mapInPersonAdHoc<T extends DomainRecordAction>(values: DomainRecordValues<InPersonAdHocDomainSchema>, action: T): Promise<{ attendees: BasicUserInfo[], course: Omit<Course, "prereqsSatisfied">; }> {
    // TODO this is not how the async wrappers intended to be handled...

    const drv = new DomainRecordValidator(InPersonAdHocDomainConfig.schema);
    const result = await drv.validate(values, action)
        .map(v => v)
        .mapErr(v => v)
        .resolve();

    if (result.err) {
        throw new Error(`Recieved invalid values for mapping: ${result.val.errors}`);
    }
    const v = result.val.value;

    return {
        course: {
            active: true, // from hardcoded hidden field that should always be true
            certificate: v["certificateYN"] === 1 ? { name: v["certificateCourseName"] } : false,
            description: v["description"],
            details: {
                kind: "InPersonAdHoc",
                eventDate: v["eventDate"],
                trackingMethod: "Instructor", //from hardcoded hidden field that should always be instructor
                urls: v["docs"] || []
            },
            header: {
                backgroundColor: "" //this course will not be displayed in a card, may want to rethink this field being required
            },
            name: v["courseName"],
            uid: v["uid"]
        },
        attendees: v["attendees"]
    };
}

export function unmapInPersonAdHoc(c: Omit<Course, "prereqsSatisfied">) : DomainRecordValues<InPersonAdHocDomainSchema> {
    if (c.details.kind !== "InPersonAdHoc") {
        throw Error(`Expected InPersonAdHoc, got ${c.details.kind}`);
    }

    return {
        uid: c.uid,
        courseName: c.name,
        description: c.description,
        trackingMethod: "Instructor",
        active: String(c.active),
        certificateYN: typeof c.certificate === "object" ? 1 : 2,
        certificateCourseName: typeof c.certificate === "object" ? c.certificate.name : "",
        eventDate: c.details.eventDate,
        attendees: [],
        docs: c.details.urls || []
    };
}

export async function saveInPersonAdHoc<T extends Exclude<DomainRecordAction, "read">>(values: DomainRecordValues<InPersonAdHocDomainSchema>, action: T, userUid: string, token: string): Promise<DomainRecordValid<InPersonAdHocDomainSchema, "read"> | DomainRecordError<InPersonAdHocDomainSchema, T | "read">> {
    const mapped = await mapInPersonAdHoc(values, action);
    if (mapped.course.details.kind !== "InPersonAdHoc") {
        throw Error(`Expected InPersonAdHoc, got ${mapped.course.details.kind}`);
    }

    const uid = action === "create" ? null : mapped.course.uid;
    const payload: Partial<Course> = { ...mapped.course };
    delete payload.uid;

    const datum: ApiJsonDatum = {
        uid: uid,
        schemaName: "course",
        userId: userUid,
        created: null,
        active: true,
        data: JSON.stringify(payload)
    };

    const config = JsonDatumEndpoints.post(datum, "course");
    const fetchResult = (await ApiFetch<ApiJsonDatum>(config.url, token, config.requestBody).resolve())
        .map(v => v)
        .mapErr((err) => {
            return {
                kind: "error",
                schema: InPersonAdHocDomainConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<InPersonAdHocDomainSchema, typeof action>;
        });

    if (fetchResult.err) return fetchResult.val;

    const fetchedCourse = { ...unmapInPersonAdHoc(JSON.parse(fetchResult.val.data)), uid: fetchResult.val.uid };

    const drvResult = await new DomainRecordValidator(InPersonAdHocDomainConfig.schema)
        .validate(fetchedCourse, "read").resolve();

    if (drvResult.err) return drvResult.val;

    const valid = drvResult.val; // does not contain attendees yet

    const vUid = valid.value["uid"];
    const vEventDate = valid.value["eventDate"];

    const attendeesConfig = MiscEndpoints.setCourseAttendees(vUid, vEventDate, vEventDate, mapped.attendees.map(a => a.uid));

    const attendeesFetchResult = (await ApiFetch<BasicUserInfo[]>(attendeesConfig.url, token, attendeesConfig.requestBody).resolve())
        .map(v => v)
        .mapErr((err) => {
            return {
                kind: "error",
                schema: InPersonAdHocDomainConfig.id,
                action: action,
                value: undefined,
                isValid: false,
                wasCast: false,
                errors: err,
                stack: new Error().stack
            } as DomainRecordError<InPersonAdHocDomainSchema, typeof action>;
        });

    if (attendeesFetchResult.err) return attendeesFetchResult.val;

    let retval = { ...valid };

    retval.value["attendees"] = attendeesFetchResult.val;  //mapped.attendees; // TODO this should be okay, but this whole function needs refactoring

    return retval;
}

