import React, { useEffect } from "react";
import DataGrid, { Column, DataGridHandle, RowsChangeData } from "react-data-grid";
import { Convert } from '../../../data/old/UserProfileDataTypes';
import fieldsData from "../../../data/old/userProfileFields.json";
import CellExpanderFormatter from "./formatters/CellExpanderFormatter";
const fieldConfig = Convert.toUserProfileField(JSON.stringify(fieldsData));

type ProfileFieldRow =
    {
        type: "MASTER";
        expanded: boolean;
        uid: string;
        fieldType: string;
        label: string;
        adminLabel: string;
        displayOrder: number;        
    }
    | {
        type: "DETAIL";
        uid: string;
        parentUid: string;
    };

// export interface ProfileRow {
//     uid: string;
//     type: string;
//     label: string;
//     adminLabel: string;
//     displayOrder: number;
// }

export interface ProfileOptionRow {
    key: number;
    text: string;
}

const profileOptionColumns: readonly Column<ProfileOptionRow>[] = [    
    { key: "text", name: "Value" }
];

function getProfileFieldRows() : readonly ProfileFieldRow[] {
    const profileFieldRows: ProfileFieldRow[] = [];

    fieldConfig.sort((a,b) => a.displayOrder - b.displayOrder).forEach(field => {
        profileFieldRows.push({
            type: "MASTER",
            expanded: false,
            uid: field.fieldId,
            label: field.label,
            adminLabel: field.adminLabel,
            displayOrder: field.displayOrder,
            fieldType: field.type
        });
    });

    return profileFieldRows;
}


const profileOptionsMap = new Map<string, readonly ProfileOptionRow[]>();
function getProfileOptions(parentUid: string): readonly ProfileOptionRow[] {
    parentUid = parentUid.replace("-DETAIL", "");
    if (profileOptionsMap.has(parentUid)) return profileOptionsMap.get(parentUid)!;

    const profileOptions: ProfileOptionRow[] = [];

    const field = fieldConfig.find(f => f.fieldId === parentUid);
    if (field && field.options) {
        field.options.forEach(opt => profileOptions.push(opt));
    }

    profileOptionsMap.set(parentUid, profileOptions);
    return profileOptions;
}

export function getColumns(): readonly Column<ProfileFieldRow>[] {
    return [
        {
            key: 'expanded',
            name: '',
            minWidth: 30,
            width: 30,
            colSpan(args) {
                return args.type === "ROW" && args.row.type === "DETAIL" ? 5 : undefined;
            },
            cellClass(row) {
                // return a CSS class with padding
                return undefined;
            },
            formatter({ row, isCellSelected, onRowChange }) {
                if (row.type === "DETAIL") {
                    return <ProfileOptionGrid isCellSelected={isCellSelected} parentUid={row.uid} />;
                }

                return (
                    <CellExpanderFormatter
                        expanded={row.expanded}
                        isCellSelected={isCellSelected}
                        onCellExpand={() => {
                            onRowChange({ ...row, expanded: !row.expanded });
                        }}
                    />
                );
            }
        },
        {
            key: "label",
            name: "Label"
        },        
        {
            key: "fieldType",
            name: "Type"
        },
        {
            key: "displayOrder",
            name: "Order",
        },
        {
            key: "adminLabel",
            name: "Short Label"
        }
    ];
}


function ProfileOptionGrid({ parentUid, isCellSelected }: { parentUid: string; isCellSelected: boolean; }) {
    const gridRef = React.useRef<DataGridHandle>(null);

    useEffect(() => {
        if (!isCellSelected) return;

        gridRef
            .current!.element!.querySelector<HTMLDivElement>("[tabindex='0']")!
            .focus({ preventScroll: true });
    }, [isCellSelected]);

    const profileOptions = getProfileOptions(parentUid);

    function onKeyDown(event: React.KeyboardEvent<HTMLDivElement>) {
        if (event.isDefaultPrevented()) {
            event.stopPropagation();
        }
    }

    return (
        <div onKeyDown={onKeyDown}>
            <DataGrid
                ref={gridRef}
                rows={profileOptions}
                columns={profileOptionColumns}
                rowKeyGetter={rowKeyGetter}
                style={{ height: 250 }}
            />

        </div>

    );

}

function rowKeyGetter(row: ProfileFieldRow | ProfileOptionRow) {
    if ("uid" in row) return row.uid;
    return row.key;
}

export default function ProfileFieldGrid() {
    const [rows, setRows] = React.useState(getProfileFieldRows);

    function onRowsChange(rows: ProfileFieldRow[], { indexes }: RowsChangeData<ProfileFieldRow>) {
        const row = rows[indexes[0]];
        if( row.type === "MASTER") {
            if(!row.expanded) {
                rows.splice(indexes[0] + 1, 1);
            } else {
                rows.splice(indexes[0] + 1, 0, {
                    type: 'DETAIL',
                    uid: row.uid + "-DETAIL",
                    parentUid: row.uid
                  });
            }
            setRows(rows);
        }
    }

    return (
        <DataGrid 
            rowKeyGetter={rowKeyGetter}
            columns={getColumns()}
            rows={rows}
            onRowsChange={onRowsChange}
            headerRowHeight={45}
            rowHeight={(args) => (args.type === "ROW" && args.row.type === "DETAIL" ? 300 : 45)}
            className="fill-grid"
            enableVirtualization={false}
        />
    );
}

