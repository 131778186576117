import React from "react";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Dialog, DialogActions, DialogContent, IconButton, Tooltip } from "@mui/material";
import UserProfilePage from "../../userProfile";
// import { AppTheme } from "../../../components/Theme";
export default function PopupUserEditor() {
    const [open, setOpen] = React.useState(false);
    // const muiClasses = AppTheme.useMuiStyles();

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <>
            <Tooltip title="Edit in Pop-up">
                <IconButton size="small" onClick={handleClickOpen}>
                    <OpenInNewIcon />
                </IconButton>
            </Tooltip>
            <Dialog open={open} onClose={handleClose}>
                <DialogContent>
                    {/* className={`${muiClasses.root}`} */}
                    <div> 
                        <UserProfilePage action="admin"></UserProfilePage>
                    </div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleClose} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

        </>
    );
}