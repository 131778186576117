import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";
import { ApiFetchOLD } from "features/common/api";
import { HomeEndpoints } from "features/common/userApiEndpoints";

// export interface HomeDownload {
//     uid: string;
//     name: string;
//     url: string;
//     showOnHome: boolean;
//     description?: string;
//     type?: string;
// }
export interface HomeState {
    markdown: string;    
    status: 'idle' | 'loading' | 'succeeded' | 'failed';

}

const initialState: HomeState = {
    markdown: "",    
    status: "idle"
};

export const fetchHomeMarkdown = createAsyncThunk(
    'home/fetchHomeMarkdown',
    async (undefined, thunkAPI) => {
        //TODO fix state typing within thunks
        const state: any = thunkAPI.getState();
        const token = state?.oidc?.user?.access_token;

        //put default home markdown here instead of in mapper?
        return await ApiFetchOLD(HomeEndpoints.GetHomeMarkdown, undefined, token);
    }
);

const homeSlice = createSlice({
    name: "home",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder
            .addCase(fetchHomeMarkdown.pending, (state) => { state.status = "loading"; })
            .addCase(fetchHomeMarkdown.fulfilled, (state, action) => {
                state.markdown = action.payload;
                state.status = "succeeded";
            })
            .addCase(fetchHomeMarkdown.rejected, (state, action) => {
                state.markdown = "## Welcome\nThis application provides you with access to various training courses and reference materials.\n\nThe following pages are available by clicking the links above:\n\n- [Home](/) - this page\n- [User Profile](/userprofile) - update your user information\n- [Courses](/courses) - view your training requirements and access courses\n- [Downloads](/downloads) - view various training documents";
                state.status = "failed";
            });
    }
});

export const selectHomeMarkdown = (state: RootState) => state.home.markdown;
export default homeSlice.reducer;