import React from 'react';
import { useField } from 'formik';
import { Select, ListSubheader, FormControl, InputLabel, MenuItem, FormHelperText } from "@mui/material";
import { FMuiProps, FMuiSelectProps, getFMuiFieldErrorState, getFMuiFieldHelperText, fieldOptionsCompareForTextSort, getFilteredPropsForInputSpread, FieldOption, FMuiUserGroupProps } from "./internal";
import { useAppSelector } from "app/hooks";
import { AuthUser } from "features/auth/authAPI";

function getUserGroupItems(authUser: AuthUser, areaName: string, moduleName: string, actionKey: string) {
    const targetGroupMap = new Map<string, [name: string, desc: string | null]>();

    if (authUser && authUser.areas) {
        //find correct area
        const areas = authUser.areas.filter(a => a.name === areaName);
        if (areas.length !== 1) throw Error(`Expected one Area matching '${areaName}', but found ${areas.length}`);
        const area = areas[0];
        //find correct module
        const modules = area.modules.filter(m => m.name === moduleName);
        if (modules.length !== 1) throw Error(`Expected one Module matching '${moduleName}', but found ${modules.length}`);
        const module = modules[0];

        //find all actions of actionKey and collect their targetUserGroup uid & name pairs (note: for the crg control, this would collect from targetJsonDatumUids)            
        module.actions.forEach(action => {
            if (action.actionKey === actionKey && action.targetUserGroup) {
                targetGroupMap.set(action.targetUserGroup.uid, [action.targetUserGroup.name, action.targetUserGroup.description]);
            }
        });
    }
    return [...targetGroupMap];
}

export default function FMuiUserGroupField(props: FMuiProps<FMuiUserGroupProps>) {
    const authUser = useAppSelector(state => state.auth.user);
    if (!authUser) throw Error("No user logged in");
    const [field, meta] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const labelId = `label-${props.name}`;
    const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiSelectProps>>(props);
    const [targetGroupMap] = React.useState(getUserGroupItems(authUser, props.areaName, props.moduleName, props.actionKey));

    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <InputLabel id={labelId}>{props.label}</InputLabel>
            <Select
                multiple={true}
                label={props.label}
                labelId={labelId}
                error={inError}
                {...inputProps}
                {...field}
            // input={props.multiple ? <Input /> : undefined}
            >

                {targetGroupMap.map(option => <MenuItem key={option[0]} value={option[0]}>{option[1][0]}</MenuItem>)}

                {/* {
                    [<ListSubheader key={`lsh-${props.name}`}>{props.label}</ListSubheader>]
                        .concat(targetGroupMap
                            .map(option =>
                                <MenuItem key={option[0]} value={option[0]}>{option[1][0]}</MenuItem>))
                } */}
            </Select>
            {inError && <FormHelperText error>{errorMsg}</FormHelperText>}
            {getFMuiFieldHelperText(props.formHelperText)}
        </FormControl>
    );

}