import { DomainSchema } from "../types";
import { CoursesDataElements } from "./dataElements";
import { InPersonAdHocFields } from "./domainFieldsInPersonAdHoc";

export const inPersonAdHocDomainSchema: DomainSchema = {
    uid: "b8991364-379f-4f9f-a380-984a13975dd9",
    name: "inPersonAdHoc",
    version: 0,
    fields: InPersonAdHocFields,
    dataElements: CoursesDataElements, // likely has more than needed and could be loaded multiple times
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "Basic Info",
                description: "Please complete the following information.",
                fields: ["uid", "courseName", "description", "eventDate", "active"]
            },
            {
                title: "Completion Certificate",
                description: "Please complete the following information.",
                fields: ["trackingMethod", "certificateYN", "certificateCourseName"]
            },
            {
                title: "Attendees",
                description: "Search for students to add to the list of attendees. If no student record is found, you may create one.",
                fields: ["attendees"]
            },
            {
                title: "Attachments",
                description: "You may optionally attach any documents such as an agenda or other course material. These documents will not be made available to students.",
                fields: ["docs"]
            }
        ]
    }
}