import { Paper } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { ApiFetchOLD } from "features/common/api";
import { HomeEndpoints } from "features/common/userApiEndpoints";
import React from "react";
import DataGrid, { Column } from "react-data-grid";
import GridBottomBar from "./gridEditor/GridBottomBar";
import GridTopBar from "./gridEditor/GridTopBar";
import { IconOpenModalDMEditor } from "./gridEditor/ModalDMEditor";

interface MiscRow {
    uid: string;
    kind: "homemd" | "download";
    item: string;
    data: any;
}
function rowKeyGetter(row: MiscRow) {
    return row.uid;
}

function getColumns(onEditSuccess: () => void): readonly Column<MiscRow>[] {
    return [
        {
            key: "edit",
            name: "Action",
            frozen: true,
            width: 90,
            formatter(props) {
                return (
                    <IconOpenModalDMEditor
                        kind={props.row.kind}
                        initialValues={props.row.data}
                        onSuccess={onEditSuccess}
                    />
                );
            }
        },
        {
            key: "item",
            name: "Item"
        }
    ];
}

function fetchRows(token: string) {
    // const homemd = await ApiFetchOLD(HomeEndpoints.GetHomeMarkdown, undefined, token);
    return [
        {
            uid: "ce022b40-9359-459d-b71c-cb90492fadf7",
            kind: "homemd",
            item: "Home Page Content",
            data: {
                uid: "ce022b40-9359-459d-b71c-cb90492fadf7",
                md: "## Welcome {givenName}\nThis application provides you with access to various training courses and reference materials.\n\nThe following pages are available by clicking the links above:\n\n- [Home](/) - this page\n- [User Profile](/userprofile) - update your user information\n- [Courses](/courses) - view your training requirements and access courses\n- [Downloads](/downloads) - view various training documents\n\nAdministrators:\n\nEnvrionmental - Jennifer Losson - jennifer.m.losson.nfg@army.mil\n\nFor assistance, please contact help@ngor-lms.com\n"
            }
        } as MiscRow,
        {
            uid: "042a92dc-b1d1-42ee-ad51-bd05f122a0ea",
            kind: "download",
            item: "Downloads",
            data: {
                uid: "042a92dc-b1d1-42ee-ad51-bd05f122a0ea",
                downloads: []
            }
        } as MiscRow
    ];
}

export default function MiscAdmin() {
    const token = useAppSelector(state => state.oidc.user?.access_token);
    if (!token) throw Error("No user logged in");
    const [loading, setLoading] = React.useState(true);
    const [rows, setRows] = React.useState<MiscRow[]>([]);
    const [cols, setCols] = React.useState(getColumns(() => setLoading(true)));
    
    React.useEffect(() => {
        if(loading) {
            //do async stuff
            setRows(fetchRows(token));
            setLoading(false);
        }
    }, [loading, token]);

    return (
        <div>
            <h4>Home Page &amp; Downloads Administration</h4>
            <p>You are able to edit the content of the Home and Downloads pages.</p>
            <Paper>
                <DataGrid
                    columns={cols}
                    rows={rows}
                    rowKeyGetter={rowKeyGetter}
                    onRowsChange={setRows}
                    defaultColumnOptions={{
                        resizable: true
                    }}
                    style={{ height: "400px" }}
                />
            </Paper>
        </div>
    );
}
