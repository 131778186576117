import { Step } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { DataElement, DomainField, DomainRecordAction, DomainFieldSchema, FieldCondition, FieldConditions, ActionValidator, ComparisonOperators, DataElementType } from "features/domainModel";
import { FMuiDependentFieldWrapper, FMuiRadioGroupField, FMuiSelectField, FMuiTreeViewField } from "./formElements";
import FMuiColorField from "./formElements/FMuiColorField";
import FMuiCourseField from "./formElements/FMuiCourseField";
import FMuiDocField from "./formElements/FMuiDocField";
import FMuiMarkdown from "./formElements/FMuiMarkdown";
import FMuiTextField from "./formElements/FMuiTextField";
import FMuiToggleField from "./formElements/FMuiToggleField";
import FMuiUserField from "./formElements/FMuiUserField";
import FMuiUserGroupField from "./formElements/FMuiUserGroup";
import { FMuiDependentProps, formatDate } from "./formElements/internal";

type OnActionValidations = Partial<ActionValidator>;
export function getFMuiFields<T extends DomainField>(fieldsFor: Array<keyof T>, forAction: DomainRecordAction, allFields: T, dataElements: DataElementType[]) {
    const FMuiFields: JSX.Element[] =
        fieldsFor.map(fieldName => {
            const dfs: DomainFieldSchema = allFields[fieldName];
            let de = dataElements.find(d => d.uid === dfs.dataElementUid);
            const oav = { ...dfs.onAction.base, ...dfs.onAction[forAction] };
            if (!de) throw new Error(`DomainFieldSchema for '${String(fieldName)}' specified a dataElementUid of '${dfs.dataElementUid}' which does not exist in the DataElement collection`);
            if (dfs.dataElementOverrides) {
                const deo = dfs.dataElementOverrides;
                //TODO type the dataElementOverrides better
                //@ts-ignore
                if (deo.all) de = { ...de, ...deo.all };
                //@ts-ignore
                if (deo[forAction]) de = { ...de, ...deo[forAction] };
                if (!de) throw new Error(`DomainFieldSchema for '${String(fieldName)}' included dataElementOverrides that resulted in an undefined dataElement`);
            }
            const FMuiOverwriteWhen = getFMuiOverwriteValueWhenCondition(fieldName, dfs, oav, allFields, dataElements);
            const FMuiOnlyShowWhen = getFMuiOnlyShowWhenCondition(fieldName, dfs, oav, allFields, dataElements);
            const FMuiField = getFMuiField(fieldName.toString(), dfs, de);

            if (FMuiOnlyShowWhen || FMuiOverwriteWhen) {
                return <FMuiDependentFieldWrapper
                    key={de.uid}
                    fieldName={fieldName.toString()}
                    onlyShowWhen={FMuiOnlyShowWhen}
                    overwriteValueWhen={FMuiOverwriteWhen}
                    dependentChild={FMuiField}
                />;
            } else {
                return FMuiField;
            }
        });
    return FMuiFields;
}

function getFMuiOverwriteValueWhenCondition<T extends DomainField>(fieldName: keyof T, dfs: DomainFieldSchema, oav: OnActionValidations, allFields: T, dataElements: DataElementType[]): FMuiDependentProps["overwriteValueWhen"] | undefined {
    if (oav.overwriteValueWhen) {
        if (!isFieldCondition(oav.overwriteValueWhen.when)) throw new Error(`DomainFieldSchema for '${String(fieldName)}' has an overwriteValueWhen condition that not implemented (only type FieldCondition is currently implemented)`);

        const fc = oav.overwriteValueWhen.when;

        if (fc.operator !== ComparisonOperators.eq) throw new Error(`DomainModelField '${String(fieldName)}' has an overwriteValueWhen comparison operator of '${fc.operator}' but only 'eq' is currently implemented`);

        const targetDe = dataElements.find(f => f.uid === fc.dataElementUid);
        if (!targetDe) throw new Error(`DomainFieldSchema '${String(fieldName)}' has an overwriteValueWhen condition that specifies a DataElement ('${fc.dataElementUid}') that does not exist in the DataElement collection`);

        const targetDf = Object.entries(allFields).find(([fieldName, tdfs]) => tdfs.dataElementUid === targetDe.uid);
        if (!targetDf) throw new Error(`DomainFieldSchema '${String(fieldName)}' has an overwriteValueWhen condition that specifies a DataElement ('${fc.dataElementUid}') that does not exist in the DomainField object`);
        const [targetFieldName, targetDfs] = targetDf;

        return {
            newValue: oav.overwriteValueWhen.newValue,
            when: {
                fieldName: targetFieldName,
                hasValue: fc.value
            }
        };
    } else {
        return undefined;
    }
}

function getFMuiOnlyShowWhenCondition<T extends DomainField>(fieldName: keyof T, dfs: DomainFieldSchema, oav: OnActionValidations, allFields: T, dataElements: DataElementType[]): FMuiDependentProps["onlyShowWhen"] | undefined {
    if (oav.onlyShowWhen !== undefined) {
        if (!isFieldCondition(oav.onlyShowWhen)) throw new Error(`DomainFieldSchema for '${String(fieldName)}' has an onlyShowWhen condition that not implemented (only type FieldCondition is currently implemented)`);

        const fc = oav.onlyShowWhen;

        if (fc.operator !== ComparisonOperators.eq) throw new Error(`DomainModelField '${String(fieldName)}' has an onlyShowWhen comparison operator of '${fc.operator}' but only 'eq' is currently implemented`);

        const targetDe = dataElements.find(f => f.uid === fc.dataElementUid);
        if (!targetDe) throw new Error(`DomainFieldSchema '${String(fieldName)}' has an onlyShowWhen condition that specifies a DataElement ('${fc.dataElementUid}') that does not exist in the DataElement collection`);

        const targetDf = Object.entries(allFields).find(([fieldName, tdfs]) => tdfs.dataElementUid === targetDe.uid);
        if (!targetDf) throw new Error(`DomainFieldSchema '${String(fieldName)}' has an onlyShowWhen condition that specifies a DataElement ('${fc.dataElementUid}') that does not exist in the DomainField object`);
        const [targetFieldName, targetDfs] = targetDf;

        return {
            fieldName: targetFieldName,
            hasValue: fc.value
        };
    } else {
        return undefined;
    }
}

function getFMuiField(fieldName: string, dfs: DomainFieldSchema, de: DataElementType) {
    switch (de.formElement) {
        case "hidden":
            return <input type="hidden" name={fieldName} key={de.uid} />;        
        case "text":
            const feo = de.formElementOptions;
            const type = feo?.isPassword ? "password"
                : feo?.isDate ? "date"
                    : feo?.isTime ? "time"
                        : feo?.isDateTime ? "datetime-local"
                            : "text";
            const rows = feo && feo?.rows && feo.rows > 1 ? feo.rows : undefined;

            return <FMuiTextField
                fullWidth
                // variant="outlined"
                type={type}
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}

                rows={rows}
                multiline={Boolean(rows && rows > 1)}
                key={de.uid}
                inputProps={type.startsWith("date") && de.validators?.find(v => v.kind === "dateInPast") ? { max: formatDate(new Date()) } : undefined}
                indent={dfs.showUiIndented || 0}
            />;
        case "number":
            // TODO implement decimalPlaces and showAsPercentage, add things like Step to the NumberFormatOptions

            const step = de.validators?.find(v => v.kind === "integer") ? 1 : undefined;
            const min = de.validators?.find(v => v.kind === "min")?.params[0];
            const max = de.validators?.find(v => v.kind === "max")?.params[0];

            return <FMuiTextField
                fullWidth
                // variant="outlined"
                type="number"
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                blockENotation={de.formElementOptions?.blockENotation}
                key={de.uid}
                indent={dfs.showUiIndented || 0}
                inputProps={{
                    step, min, max
                }}
            />;
        case "choice":
            if (de.formElementOptions?.asCheckBoxOrRadio) {
                if (de.formElementOptions.multiselect) {
                    throw new Error(`DataElementUid '${de.uid}' is configured for Checkboxes field which is not implemented.`);
                }
                return <FMuiRadioGroupField
                    row
                    name={fieldName}
                    label={de.formLabel}
                    formHelperText={de.helpText}
                    options={de.formElementOptions.items}
                    key={de.uid}
                    indent={dfs.showUiIndented || 0} />;
            } else {
                return <FMuiSelectField
                    fullWidth
                    multiple={de.formElementOptions?.multiselect}
                    name={fieldName}
                    label={de.formLabel}
                    formHelperText={de.helpText}
                    options={de.formElementOptions?.items || []}
                    isCrg={de.formElementOptions?.isCourseRequirementGroup}
                    key={de.uid}
                    indent={dfs.showUiIndented || 0} />;
            }
        case "tree":
            //TODO implement multiple Tree Selections, after updating to mui v5
            return <FMuiTreeViewField
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                options={de.formElementOptions?.items || []}
                multiselect={de.formElementOptions?.multiselect ? true : false}
                key={de.uid}
                indent={dfs.showUiIndented || 0} />;
        case "color":
            return <FMuiColorField
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                key={de.uid}
                indent={dfs.showUiIndented || 0} />;
        case "user":
            return <FMuiUserField
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                key={de.uid}
                indent={dfs.showUiIndented || 0} />;
        case "doc":
            return <FMuiDocField
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                key={de.uid}
                indent={dfs.showUiIndented || 0}
            />;
        case "toggle":
            return <FMuiToggleField
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                key={de.uid}
                indent={dfs.showUiIndented || 0}
            />;
        case "userGroup":
            if (!de.formElementOptions) {
                throw Error(`Form Element Options with areaName, moduleName, and actionKey must be specified for UserGroup fields`);
            }
            return <FMuiUserGroupField
                fullWidth
                name={fieldName}
                label={de.formLabel}
                formHelperText={de.helpText}
                key={de.uid}
                indent={dfs.showUiIndented || 0}
                areaName={de.formElementOptions.areaName}
                moduleName={de.formElementOptions.moduleName}
                actionKey={de.formElementOptions.actionKey}
            />;
            case "markdown":
                return <FMuiMarkdown
                    name={fieldName}
                    label={de.formLabel}
                    formHelperText={de.helpText}
                    key={de.uid}
                    indent={dfs.showUiIndented || 0}
                />;
            case "course":
                return <FMuiCourseField 
                    name={fieldName}                    
                    label={de.formLabel} 
                    formHelperText={de.helpText}
                    key={de.uid}
                    indent={dfs.showUiIndented || 0}
                    accept={de.formElementOptions?.accept || ""} 
                    courseNameKey={"courseName"}  // TODO: this should come from domainSchema
                    courseUidKey={"uid"} // TODO: this should come from domainSchema
                />
        default:
            throw new Error(`DataElementUid '${de.uid}' is FormElement '${de.formElement}' which is not implemented`);
    }
}

export function isFieldCondition(fc: FieldCondition | FieldConditions | FieldConditions[]): fc is FieldCondition {
    return ((fc as FieldCondition).value !== undefined);
}
