import { UserProfileDataElements } from "./dataElements";
import { UserProfileFields } from "./domainFields";
import { DomainSchema } from "../types";

export const userProfileDomainSchema: DomainSchema = {
    uid: "a45c8bc2-0fe5-4771-9fe4-6faabca4bb46",
    name: "userProfile",
    version: 0,
    fields: UserProfileFields,
    dataElements: UserProfileDataElements,
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "General Information",
                description: "Please complete the following information.",
                fields: ["uid", "email", "password", "confirmPassword", "currentPassword", "newPassword", "confirmNewPassword", "givenName", "surname"]
            },
            {
                title: "Organization",
                description: "Please select one organization. Full-time ORARNG/OMD employees select your primary organization.",
                fields: ["organization"]
            },
            {
                title: "Additional Information",
                description: "Please complete the following information.",
                fields: ["militaryDuties", "mosType", "mosEnlisted", "mosWarrant", "mosOfficer", "fulltimeGuardMember", "fulltimeStatusAGRADOS", "agrPosition", "adosPosition", "stateEmployee", "omdState", "fulltimeFederalEmployee", "fulltimeStatusGSWG", "wgPosition", "gsPosition", "epoc", "shop", "csmsSectionEpoc", "epocSupervisor", "handlesHW", "facility"]
            }
        ]
    }
};

let upaFields = {...UserProfileFields};
delete upaFields["password"];
delete upaFields["confirmPassword"];
delete upaFields["currentPassword"];
delete upaFields["newPassword"];
delete upaFields["confirmNewPassword"];

export const userProfileAdminDomainSchema: DomainSchema = {
    uid: "d8d67e1b-35cc-4e43-bf14-ebeab052a4c5",
    name: "userProfileAdmin",
    version: 0,
    fields: upaFields,
    dataElements: UserProfileDataElements,
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "General Information",
                description: "Please complete the following information.",
                fields: ["uid", "email", "givenName", "surname"]
            },
            {
                title: "Organization",
                description: "Please select one organization. Full-time ORARNG/OMD employees select your primary organization.",
                fields: ["organization"]
            },
            {
                title: "Additional Information",
                description: "Please complete the following information.",
                fields: ["militaryDuties", "mosType", "mosEnlisted", "mosWarrant", "mosOfficer", "fulltimeGuardMember", "fulltimeStatusAGRADOS", "agrPosition", "adosPosition", "stateEmployee", "omdState", "fulltimeFederalEmployee", "fulltimeStatusGSWG", "wgPosition", "gsPosition", "epoc", "shop", "csmsSectionEpoc", "epocSupervisor", "handlesHW", "facility"]
            }
        ]
    }
}