import React from "react";
import DataGrid, { Column } from "react-data-grid";
import { Box, IconButton, Paper, Tooltip, Typography } from "@mui/material";
import { useAppSelector } from "app/hooks";
import { DomainRecordKey, DomainSchema } from "features/domainModel";
import {  DomainConfigAdmin } from "features/domainModel/types";
import DMTopBar from "./DMTopBar";
import DMActionEditor from "./DMActionOpener";

interface DMAdminGridProps {
    pageTitle: string;
    pageDescription: string;
    dca: DomainConfigAdmin<DomainSchema>;
}

function getFieldLabels<T extends DomainSchema>(ds: T): Record<DomainRecordKey<T>, string> {
    const fieldLabels = Object.entries(ds.fields).reduce((acc, df) => {
        const [fieldName, dfs] = df;
        const des = ds.dataElements.filter(de => de.uid === dfs.dataElementUid);

        if (des.length !== 1) {
            throw Error(`Expected one matching DataElement for UID '${dfs.dataElementUid}' but found '${des.length}'`);
        }

        return {
            ...acc,
            [fieldName]: des[0].reportLabel
        };
    }, {});
    return fieldLabels as Record<DomainRecordKey<T>, string>;
}

function getColumns(dca: DomainConfigAdmin<DomainSchema>, chosenFields: Array<DomainRecordKey<DomainSchema>>, fieldLabels: Record<DomainRecordKey<DomainSchema>, string>): readonly Column<any>[] { // ,onActionSuccess: (value: AdminActionEditorResult<DomainSchema,AdminActionEditorTypes> | Array<AdminActionEditorResult<DomainSchema,AdminActionEditorTypes>>) => void
    const cols: Column<any>[] = [];
    if (dca.actions.filter(a => !a.isBatch).length > 0) {
        cols.push({
            key: "action",
            name: "Actions",
            frozen: true,
            formatter(props) {
                // iterate through actions
                return (
                    <Box>
                        {
                            dca.actions.filter(a => !a.isBatch).map((action) => {
                                return (
                                    null
                                );
                            })
                        }
                    </Box>
                );
            }
        });
    }
    Object.entries(dca.schema.fields).forEach(([fieldName, schema]) => {
        if(chosenFields.includes(fieldName)) {
            cols.push({
                key: fieldName,
                name: fieldLabels[fieldName]
            });
        }
    });

    // iterate through fields
    return cols;
}

export default function DMAdminGrid(props: DMAdminGridProps) {
    // const handleActionSuccess = (value: AdminActionEditorResult<typeof props.dca.schema, AdminActionEditorTypes> | AdminActionEditorResult<typeof props.dca.schema, AdminActionEditorTypes>[]) => {

    // };
    const token = useAppSelector(state => state.oidc.user?.access_token);
    if (!token) throw Error("No user logged in");

    const [loading, setLoading] = React.useState(true);
    const [chosenFields, setChosenFields] = React.useState(props.dca.defaultViewFields);
    const [fieldLabels] = React.useState(getFieldLabels(props.dca.schema)); // memo?
    const [cols, setCols] = React.useState(getColumns(props.dca, chosenFields, fieldLabels)) //, handleActionSuccess
    const [searchValue, setSearchValue] = React.useState("");


    React.useEffect(() => {
        if (loading) {
            //get all the records (async stuff)


            setLoading(false);
        }
    }, [loading]);

    

    const handleChosenFieldsChange = (fields: Array<DomainRecordKey<typeof props.dca.schema>>) => {
        // TODO rebuild columns if fields is different

    };

    const handleSearchChange = (value: string) => {
        //TODO filter records by search value and rate limit?

        setSearchValue(value);
    };

    return (
        <Box>
            <Typography variant="h4">{props.pageTitle}</Typography>
            <Typography>{props.pageDescription}</Typography>
            <Paper>
                <DMTopBar
                    chosenFields={chosenFields}
                    allFieldLabels={fieldLabels}
                    onChosenFieldsChange={handleChosenFieldsChange}
                    onSearchChange={handleSearchChange}
                />
                <DataGrid
                    columns={cols}
                    rows={[]}
                    rowKeyGetter={props.dca.getRecordKeyValue}
                />
            </Paper>
        </Box>
    );

}