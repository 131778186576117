import { AppBar, Dialog, DialogContent, DialogTitle, Fab, IconButton, Menu, MenuItem, Toolbar, Tooltip, useMediaQuery, useTheme } from "@mui/material";
import AddIcon from '@mui/icons-material/Add';
import React from "react";
import MergeTypeIcon from '@mui/icons-material/MergeType';
import DeleteIcon from '@mui/icons-material/Delete';
import { Schemas } from "./SchemaChooser";
// import PopupCoursesEditor from "./PopupCoursesEditor";
// import PopupUserEditor from "./PopupUserEditor";
import { InPersonAdHocDomainConfig, onlineDocumentReviewConfig, saveInPersonAdHoc } from "features/domainModel/Courses";
import { DomainRecordValidator } from "features/domainModel";
import { DMForm, DMFormProps } from "features/dmForms";

// import { AppTheme } from "../../../components/Theme";
import { useAppSelector } from "app/hooks";
import { useSnackbar } from "notistack";
import ModalDMEditor from "./ModalDMEditor";


interface GridBottomBarProps {
    schema: Schemas;
    onNewRecordCreated: () => void;
}

export default function GridBottomBar(props: GridBottomBarProps) {
    const user = useAppSelector(state => state.oidc.user);
    const authUserUid = useAppSelector(state => state.auth.user?.uid);
    //const { enqueueSnackbar } = useSnackbar();
    
    if(!user || !authUserUid) {
        throw Error("No user logged in");
    }

    const [popupCourseEditorOpen, setPopupCourseEditorOpen] = React.useState(false);
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [courseType, setCourseType] = React.useState<"OnlineDocReview" | "InPersonAdHoc" | "OnlineSelfPacedModule">("OnlineDocReview");
    //const muiClasses = Theme.useMuiStyles();
    //const theme = useTheme();
    //const fullscreenDialogs = useMediaQuery(theme.breakpoints.down("sm"));


    // const schema = courseType === "InPersonAdHoc" ? InPersonAdHocDomainConfig.schema : onlineDocumentReviewConfig.schema;
    // const drv = new DomainRecordValidator(schema);
    // const { initialValues, validationSchema } = drv.getPropsForFormik("create");

    // const dmeProps: DMFormProps<typeof schema, "create"> = {
    //     domainSchema: schema,
    //     action: "create",
    //     areInitialValuesValid: false,
    //     onUserCancel: () => { setPopupCourseEditorOpen(false); },
    //     forFormik: {
    //         initialValues: initialValues,
    //         validationSchema: validationSchema,
    //         onSubmit: (values, formikHelpers) => {
    //             const {setSubmitting} = formikHelpers;
    //             setSubmitting(true);
    //             saveInPersonAdHoc(values, "create", authUserUid, user.access_token)
    //                 .then(result => {
    //                     if(result.kind === "valid") {
    //                         setPopupCourseEditorOpen(false);
    //                         enqueueSnackbar("Course saved successfully", {variant: "success"});
    //                     } else {
    //                         // TODO: parse {result.errors} for a useful message (it might be an arrray of yup errors)
    //                         enqueueSnackbar(<div><div>Error while saving course</div></div>, {variant: "error", persist: true});
    //                     }
    //                 }).catch(reason => {
    //                     // {reason}
    //                     enqueueSnackbar(<div><div>Error while saving course</div><div></div></div>, {variant: "error", persist: true});                        
    //                 }).finally(() =>  setSubmitting(false));                
    //         }
    //     }
    // };




    const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const closeMenuOpenEditor = (which: "OnlineDocReview" | "InPersonAdHoc" | "OnlineSelfPacedModule") => {
        setCourseType(which);
        setPopupCourseEditorOpen(true);
        handleMenuClose();
    };

    const handleMenuClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <ModalDMEditor 
                kind={courseType} 
                open={popupCourseEditorOpen} 
                onCancel={() => setPopupCourseEditorOpen(false)} 
                onSuccess={() => {setPopupCourseEditorOpen(false); props.onNewRecordCreated()}} />
            {/* <Dialog open={popupCourseEditorOpen} fullScreen={fullscreenDialogs} >
                <DialogTitle>Create new {courseType === "InPersonAdHoc" ? "In-Person Ad-Hoc" : "Online Document Review"} Course</DialogTitle>
                <DialogContent>
                    <div className={`${muiClasses.root}`} style={{ minWidth: "500px" }}>
                        <DMForm {...dmeProps} />
                    </div>
                </DialogContent>
            </Dialog> */}

            <AppBar position="static" variant="outlined" style={{ backgroundColor: "#DDE1E4" }}>
                <Toolbar variant="dense">
                    <Tooltip title="Add Record">
                        <IconButton color="primary" onClick={handleMenuClick}>
                            <AddIcon />
                        </IconButton>
                    </Tooltip>
                    {props.schema === "users" &&
                        <>
                            <Tooltip title="Merge Selected Records">
                                <IconButton color="primary">
                                    <MergeTypeIcon />
                                </IconButton>
                            </Tooltip>

                        </>
                    }
                    {/* <Tooltip title="Delete Selected Records">
                        <IconButton color="primary">
                            <DeleteIcon />
                        </IconButton>
                    </Tooltip> */}
                </Toolbar>
            </AppBar>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleMenuClose}
            >
                <MenuItem disabled>Add New Course of Type:</MenuItem>
                <MenuItem onClick={() => closeMenuOpenEditor("InPersonAdHoc")}>Training Event</MenuItem>
                <MenuItem onClick={() => closeMenuOpenEditor("OnlineDocReview")}>Online Document Review</MenuItem>
                <MenuItem onClick={() => closeMenuOpenEditor("OnlineSelfPacedModule")}>Online Self-Paced Module</MenuItem>

            </Menu>
        </div>
    );
}