import { render } from "@testing-library/react";
import React from "react";
import { AuthUser } from "../auth/authAPI";
import { UserCourse } from "./data/userCourse";

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { CourseSummary } from "./coursesAPI";
import { useSnackbar } from "notistack";
import { Course } from "./data/course";

export interface TranscriptProps {
    user: AuthUser | undefined;
    records: CourseSummary[];
}

//TODO this function is not dry, it's a copy/paste from ModalDMCoursesEditor
function getFriendlyCourseType(kind: Course["details"]["kind"]) {
    switch (kind) {
        case "InPersonAdHoc":
            return "Training Event";
        case "InPersonEvent":
            return "In-Person Event";
        case "OnlineDocReview":
            return "Online Document Review";
        case "OnlineEvent":
            return "Online Event";
        case "OnlineSelfPacedModule":
            return "Online Self-Paced Module";
        default:
            return kind;
    }
}

function getRows(summaries: CourseSummary[]) {
    const rows: JSX.Element[] = [];

    summaries.forEach(cs => {
        if (cs.history) {
            switch (cs.history.details.kind) {
                case "UserCourseHistoryOnlineSelfPacedModule":
                    cs.history.details.history.forEach(h => {
                        if (h.creditReceived) {
                            rows.push(
                                <TableRow>
                                    <TableCell>{cs.course.name}</TableCell>
                                    <TableCell>{getFriendlyCourseType(cs.course.details.kind)}</TableCell>
                                    <TableCell>{h.end ? new Date(h.end).toLocaleString() : new Date(h.start).toLocaleString()}</TableCell>
                                </TableRow>
                            );
                        }
                    });
                    break;
                default:
                    cs.history.details.creditReceivedOn.forEach(h => {
                        rows.push(
                            <TableRow>
                                <TableCell>{cs.course.name}</TableCell>
                                <TableCell>{cs.course.details.kind}</TableCell>
                                <TableCell>{new Date(h.date).toLocaleString()}</TableCell>
                            </TableRow>
                        );
                    });
                    break;
            }
        }
    });

    return rows;
}


export default function Transcript(props: TranscriptProps) {    
    const studentName = props.user && props.user.profile && props.user.profile.isValid ? `${props.user.profile.value["surname"]}, ${props.user.profile.value["givenName"]}` : "";
    const studentEmail = props.user && props.user.email ? props.user.email : "";
    const rows = getRows(props.records);
    return (
        <Paper style={{ padding: "1rem" }}>
            <div style={{ width: "100%", textAlign: "right" }}>
                <p>{new Date(Date.now()).toLocaleString()}</p>
                <p>{studentName}</p>
                <p>{studentEmail}</p>
            </div>
            {/* <TableContainer>
                <Table>
                    <TableBody>
                        <TableRow>
                            <TableCell align="right"><b>Transcript Date</b></TableCell>
                            <TableCell>{new Date(Date.now()).toLocaleString()}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"><b>Student</b></TableCell>
                            <TableCell>{studentName}</TableCell>
                        </TableRow>
                        <TableRow>
                            <TableCell align="right"><b>Email</b></TableCell>
                            <TableCell>{studentEmail}</TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </TableContainer> */}
            <br />

            <TableContainer>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Course</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Date Completed</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.length === 0 ? <TableRow><TableCell colSpan={3} align="center">No courses have completed</TableCell></TableRow> : rows}
                    </TableBody>
                </Table>
            </TableContainer>
        </Paper>
    );
}