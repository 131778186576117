import React from 'react';
import { useField } from 'formik';
import { FormControl, FormLabel, FormHelperText } from "@mui/material";
import TreeView from '@mui/lab/TreeView';
import TreeItem from '@mui/lab/TreeItem';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';

import { FMuiProps, getFMuiFieldErrorState, getFMuiFieldHelperText, getFilteredPropsForInputSpread, FMuiTreeViewProps, FMuiTreeItem } from "./internal";

export default function FMuiTreeViewField(props: FMuiProps<FMuiTreeViewProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);

    // TODO: should inputProps be spread on TreeView? Make sure TreeView is behaving like a controlled component
    // const inputProps = getFilteredPropsForInputSpread<FMuiProps<FMuiTreeViewProps>>(props);

    // TODO: should the expanded nodes calc for a TreeView be memoized?
    const value = (meta.value === undefined || meta.value === null) ? '' : String(meta.value);
        
    return (
        <FormControl style={props.indent ? { marginLeft: `${2*props.indent}rem` } : {}}>
            <FormLabel error={inError}>{props.label}</FormLabel>
            <TreeView
                defaultCollapseIcon={<ExpandMoreIcon />}
                defaultExpandIcon={<ChevronRightIcon />}
                selected={value}
                expanded={findNodeParents(value, props.options) || []}
                onNodeSelect={(e: any, id: any) => helpers.setValue(id)}
                onBlur={()=>helpers.setTouched(true)}
            >
                {props.options.map(n => getTreeItems(n))}
            </TreeView>
            {inError && <FormHelperText error>{errorMsg}</FormHelperText>}
            {getFMuiFieldHelperText(props.formHelperText)}
            {/* <input                
                name={field.name}
                value={field.value}
                type="hidden"
            /> */}
        </FormControl>
    );
}

function getTreeItems(node: FMuiTreeItem) {
    return (
        <TreeItem key={node.id} nodeId={node.id.toString()} label={node.label}>
            {Array.isArray(node.children) ? node.children.map((childNode) => getTreeItems(childNode)) : null}
        </TreeItem>
    );
}

function searchTree(node: FMuiTreeItem, searchId: string): string[] | undefined {
    if (String(node.id) === searchId) {
        return [];
    }
    if (Array.isArray(node.children)) {
        for (var treeNode of node.children) {
            const childResult = searchTree(treeNode, searchId);

            if (Array.isArray(childResult)) {
                return [String(treeNode.id)].concat(childResult);
            }
        }
    }
}

function findNodeParents(searchId: string, tree: FMuiTreeItem[]) {
    if (searchId) {
        const root: FMuiTreeItem = {
            id: 0,
            label: "root",
            children: tree
        };
        return searchTree(root, searchId) || [];
        // const result = orgData.map(tree => searchTree(tree, searchVal));
        // return result;
    }
    // const searchVal = Number(searchId);
    // if (searchVal > 0) {
    //     let overallResult: number[] | undefined = undefined;
    //     orgData.forEach(tree => {
    //         if (!overallResult) {
    //             const result = searchTree(tree, searchVal);
    //             if (result)
    //                 overallResult = result;
    //         }
    //     });
    //     return overallResult;
    // }
    // return [];
}