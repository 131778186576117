import React from "react";
import { IconButton, Menu, MenuItem, Tooltip, Checkbox, Typography } from "@mui/material";
import ViewColumnIcon from '@mui/icons-material/ViewColumn';
import { DomainRecordKey, DomainSchema } from "features/domainModel";
import { Box } from "@mui/system";


interface DMColumnChooserProps<T extends DomainSchema> {
    chosenFields: Array<DomainRecordKey<T>>;
    allFieldLabels: Record<DomainRecordKey<T>, string>;
    onChange: (fields: Array<DomainRecordKey<T>>) => void;
}

export default function DMColumnChooser(props: DMColumnChooserProps<DomainSchema>) {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [checkedFields, setCheckedFields] = React.useState(props.chosenFields);

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
        props.onChange(checkedFields);
    };

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>, key:string) => {
        if(event.target.checked && !checkedFields.includes(key)) {
            setCheckedFields([...checkedFields, key]);
        } else if(!event.target.checked && checkedFields.includes(key)) {
            setCheckedFields(checkedFields.splice(checkedFields.indexOf(key), 1));
        }
    };
    
    return (
        <Box>
            <Tooltip title="Choose Columns">
                <IconButton aria-label="Column Chooser" onClick={handleClick}>
                    <ViewColumnIcon color="primary" />
                </IconButton>
            </Tooltip>
            <Menu
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
            >
                {
                    Object.entries(props.allFieldLabels).map(([key, value]) =>
                        <MenuItem key={key}>
                            <Checkbox checked={props.chosenFields.includes(key)} onChange={(event) => handleChange(event, key)} />
                            <Typography>{value}</Typography>
                        </MenuItem>
                    )
                }
            </Menu>
        </Box>
    );

}