import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import { DomainRecordValid, UserProfileDomainSchema } from "features/domainModel";
import { RootState } from '../../app/store';

import { UserCourseRequirementGroup } from "./courseRequirementGroups";
import { CourseSummary, fetchCourseSummaries } from "./coursesAPI";


export interface CoursesState {
    data: CourseSummary[];
    courseRequirementGroups: UserCourseRequirementGroup[];
    status: 'idle' | 'loading' | 'succeeded' | 'failed',
    error: string | undefined;
}

const initialState: CoursesState = {
    data: [],
    courseRequirementGroups: [],
    status: "idle",
    error: undefined
};

export const fetchCourseSummariesAsync = createAsyncThunk(
    'courses/fetchUserCourses',
    async (userProfile: DomainRecordValid<UserProfileDomainSchema, "read"> | undefined, thunkAPI) => {
        // TODO: figure out why userProfile is arriving undefined
        if (!userProfile) {
            //@ts-ignore
            userProfile = thunkAPI.getState().auth.user.profile;
        }
        const response = await fetchCourseSummaries(userProfile);
        
        return response;
    }
);

export const coursesSlice = createSlice({
    name: "courses",
    initialState,
    reducers: {        
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCourseSummariesAsync.pending, (state) => {
                state.status = "loading";
            })
            .addCase(fetchCourseSummariesAsync.fulfilled, (state, action) => {
                state.status = "succeeded";
                state.data = action.payload.courseSummaries;
                state.courseRequirementGroups = action.payload.userCourseRequirementGroups;
            })
            .addCase(fetchCourseSummariesAsync.rejected, (state, action) => {
                state.status = "failed";
                state.error = action.error.message;
            })
    }
});

export const selectAllUserCourses = (state: RootState) =>  state.userCourses.data;
export const selectUserCourseRequirementGroups = (state: RootState) => state.userCourses.courseRequirementGroups;
export default coursesSlice.reducer;
