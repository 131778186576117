import type { DataElementType } from "../types";

export const CoursesDataElements: DataElementType[] = [
    {
        formElement: "hidden",
        uid: "67cd1940-678f-4cc3-aff2-2f1938666cca",
        formLabel: "Course Id",
        reportLabel: "Course Id",
        validators: [{ kind: "uuid", params: ["Must be a valid uuid"] }]
    },
    {
        // ad-hoc only
        // TODO: need a way to hide choice fields and lock their value
        formElement: "hidden",
        uid: "a9970856-c34a-4311-bd86-5941e339dd2c",
        formLabel: "InPersonAdHocTrackingMethod",
        reportLabel: "Tracking Method",
        validators: [
            { kind: "oneOf", params: ["Must be Instructor", ["Instructor"]] }
        ]
    },
    {
        // ad-hoc only
        formElement: "hidden",
        uid: "63c5a1bc-4954-46a5-be45-71f1524e42fb",
        formLabel: "InPersonAdHocActive",
        reportLabel: "Active",
        validators: [
            { kind: "oneOf", params: ["Must be true", ["true"]] }
        ]
    },
    {
        // docreview only
        formElement: "choice",
        uid: "52b7461a-4176-4ef7-a66d-69fbc49e0c0f",
        formLabel: "Allow students to mark the course as complete?",
        reportLabel: "Tracking Method",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                { id: 1, label: "Yes" },
                { id: 2, label: "No" }
            ]
        }
    },
    {
        formElement: "choice",
        uid: "174ffbae-a090-4b9b-8463-0434cd99809b",
        formLabel: "Active",
        reportLabel: "Active",
        helpText: ["If yes, the course will be available to students on the courses page"],
        formElementOptions: {
            multiselect: false, asCheckBoxOrRadio: true, items: [
                { id: 1, label: "Yes" },
                { id: 2, label: "No" }
            ]
        }
    },
    {
        formElement: "text",
        uid: "489eb28a-adc1-482d-8b17-3a8a8abb86b5",
        formLabel: "Course Name",
        reportLabel: "Course Name"
    },
    {
        formElement: "text",
        uid: "bdc8884a-7acf-4482-98df-f0adb2cc2853",
        formLabel: "Description",
        reportLabel: "Course Description",
        formElementOptions: { rows: 3 }
    },
    {
        formElement: "color",
        uid: "e7164789-3792-4f29-8a26-418a27512719",
        formLabel: "Course Card Color",
        reportLabel: "Course Card Color",
        helpText: ["The color shown at the top of the course card"]
    },
    {
        formElement: "choice",
        uid: "785fc9b3-0030-43d4-ba31-68aaf89f6505",
        formLabel: "Are students eligible for a Completion Certificate?",
        reportLabel: "Certificate",
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                { id: 1, label: "Yes" },
                { id: 2, label: "No" }
            ]
        }
    },
    {
        formElement: "text",
        uid: "2cd7b7e4-e1ae-4d18-bb74-a8ff9a9e688c",
        formLabel: "Certificate Course Name",
        reportLabel: "Certificate Course Name",
        helpText: ["The course name that shows on the completion certificate, typically ends in 'Course'"]
    },
    {
        // ad-hoc
        formElement: "text",
        uid: "86aa63f6-f686-4663-8abd-cc025e332414",
        formLabel: "Event Date",
        reportLabel: "Date",
        formElementOptions: { isDate: true },
        helpText: ["The date the ad-hoc training event took place"],
        validators: [{ kind: "dateInPast", params: ["The date must be today or in the past"] }]
    },
    {
        // ad-hoc
        formElement: "user",
        uid: "a748805d-d89f-49d1-b033-847613d3e206",
        formLabel: "Search for Student",
        reportLabel: "Attendees",
        formElementOptions: { multiselect: true },
        //helpText: ["Search for a student by name or email address to add to the list of attendees below"]
    },
    {
        formElement: "choice",
        uid: "a7868363-73f1-405e-bf13-860687ad9f26",
        formLabel: "Should this training expire?",
        reportLabel: "Expires",
        helpText: ["If the training expires, student will be reminded to take it again before the expiration date"],
        formElementOptions: {
            multiselect: false,
            asCheckBoxOrRadio: true,
            items: [
                { id: 1, label: "Yes" },
                { id: 2, label: "No" }
            ]
        }
    },
    {
        formElement: "number",
        uid: "1b403e52-6e3d-4059-a371-7976218c8504",
        formLabel: "How many months until the course expires?",
        reportLabel: "",
        helpText: ["The number of months after a student completes the course that it will expire"],
        formElementOptions: { blockENotation: true },
        validators: [
            { kind: "integer", params: ["Must be a whole number"] },
            { kind: "min", params: [1, "Must be at least 1"] },
            { kind: "max", params: [120, "Must be 120 or less"] }
        ]
    },
    {
        formElement: "doc",
        uid: "33153202-a53b-4b83-a40e-3e0e5cb9a375",
        formLabel: "Documents",
        reportLabel: "Documents"
    },
    {
        formElement: "choice",
        uid: "044a3d56-4ea8-4c0f-92d2-fbeef536544f",
        formLabel: "Course Requirement Group",
        reportLabel: "Course Requirement Group",
        helpText: ["The group the course will appear in on the Courses page"],
        formElementOptions: {
            asCheckBoxOrRadio: false,
            isCourseRequirementGroup: true,
            multiselect: false,
            items: []
        }
    },
    {
        formElement: "userGroup",
        uid: "3ac22684-e65e-417d-aa96-b8f82ce58149",
        formLabel: "User Group",
        reportLabel: "User Group",
        helpText: ["The group(s) of users that can view this course"]        
    },
    // self-paced module
    {
        formElement: "course",
        uid: "9cfb8b17-b865-4508-82fa-330cd7ce266a",
        formLabel: "Upload Course",
        reportLabel: "Url",
        helpText: ["Must be a SCORM conformant zip file"],
        formElementOptions: {
            accept: ".zip"
        }
    },    
];
