import { configureStore, ThunkAction, Action, combineReducers, AnyAction, CombinedState, getDefaultMiddleware } from '@reduxjs/toolkit';
import { coursesReducer, CoursesState } from '../features/courses';
import { AuthState, authReducer, userManager } from "../features/auth";
import { reducer as oidcReducer, UserState as OidcUserState, loadUser } from 'redux-oidc';
import homeReducer, { HomeState } from "features/home/homeSlice";




const appReducers = combineReducers({
  userCourses: coursesReducer,
  oidc: oidcReducer,
  auth: authReducer,
  home: homeReducer,
});

// type CombinedAppState = ReturnType<typeof appReducers>;
type CombinedAppState = CombinedState<
  {
    userCourses: CoursesState;
    oidc: OidcUserState;
    auth: AuthState;
    home: HomeState;
  }> | undefined;

const rootReducer = (state: CombinedAppState, action: AnyAction) => {
  if (action.type === 'USER_SIGNOUT') {
    return appReducers(undefined, action);
  }

  return appReducers(state, action);
};

// export const store = createStore(rootReducer);

const store = configureStore({
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoredPaths: ["oidc.user"],
        ignoredActions: ["redux-oidc/USER_FOUND"]
      }
    }),
  reducer: rootReducer
  // {
  //   userCourses: coursesReducer,
  //   authUser: authReducer
  // }
});
loadUser(store, userManager);

export { store };
export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;