import React from "react";
import { Avatar, Button, FormControl, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, TextField } from "@mui/material";
import { useField } from "formik";
import { FMuiProps, FMuiUserProps, getFMuiFieldErrorState, getFMuiFieldHelperText } from "./internal";
import { Autocomplete } from '@mui/material';
import throttle from 'lodash/throttle';
import { API_URL } from "app/runtimeConstants";
import { BasicUserInfo } from "features/common/elevatedApiEndpoints";
import FaceIcon from '@mui/icons-material/Face';
import DeleteIcon from '@mui/icons-material/Delete';
import SearchIcon from '@mui/icons-material/Search'
import CreateQuickUserDialog from "features/admin/CreateQuickUserDialog";
export default function FMuiUserField(props: FMuiProps<FMuiUserProps>) {
    const [field, meta, helpers] = useField(props.name);
    const [inError, errorMsg] = getFMuiFieldErrorState(field, meta);
    const [value, setValue] = React.useState<BasicUserInfo | null>(null);
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<BasicUserInfo[]>([]);
    const loaded = React.useRef(false);
    
    const [showAddUser, setAddUser] = React.useState(false);
    const [hasSearched, setHasSearched] = React.useState(false);
    const [quickUserOpen, setQuickUserOpen] = React.useState(false);


    //const [users, setUsers] = React.useState<UserSearchResult[]>([]);

    const users: BasicUserInfo[] = field.value || [];
    const setUsers = (u: BasicUserInfo[]) => helpers.setValue(u);

    const memoSearch = React.useMemo(
        () =>
            throttle((searchStr: string, callback: (results?: BasicUserInfo[]) => void) => {
                // TODO: switch to FetchAPI 
                const url = `${API_URL}/search/admin/user/${encodeURIComponent(searchStr)}`;
                fetch(url).then(response => {
                    if (response.ok) {
                        response.json().then(data => {
                            //setHasSearched(true);
                            callback(data);
                        }).catch(err => {
                            callback([]);
                        });
                    } else {
                        callback([]);
                    }
                }).catch(err => callback([]));
            }, 200),
        []
    );

    React.useEffect(() => {
        let active = true;
        if (inputValue === "") {
            // setOptions(value ? [value] : []);
            setOptions([]);
            return undefined;
        }
        memoSearch(inputValue, (results?: BasicUserInfo[]) => {
            if (active) {
                let newOptions: BasicUserInfo[] = [];
                // if (value) {
                //     newOptions = [value];
                // }
                if (results) {
                    newOptions = [...newOptions, ...results];
                }
                if (newOptions.length === 0) {
                    // push flag value - renderOption will show add user button
                    newOptions.push({uid: "", email: "", givenName: "", surname: ""});
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue, memoSearch]); // value

    const removeUser = (user: BasicUserInfo) => {
        setUsers(users.filter(u => u.uid !== user.uid));
    };
    return (
        <FormControl style={props.indent ? { marginLeft: `${2 * props.indent}rem` } : {}}>
            <Autocomplete
                id={field.name}
                options={options}
                noOptionsText={"Start typing to search for a student by name or email address"}
                getOptionLabel={(option) => (typeof option === "string" ? option : option.email)}
                filterOptions={(opts) => opts.filter(o => users.findIndex(u => o.uid === u.uid) === -1)}                
                handleHomeEndKeys
                selectOnFocus
                clearOnBlur
                blurOnSelect
                value={value}

                onChange={(event: any, newValue: BasicUserInfo | null) => {
                    // setOptions(newValue ? [newValue, ...options] : options);
                    // setValue(newValue);

                    // option selected
                    if (newValue && newValue.uid !== "" && !users.find((u) => u.uid === newValue.uid)) {
                        // add user to list
                        setUsers([...users, newValue]);
                        //TODO: set formik value with helpers
                    }
                    // reset for another search
                    setOptions([]);
                    setValue(null);
                    setInputValue("");
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => (<TextField {...params} label={props.label} fullWidth />)}
                renderOption={(props, option) => {
                    if(option.uid === "") {
                        return (
                            <div style={{textAlign: "center", width: "100%"}}>
                                <div>No users found. Click the button below to create a new user account.</div>
                                <div><Button variant="contained" color="primary" onClick={() => setQuickUserOpen(true)}>New User</Button></div>
                            </div>
                        );
                    }
                    return (
                        <div>{`${option.surname}, ${option.givenName} (${option.email})`}</div>
                    );
                }}
            />
            {/* <TextField
                fullWidth
                label={props.label}
                // name={field.name}                
                // onChange={field.onChange}
                // onBlur={field.onBlur}
                error={inError}
                helperText={errorMsg}
                // {...inputProps}
                {...field}
            /> */}
            {getFMuiFieldHelperText(props.formHelperText)}
            <List>
                {
                    users.map(u =>
                        <ListItem key={u.uid}>
                            <ListItemAvatar>
                                <Avatar>
                                    <FaceIcon />
                                </Avatar>
                            </ListItemAvatar>
                            <ListItemText
                                primary={`${u.surname}, ${u.givenName}`}
                                secondary={u.email}
                            />
                            <ListItemSecondaryAction>
                                <IconButton
                                    edge="end"
                                    aria-label="delete"
                                    onClick={() => removeUser(u) }
                                    size="large">
                                    <DeleteIcon />
                                </IconButton>
                            </ListItemSecondaryAction>
                        </ListItem>)
                }
                {
                    users.length === 0 && <ListItem key={0}>
                        <ListItemAvatar>
                            <Avatar>
                                <FaceIcon />
                            </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary="No Students have been Added"/>
                    </ListItem>
                }
            </List>
            <CreateQuickUserDialog 
                open={quickUserOpen}
                onUserCancel={() => setQuickUserOpen(false)}
                onCreatedQuickUser={(newUser) => {                 
                    //TODC: write an IsUserSearchResult type guard   
                    setUsers([...users, newUser as BasicUserInfo]);
                    setQuickUserOpen(false);
                }}
            />
        </FormControl>
    );
}