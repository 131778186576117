import { DomainField, ComparisonOperators } from "../types";

export const InPersonAdHocFields: DomainField = {
    uid: {
        sortOrder: 0,
        dataElementUid: "67cd1940-678f-4cc3-aff2-2f1938666cca",
        onAction: {
            base: { required: ["Required"] },
            create: { required: false, omit: true }
        }
    },
    courseName: {
        sortOrder: 1,
        dataElementUid: "489eb28a-adc1-482d-8b17-3a8a8abb86b5",
        onAction: {
            base: { required: ["Required"]}
        }
    },
    description: {
        sortOrder: 2,
        dataElementUid: "bdc8884a-7acf-4482-98df-f0adb2cc2853",
        onAction: {
            base: { required: ["Required"]}
        },
        dataElementOverrides: {
            all: {
                helpText: ["Describe the training took place"]
            }
        }
    },
    trackingMethod: {
        sortOrder: 4,
        dataElementUid: "a9970856-c34a-4311-bd86-5941e339dd2c",
        onAction: {
            base: { required: false, default: ["Instructor"]}
        }
    },
    active: {
        sortOrder: 4,
        dataElementUid: "63c5a1bc-4954-46a5-be45-71f1524e42fb",
        onAction: {
            base: {required: false, default: ["true"]}
        }
    },
    certificateYN: {
        sortOrder: 4,
        dataElementUid: "785fc9b3-0030-43d4-ba31-68aaf89f6505",
        onAction: {
            base: { required: ["Required"]}
        }
    },
    certificateCourseName: {
        sortOrder: 5,
        dataElementUid: "2cd7b7e4-e1ae-4d18-bb74-a8ff9a9e688c",
        onAction: {
            base: {
                required: ["Required"],
                onlyShowWhen: {
                    dataElementUid: "785fc9b3-0030-43d4-ba31-68aaf89f6505",
                    operator: ComparisonOperators.eq,
                    value: 1
                }
            }
        }
    },
    eventDate: {
        sortOrder: 6,
        dataElementUid: "86aa63f6-f686-4663-8abd-cc025e332414",
        onAction: {
            base: {
                required: ["Required"]
            }
        }
    },
    attendees: {
        sortOrder: 7,
        dataElementUid: "a748805d-d89f-49d1-b033-847613d3e206",
        onAction: {
            base: {
                required: false
            }
        }
    },
    docs: {
        sortOrder: 8,
        dataElementUid: "33153202-a53b-4b83-a40e-3e0e5cb9a375",
        onAction: {
            base: {
                required: false
            }
        }
    }
};