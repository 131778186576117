import { UserProfileDataElements } from "./dataElements";
import { DomainSchema } from "../types";

export const quickUserDomainSchema: DomainSchema = {
    uid: "45385bb3-eb76-467e-a862-00c9ce2a6242",
    name: "quickUser",
    version: 0,
    dataElements: UserProfileDataElements,
    fields: {
        uid: {
            sortOrder: 0,
            dataElementUid: "aabb85af-eb9c-4063-97bb-3ac97d9b756f",
            onAction: {
                base: { required: ["Required"] },
                create: { required: false, omit: true }
            }
        },
        email: {
            sortOrder: 1,
            dataElementUid: "1ed1faf6-c346-4d22-8f4b-934bd73b711c",
            onAction: {
                base: { required: ["Required"] }
            },
            dataElementOverrides: {
                // remove emailAvailable validator from update & read
                update: {
                    validators: [
                        { kind: "email", params: ["Enter a valid email"] }
                    ]
                },
                read: {
                    validators: [
                        { kind: "email", params: ["Enter a valid email"] }
                    ]
                },
                create: {
                    validators: [
                        { kind: "email", params: ["Enter a valid email"] },
                        // eslint-disable-next-line no-template-curly-in-string
                        { kind: "emailAvailable", params: ["${value} is already registered. Cancel this form and search for the student again.", "/search/email/${email}"] },
                    ]
                }
            }
        },
        givenName: {
            sortOrder: 7,
            dataElementUid: "8424222a-69ba-40a7-960f-eb1d6a2421ff",
            onAction: {
                base: { required: ["Required"] },
            }
        },
        surname: {
            sortOrder: 8,
            dataElementUid: "be3fa7c6-bf88-49db-9996-75278db1d0f5",
            onAction: {
                base: { required: ["Required"] },
            }
        },
        welcomeEmail: {
            sortOrder: 9,
            dataElementUid: "1e58f126-0d29-4ade-93ad-c092929ce16c",
            onAction: {
                base: { required: false, default: [false] }
            }
        }
    },
    schema: {
        kind: "stepForm",
        steps: [
            {
                title: "Student Info",
                description: "Complete the information below to create a new student record.",
                fields: ["uid", "email", "givenName", "surname", "welcomeEmail"]
            }
        ]
    }
};
